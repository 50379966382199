import { Component } from "@angular/core";

import { Router } from "../../node_modules/@angular/router";
import { UserIdleService } from "angular-user-idle"
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from "./config";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [Globals]
})
export class AppComponent {
  title: string = "barista";
  userName: string = "";
  showTheme: boolean = false;
  index: number = -1;
  timeStart = false;
  seconds = 1800;
  topics: any;
  accessType = "";
  modalContext:NgbModal;
  constructor(
    private globals: Globals,
    private router: Router, private userIdle: UserIdleService, private location: Location, modalService: NgbModal) {
    this.modalContext= modalService;
    /* this.getTopics();*/
  }

  onLogin(user: string) {
    this.userName = user;
    this.router.navigate(["topic"]);
    this.setUserIdelTimer();
  }
  ngOnInit() {
    this.accessType = this.globals.accessType;

    const path = this.location.path().split('?')[0];
    if(path  == "" ||path == "/login")
               return;


    //if(this.location.path() == "" || this.location.path() == "/login") return;
    //Start watching for user inactivity.
    this.setUserIdelTimer();
    if(!localStorage.getItem("currentUser") || localStorage.getItem("currentUser").length <=0){
      this.router.navigate(["login"]);
    }
  }

  setUserIdelTimer() {
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      this.seconds = this.seconds - 1;
      this.timeStart = true;
      //console.log(count) ;
    });
    this.userIdle.onTimeout().subscribe(() => {
      if (localStorage.getItem("currentUser")) {  
        alert('Session Timeout!');
      }
      if(this.modalContext.hasOpenModals()){
        this.modalContext.dismissAll();
      }
      
      this.router.navigate([""]);
      localStorage.removeItem("currentUser");
      localStorage.clear();
    });
  }

  stop() {
    // alert('timer is stopped');
    this.userIdle.stopTimer();
    this.seconds = 1800;
    this.timeStart = false;
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

}
