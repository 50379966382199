import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AwsS3Service } from '../services/AwsS3Service/aws-s3.service';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/config';
import { ApiFormatterService } from 'src/app/common/services/Api Formatter/api-formatter.service';
import { FileUploadService } from '../services/fileUploadService/file-upload.service';
import { Solution } from '../edit-theme/solution';
import { Content } from '../edit-theme/content';
import { Topic } from './topic';
import { Instance } from './instance';
import { User } from 'src/app/register/user';
import { Container } from './container';
import _ from 'lodash';
// let file = '../../../assets/resources/eqpmtMonitor.svg';

declare function clickFileLoader(id): any

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./custom-modal.component.css']
})
export class CustomModalComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: NgbModal,
    private httpClient: HttpClient,
    private awsS3: AwsS3Service,
    private appComponentref: AppComponent,
    private globals: Globals,
    private apiFormatterService: ApiFormatterService,
    private fileUploadService: FileUploadService
  ) { }

  actualTopicName: any;

  ngOnInit() {
    this.timestamp = new Date().getTime();
    //console.log(this.modalData);
    if (this.modalData instanceof Instance) {
      this.modalData.fields.forEach((element) => {
        this.deciderValueChange(element);
      });
    }
    if (this.modalData instanceof Topic) {
      this.modalData.fields.forEach((element) => {
        if (element.jsonKey === "topicname") {
          this.actualTopicName = element.value;
        }
      });
    }
  }

  ngOnDestroy() {
    this.appComponentref.restart();
  }

  @Input() modalData;
  @Input() modal;
  @Input() dataFromModal;
  @Input() title;

  timestamp = undefined;

  @Input() isButtonClicked: Boolean = false;

  @Output() event = new EventEmitter();

  @Output() closeCall = new EventEmitter();

  @Output() instanceChangeEvent = new EventEmitter();

  @Output() onChangeSelect = new EventEmitter();

  uploadFileInProgress = false;

  @ViewChild('Logo') logo: ElementRef;

  @Output() onDeselectInMultiselect = new EventEmitter();

  click() {
    // console.log(this.logo.nativeElement);
  }

  async sendModalData() {
    // Check for valid owner and contact
    // TODO:This method has to be deleted by making sure that when the user clicks on delete button for owner value, this implementation should be taken care of.
    if (!this.validateContact()) {
      return;
    }

    // Continue with submitting data
    let result = await this.validateForm();
    // console.log('main:     ', result);
    if (result) {
      this.isButtonClicked = true;
      this.event.emit(this.modalData);
    }
  }
  /**
   * Check if the contact is present in owner or not
   * @returns {boolean}
   */
  validateContact(): boolean {
    var isValid = true;
    if ((this.modalData instanceof Topic)) {
      if (this.modalData instanceof Topic) {
        // console.log(options);
        const contactIndex: any = this.modalData.fields.findIndex(field => {
          return field.jsonKey == "contact";
        });
        const ownerIndex: any = this.modalData.fields.findIndex(field => {
          return field.jsonKey == "owner";
        });
        // Make sure to have contact value always in owner.
        const ownerSelectedValues = this.modalData.fields[ownerIndex].selectedValues;
        const contactSelectedValues = this.modalData.fields[contactIndex].selectedValues;
        if (contactSelectedValues.length > 0) {
          const contactValue = this.modalData.fields[contactIndex].selectedValues[0];
          const tempResult = _.filter(ownerSelectedValues, { value: contactValue.value });
          if (tempResult.length == 0) {
            (this.modalData.fields[ownerIndex])['error'] = true;
            (this.modalData.fields[ownerIndex])['errorMsg'] = 'Make sure the contact is part of owner options.';
            return false;
          }
        }
      }
    }
    return isValid;
  }

  async validateForm() {


    let submit = true;
    if (this.modalData.tabs) {
      this.modalData.tabs.map((tab) => {
        tab.error = false;
      })
    }
    // console.log(submit);
    // console.log(this.modalData.fields);
    // console.log(this.modalData);
    await Promise.all(
      this.modalData.fields.map(async (element) => {
        // console.log(element.label,  element.value);
        // debugger;
        // console.log( element.type == "multiSelect"? (element.selectedValues && element.selectedValues.length <= 0) : element.value.length <= 0)
        // console.log(element.required && (!element.value || (element.type == "multiSelect"? (element.selectedValues && element.selectedValues.length <= 0) : element.value.length <= 0)));
        element['error'] = false;

        if (element['specialValidationNotMet']) element.specialValidationNotMet(this.modalData);

        // let errorMsg = "Name already exists! Please try a different name.";
        // if(element.jsonKey === "artifactName") {
        // 	if(!this.modalData.title.toLowerCase().includes("update")) {
        // 		if(await this.modalData.isContainerNameDuplicate(this.globals.devDbUrl, element.value.trim())) {
        // 			element.error = true;
        // 			element.errorMsg = errorMsg;
        // 		}
        // 	}
        // }
        // if(element.jsonKey === "topicname") {
        // 	console.log(this.actualTopicName);
        // 	let isApiCallNecessary = false;
        // 	if(this.modalData.title.toLowerCase().includes("update")) {
        // 		if(this.actualTopicName.toLowerCase() !== element.value.trim().toLowerCase()) {
        // 			isApiCallNecessary = true;
        // 		}
        // 	} else {
        // 		isApiCallNecessary = true;
        // 	}
        // 	if(isApiCallNecessary) {
        // 		let result = await this.modalData.isTopicNameDuplicate(this.globals.devDbUrl, element.value.trim());
        // 		if(result) {
        // 			element.error = true;
        // 			element.errorMsg = errorMsg;
        // 		}
        // 	}
        // }

        if (
          element.required &&
          (!element.value ||
            (element.type == 'multiSelect'
              ? element.selectedValues && element.selectedValues.length <= 0
              : element.value.length <= 0))
        ) {
          // console.log('here ->');
          submit = false;
          element.error = true;
          element.errorMsg = 'Required';
        } else {
          let regex = new RegExp(element.pattern);
          if (
            element.value &&
            element.pattern &&
            element.pattern.length &&
            typeof element.value === 'string'
          ) {
            if (!regex.test(element.value)) {
              element.error = true;
              element.errorMsg = element.patternErrorMsg;
              // console.log('here ->');
              submit = false;
            } else {
              element.error = false;
            }
          }
          if (element.uploadToS3 && element.fileObj instanceof Object) {
            // console.log('here ->');
            submit = false;
            element.error = true;
            element.errorMsg = 'Required';
          }
        }

        if (element.uploading) element.error = true;

        if (element['type'] == 'many') {
          for (let field of element['fields']) {
            if (field['type'] == 'textWithOptions' && field.value != '') {
              let regex = new RegExp(field.pattern);
              if (field.pattern && field.pattern.length && typeof field.value === 'string') {
                if (!regex.test(field.value)) {
                  field.error = true;
                  field.errorMsg = field.patternErrorMsg;
                  // console.log('here ->');
                  submit = false;
                } else {
                  field.error = false;
                }
              }
            }
            if (field['specialValidationNotMet']) field.specialValidationNotMet(this.modalData);

            if (field['type'] == 'file' && field['jsonKey'] == 'audio' && field['fileObj']) {
              let fileObj = field['fileObj'];
              let result = await this.isLengthMoreThan_500(fileObj);
              // console.log(result);
              if (result) {
                // console.log('here ->');
                submit = false;
                field['error'] = true;
                field['errorMsg'] = 'Text should not exceed 500 words.';
              } else {
                field['error'] = false;
                field['errorMsg'] = '';
              }
            }
            if (field['type'] == 'file' && field['jsonKey'] == 'video' && field['fileObj']) {
              let fileObj = field['fileObj'];
              let size = fileObj.size;
              if (this.globals.allowableFileSize < size) {
                field['error'] = true;
                field['errorMsg'] = 'File size exceeded 20MB.';
                // console.log('here ->');
                submit = false;
                // console.log("more than 20 mb");
              } else {
                field['error'] = false;
                field['errorMsg'] = '';
                // console.log("less than 20 mb");
              }
            }
            if (field.uploading) field.error = true;
            if (field.error) {
              submit = false;
              if (element.tabId) {
                this.modalData.tabs[this.modalData.tabIndices[element.tabId]].error = true;
              }
            }
          }
        }
        if (element.error) {
          // console.log('here ->', element);
          submit = false;
          if (element.tabId) {
            this.modalData.tabs[this.modalData.tabIndices[element.tabId]].error = true;
          }
        }
        // console.log(element.jsonKey, submit);
      })
    );
    // console.log(submit);
    return submit;
  }

  async isLengthMoreThan_500(fileObj): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        let s = fileReader.result.toString();
        let length = s.split(/\s+\b/g).length;
        if (length > 500) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      fileReader.readAsText(fileObj);
    });
  }

  async modalBodyClicked(event) {
    this.modalData.fields.map(async (element) => {
      if (element.showOptions) {
        element.showOptions = false;
      } else if (element.type == 'many') {
        element.fields.map((subElement) => {
          if (subElement.showOptions) {
            subElement.showOptions = false;
          }
        });
      }

    });
  }

  async chekIfNameIsValid(element) {
    let errorMsg = "Name already exists! Please try a different name.";
    if (element.jsonKey === "artifactName") {
      if (!this.modalData.title.toLowerCase().includes("update")) {
        if (await this.modalData.isContainerNameDuplicate(this.globals.devDbUrl, element.value.trim())) {
          element.error = true;
          element.errorMsg = errorMsg;
        } else if (element.errorMsg === errorMsg) {
          element.error = false;
          element.errorMsg = "";
        }
      }
    }
    if (element.jsonKey === "topicname") {
      // console.log(this.actualTopicName);
      let isApiCallNecessary = false;
      if (this.modalData.title.toLowerCase().includes("update")) {
        if (this.actualTopicName.toLowerCase() !== element.value.trim().toLowerCase()) {
          isApiCallNecessary = true;
        }
      } else {
        isApiCallNecessary = true;
      }
      if (isApiCallNecessary) {
        let result = await this.modalData.isTopicNameDuplicate(this.globals.devDbUrl, element.value.trim());
        if (result) {
          element.error = true;
          element.errorMsg = errorMsg;
        } else if (element.errorMsg === errorMsg) {
          element.error = false;
          element.errorMsg = "";
        }
      }
    }
  }

  selectOptionFile(filePath, modalDataField) {
    let filePathSplitted = filePath.split('/');
    modalDataField.value = modalDataField.prefix + '_' + filePathSplitted[filePathSplitted.length - 1];
    this.httpClient.get(this.globals.filesServerUrl + '/getThemesImage/' + filePath, { responseType: 'blob' }).subscribe((data) => {
      let file: File = new File([data], filePathSplitted[filePathSplitted.length - 1]);
      modalDataField.fileObj = file;
      // console.log(data);
      this.uploadToNode(modalDataField, false);
    });
    this.toggleFileOptions(modalDataField);
  }

  toggleFileOptions(modalDataField) {
    this.modalData.fields.map((element) => {
      if (element.jsonKey != modalDataField.jsonKey && element.showOptions) {
        element.showOptions = false;
      } else if (element.type == 'many') {
        element.fields.map((subElement) => {
          if (subElement.jsonKey != modalDataField.jsonKey && subElement.showOptions) {
            subElement.showOptions = false;
          }
        });
      }
    });
    if (modalDataField.showOptions != undefined) modalDataField.showOptions = !modalDataField.showOptions;
  }

  toggleSideOptions(modalDataField) {
    this.toggleFileOptions(modalDataField);
  }

  // selectSideOption(value, modalDataField) {
  //   modalDataField.fieldType = value;
  // }

  selectFileUrlType(modalDataField, fieldType) {
    // console.log(this.modalData);
    if (modalDataField.fieldType == fieldType) return;
    if (fieldType == 'url' && modalDataField.fileObj) modalDataField.fileObj = undefined;
    modalDataField.fieldType = fieldType;
    modalDataField.value = '';
    if (fieldType == 'url') {
      modalDataField.placeholder = 'Enter URL here';
      modalDataField.pattern = "^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|s3:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*(:[0-9]{1,5})?(\/.*)?$";
      modalDataField.patternErrorMsg = "Enter Valid URL";
    }
    else if (fieldType == 'file') {
      modalDataField.placeholder = `Choose file${modalDataField.fileType ? ' (*.' + modalDataField.fileType + ')' : ''} to upload`
      modalDataField.pattern = `.(${modalDataField.fileType})$`
      modalDataField.patternErrorMsg = "Upload a valid File Type"
    }
  }

  selectTextOption(modalDataField, value) {
    modalDataField.value = value;
  }

  fileLoader(id) {
    // console.log(id, this.modalData);
    clickFileLoader(id);
  }

  inputClicked(item) {
    item.error = false;
    if ((this.modalData instanceof Topic) && item.jsonKey == "contact") {
      if (this.modalData instanceof Topic) {
        this.modalData.fields.forEach((element) => {
          if (element.jsonKey === "contact") {
            let ownerIndex = this.modalData.fields.findIndex(element => element.jsonKey === "owner");
            let contactIndex = this.modalData.fields.findIndex(element => element.jsonKey === "contact");
            this.modalData.fields[contactIndex].options = _.uniqBy(this.modalData.fields[ownerIndex].selectedValues, function (e) {
              return e.name;
            });
            this.modalData.fields[contactIndex].options = _.sortBy(this.modalData.fields[contactIndex].options, o => o.name)
          }
        });

      }
    }
  }

  instanceOfObject(value) {
    return value instanceof Object;
  }

  assignProgressToItem(item) {

    let prev = 0;

    let refreshId = setInterval(() => {

      if (!item.uploading || item.progress.loaded == item.progress.total) {
        clearInterval(refreshId);
      }

      if (item.progress.loaded == 0) return;

      let speed = item.progress.loaded - prev;
      prev = item.progress.loaded;

      let timeLeft = speed ? Math.ceil((item.progress.total - prev) / speed) : -1;
      item.progress.message = (timeLeft == -1 ? item.progress.message : (Math.ceil(prev / item.progress.total * 100) + "% " + timeLeft + "s remaining"));

      //console.log(item.progress);

    }, 1000);
  }

  async uploadToAwsS3(item, progressPopover) {
    //console.log(progressPopover);
    // console.log(this.timestamp);
    item.uploading = true;
    // console.log(item.directoryPath);
    item.specialValidationNotMet(this.modalData);
    if (!item.error)
      await this.checkConstraints(item);
    // console.log(item.error);

    if (item.fileType == "json" && !item.error) {
      if (item.fileObj) {
        let fileReader = new FileReader()
        fileReader.readAsText(item.fileObj)
        fileReader.onloadend = () => {
          try {
            let str: string = <string>fileReader.result;
            let parse = JSON.parse(str)
            return;
          } catch (error) {
            item.error = true
            item.errorMsg = "Please upload a valid JSON file.";
            item.uploading = false;
            this.uploadFileInProgress = false;
          } finally {
            this.uploadToS3AndShowProgress(item, progressPopover)
          }
        }
        fileReader.onerror = () => {
          item.error = true
          item.errorMsg = "Error reading file";
          item.uploading = false;
          this.uploadFileInProgress = false;
        }
      }
    }
    else
      this.uploadToS3AndShowProgress(item, progressPopover)
  }

  async uploadToS3AndShowProgress(item, progressPopover) {
    if (item.error) {
      item.uploading = false;
      this.uploadFileInProgress = false;
      return;
    }

    item.progress.total = item.fileObj.size;
    let fileSizeInMb = item.fileObj.size / 1000000;
    if (fileSizeInMb < 1) item.progress.message = "1s remaining";
    else item.progress.message = "0% " + (Math.ceil(fileSizeInMb) * 2) + "s remaining";

    //console.log((Math.ceil(fileSizeInMb) * 2) + "s remaining");

    if (progressPopover)
      progressPopover.open();



    // debugger;
    let directoryInd = this.modalData.fields.findIndex((field) => field.jsonKey == item.directoryPathFieldJsonKey);
    if (directoryInd >= 0) item.directoryPath = this.modalData.fields[directoryInd].value;
    else item.directoryPath = 'default_directory';

    this.assignProgressToItem(item);

    let res = await this.awsS3.uploadFileToBucket(
      item.fileObj,
      item.directoryPath,
      this.timestamp ? this.timestamp : new Date().getTime(),
      item
    );

    // console.log(res);
    let keyValue = res['Key'] ? res['Key'] : res['key'];
    if (keyValue) {
      let filePath = `s3://${this.awsS3.getBucketName()}/${keyValue.trim()}`;
      item.value = filePath;
      item.error = false;
      item.fileObj = undefined;
    } else {
      console.error('key not returned from AWS!');
    }
    item.uploading = false;
    this.uploadFileInProgress = false;
    if (progressPopover)
      progressPopover.close();
  }

  async getPath(fileInput, item, progressPopover?: any) {
    if (fileInput && fileInput.target.files && fileInput.target.files[0]) {
      item.value = (item.prefix ? item.prefix + '_' : '') + fileInput.target.files[0].name;
      item.fileObj = fileInput.target.files[0];
      await this.uploadToNode(item, true, progressPopover);
    }
  }

  deleteFileItem(item) {
    item.value = "";
    item.fileObj = '';
    item.error = false;
    item.errorMsg = "";
  }

  async checkConstraints(field) {
    let regex = new RegExp(field.pattern);
    if (
      field.value &&
      field.pattern &&
      field.pattern.length &&
      typeof field.value === 'string'
    ) {
      if (!regex.test(field.value)) {
        field.error = true;
        field.errorMsg = field.patternErrorMsg;
        // console.log('here ->');
        return !field['error'];
      } else {
        field.error = false;
      }
    }
    if (field['type'] == 'file' && field['jsonKey'] == 'video' && field['fileObj']) {
      let fileObj = field['fileObj'];
      let size = fileObj.size;
      if (this.globals.allowableFileSize < size) {
        field['error'] = true;
        field['errorMsg'] = 'File size exceeded 20MB.';
      } else {
        field['error'] = false;
        field['errorMsg'] = '';
      }
    }
    else if (field['type'] == 'file' && field['jsonKey'] == 'audio' && field['fileObj']) {
      let fileObj = field['fileObj'];
      let result = await this.isLengthMoreThan_500(fileObj);
      if (result) {
        field['error'] = true;
        field['errorMsg'] = 'Text should not exceed 500 words.';
      } else {
        field['error'] = false;
        field['errorMsg'] = '';
      }
    }
    return !field['error'];
  }

  async uploadToNode(item, showSpinner: boolean = true, progressPopover?: any) {
    let filename = item.value;
    let fileObj = item.fileObj;
    // if(this.modalData instanceof Container) {
    // 	await this.checkConstraints(item);
    // }
    if (this.modalData instanceof Solution || this.modalData instanceof Content || this.modalData instanceof Topic) {
      let res = await this.checkConstraints(item);
      if (res) {
        if (item.uploading != undefined && showSpinner) {
          if (progressPopover) progressPopover.open({ progress: "Progress: Uploading..." });
          this.uploadFileInProgress = true;
          item.uploading = true;
        }
        let formData = new FormData();
        formData.append("upload", fileObj, filename);
        let currentUser = this.apiFormatterService.removeQuotesFromString(localStorage.getItem('currentUser').trim());
        formData.append("userName", currentUser);
        this.fileUploadService.postFilesToTemp(formData).subscribe(async data => {
          if (progressPopover) progressPopover.close();
          if (item.uploading != undefined) {
            this.uploadFileInProgress = false;
            item.uploading = false;
          }
          // console.log(data);
        });
      }
    }
  }


  onTextFieldChange(item) {
    if (item.onChange) item.onChange(this.modalData);
  }

  deciderValueChange(item, resetValues = false): void {

    if (resetValues && item.onChange) item.onChange(this.modalData);

    // console.log(item.value);
    if (item.deciderOption) {
      if (this.modalData instanceof Instance) {
        this.modalData.fields.forEach((element) => {
          if (element.deciderJsonKey && element.deciderJsonKey == item.jsonKey) {
            element.options = element.optionsToChoose.filter((option) => option.deciderValue == item.value);
            if (item.jsonKey == 'instancetype') {
              if (item.value == 'GCP') element['disabled'] = true;
              else element['disabled'] = false;
            }
            if (resetValues) element.value = '';
          }
        });
      }
      else if (this.modalData instanceof User) {
        if (this.modalData.buttons[0].value == "Approve" || this.modalData.buttons[0].value == "Update" || this.modalData.buttons[0].value == "Activate")
          this.modalData.checkTopicsConfidentiality(this.globals.backendServicesUrl);
      }
    }
    this.onChangeSelect.emit(this.modalData);
  }

  setMultiSelectValues(item, type) {
    if (type == "selectAll") {
      let selectOptions = [];
      item.options.forEach(option => selectOptions.push({ name: option.name, value: option.value }));
      item.selectedValues = selectOptions;
    } else if (type == "deselectAll") {
      item.selectedValues = [];
    }
  }

  multiselectChange(item, type) {
    if ((this.modalData instanceof User || this.modalData instanceof Topic) && this.modalData.handleMultiselectChange) {
      let deselectedValues = [...item.selectedValues];
      this.setMultiSelectValues(item, type);
      this.modalData.handleMultiselectChange(this.globals.backendServicesUrl, item);
      if (type == 'deselectAll' && item.handleDeselect) {
        this.confirmDeselect(deselectedValues, item);
      }
      if ((this.modalData instanceof Topic) && item.jsonKey == "owner") {
        if (this.modalData instanceof Topic) {
          // console.log(options);
          let index: any = this.modalData.fields.findIndex(field => {
            return field.jsonKey == "contact";
          });
          this.modalData.fields[index].options = item.selectedValues;
        }
      }
    }
    // if(this.modalData instanceof Topic && item.jsonKey === "topicArtifacts") {
    // 	this.setMultiSelectValues(item, type);
    // 	this.modalData.handleMultiselectChange(this.globals.backendServicesUrl, item);
    // 	// let instanceItem = null;
    // 	// for(let field of this.modalData.fields) {
    // 	// 	if(field.jsonKey === "topicInstances") {
    // 	// 		instanceItem = field;
    // 	// 		break;
    // 	// 	}
    // 	// }
    // 	// item.callBackFuncToChangeOptions(item.selectedValues.map((element) => element.value), instanceItem, this.globals.backendServicesUrl + "console/getInstancesByArtifact/", this.apiFormatterService);
    // }
  }

  confirmDeselect(event, item) {
    // console.log(event);
    let objectToEmit = {
      deselectedValues: event instanceof Array ? event : [event],
      item: item,
      isDeselectAll: event instanceof Array ? true : false
    };
    // console.log(objectToEmit);
    this.onDeselectInMultiselect.emit(objectToEmit);
  }

  handleOnKeyUp(item, event) {
    if (!item.handleKeyUp) return;
    item.handleKeyUp();
  }

  isArray(arr): boolean {
    return Array.isArray(arr);
  }

}
