import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges
} from "@angular/core";
import { Globals } from "../../config";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Container } from "../custom-modal/container";
import { Topic } from "../custom-modal/topic";
import { Instance } from "../custom-modal/instance";
import { ApiFormatterService } from "../../common/services/Api Formatter/api-formatter.service";
import {
  ActivatedRoute,
  Router
} from "../../../../node_modules/@angular/router";
import { DataService } from "../../register/services/data.service";
import { FileUploadService } from "../services/fileUploadService/file-upload.service";
// import { AwsS3Service } from '../services/AwsS3Service/aws-s3.service';

@Component({
  selector: "app-container-list",
  templateUrl: "./container-list.component.html",
  styleUrls: ["./../console.component.css"]
})
export class ContainerListComponent implements OnInit {
  //, OnChanges {
  allContainers: any;
  topicsAndContainers: any;
  loading: boolean = false;
  error: boolean = true;
  @Input() showList;
  // ngOnChanges(changes: SimpleChanges): void {
  //   //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //   //Add '${implements OnChanges}' to the class.
  //   console.log(changes);
  //   if (changes.showList) {
  //     console.log("Hellllllllllooooooo");
  //   }
  // }
  constructor(
    private globals: Globals,
    private modalService: NgbModal,
    private apiFormatterService: ApiFormatterService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private uploadFilesService: FileUploadService,
    // private awsS3: AwsS3Service
  ) {
    //this.getContainers();
    //this.getTopicsAndContainers();
    //  this.showAddTopicFlag = false;
  }
  username: string;
  index: number;
  topics: any;
  showEditContainer: boolean = false;
  selectedContainerId: number;
  ngOnInit() {
    this.username = localStorage.getItem("currentUser");
    if (this.username && localStorage.getItem("consoleAccess") === "true") {
      this.getContainers();
      //this.getTopics();
    } else {
      this.router.navigate([""]);
    }
    // this.getTopics();
    // this.route.queryParams.subscribe(params => {
    //   this.username = params["username"];
    // });
  }
  // getTopics() {
  //   this.httpClient.get(this.globals.devDbUrl + "/getTopics").subscribe(res => {
  //     this.topics = res;
  //     this.index = -1;
  //     for (const topic of this.topics) {
  //       this.index++;
  //       this.getThemesNames(topic.topicid, this.index);
  //     }
  //   });
  // }
  // getThemesNames(topicID, index) {
  //   this.httpClient
  //     .get(this.globals.devDbUrl + "/getThemesByTopic/" + topicID + "/text")
  //     .subscribe(res => {
  //       this.topics[index].themeNames = res;
  //     });
  // }
  items: Object;
  title = "";
  createTopic: any;
  instanceModalContent: any;
  modalDataForInstance: Object;
  // Changes by Charan on Sept 24th - 1
  modalDataForContainer: Object;
  containerModalContent: any;
  // Changes by Charan ended here - 1
  //showAddTopicFlag: boolean;
  deleteObj: object;

  formatDate(date): string {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  dataFromInstanceModal(event: any, modal): void {
    event.fields.push({
      jsonKey: "createdOn",
      value: this.formatDate(new Date())
    });
    // console.log(event);

    event.postData(this.globals.devDbUrl, modal);
    modal.close('Saved');
  }

  getFieldIndexByJsonKey(fields: any[], jsonKey: string): number {
    return fields.findIndex(field => field.jsonKey == jsonKey);
  }

  findFieldAndAssign(fields: any[], arrayToPopulateWith: any[]) {
    fields.map(field => {
      let index: number = arrayToPopulateWith.findIndex(object => {
        if (field.jsonKey == object.jsonKey) return true;
      });
      if (index >= 0) field.value = arrayToPopulateWith[index].value;
    });
  }

  setDynamicDataForModal(event: any): any {
    if (event instanceof Topic){
      event = <Topic>event;
      let arrayToPopulateWith: any[] = [];
      let index = this.getFieldIndexByJsonKey(event.fields, "topicArtifacts");
      if (index >= 0) {
        let arrayToSetAsValue: any[] = [];
        event.fields[index].selectedValues.map(value => {
          // let dataFromApiIndex = event.fields[index]["dataFromApi"].findIndex(
          //   dataObject => dataObject.artifactID == value.value
          // );
          arrayToSetAsValue.push({
            taid: "",
            artifactid: value.value,
            topicid: ""
          });
        });
        arrayToPopulateWith.push({
          jsonKey: "topicArtifacts",
          value: arrayToSetAsValue
        });
      }
      let arrayForLogos: any[] = [];
      index = this.getFieldIndexByJsonKey(event.fields, "logos");
      if (index >= 0) {
        let field = event.fields[index];
        field.fields.map(subField => {
          if (subField.value && subField.value.length >= 0) {
            subField.jsonObject.logoname = subField.value;
            arrayForLogos.push(subField.jsonObject);
          }
        });
      }
      // console.log(arrayForLogos);
      arrayToPopulateWith.push({
        jsonKey: "logos",
        value: arrayForLogos
      });
      this.findFieldAndAssign(event.fields, arrayToPopulateWith);

    } else if(event instanceof Container) {

      event = <Container>event;
      let arrayToPopulateWith: any[]= [];

      let index = this.getFieldIndexByJsonKey(event.fields, "createdON");
      arrayToPopulateWith.push({
        jsonKey: "createdON",
        value: this.formatDate(new Date())
      });

      index = this.getFieldIndexByJsonKey(event.fields, "owner");
      if(index >= 0) {
        let arrayToSetAsValue: any[] = [];
        event.fields[index].selectedValues.map(value => {
          arrayToSetAsValue.push(value.value);
        });
        arrayToPopulateWith.push({
          jsonKey: "owner",
          value: arrayToSetAsValue
        });
      }
      this.findFieldAndAssign(event.fields, arrayToPopulateWith);
    }
    // console.log(arrayToPopulateWith);
  }

  dataFromModal(event, modal): void {
    // console.log('here')
    this.setDynamicDataForModal(event);
    // console.log(event);
    event.postData(this.globals.devDbUrl, modal /*, this.awsS3 */);
    modal.close("Done");
    //this.getTopicsAndContainers();
  }

  selectData(selector) {
    if (selector === "container") {
      this.items = new Container(this.apiFormatterService).getData(
        this.globals.devDbUrl
      );
      // } else if (selector === "topic") {
      // this.items = new Topic(
      //   this.apiFormatterService,
      //   this,
      //   this.uploadFilesService
      // ).getData(this.globals.devDbUrl);
    }
  }

  setContentForInstance(content): void {
    this.instanceModalContent = content;
  }

  editContainer(content, objectToPopulateWith) {
    this.items = new Container(this.apiFormatterService).getPopulatdData(
      objectToPopulateWith,
      this.globals.devDbUrl
    );

    this.openWindowCustomClass(content);
  }

  getContainers() {
    this.loading = true;
    this.error = false;
    let currentUser = this.apiFormatterService.removeQuotesFromString(localStorage.getItem('currentUser').trim());
    //  this.httpClient
    //   .get(this.globals.devDbUrl + "/console/getContainerList/"+ currentUser)
    
    let containerList = this.apiFormatterService.getApiRequest(this.globals.devDbUrl + "/console/getContainerList/"+ currentUser).toPromise()
    let containerOwners = this.apiFormatterService.getApiRequest(this.globals.devDbUrl + "/console/getArtifactOwners").toPromise()
    let owners = this.apiFormatterService.getApiRequest(this.globals.devDbUrl + "/user/getUsers").toPromise()
    Promise.all([containerList, containerOwners, owners]).then((values) => {
      this.loading = false
      this.allContainers = values[0]
      
      let containerOwnerMap = {}
      for(let relation of values[1])
        if(containerOwnerMap[relation.artifactid]) containerOwnerMap[relation.artifactid].push(relation.username)
        else containerOwnerMap[relation.artifactid] = [relation.username];
      let usernameNameMap = {}
      for(let user of values[2])
        usernameNameMap[user.userName] = (user.firstName ? user.firstName + " " : "") + (user.lastName ? user.lastName : "");

      // console.log(containerOwnerMap, usernameNameMap)

      for(let container of this.allContainers) {
        let ownerList = "";
        if(containerOwnerMap[container.artifact.artifactID]) {
          containerOwnerMap[container.artifact.artifactID].forEach(username => {
            ownerList += usernameNameMap[username] ? (ownerList ? ", " + usernameNameMap[username] : usernameNameMap[username]) : ""
          })
        }
        container.artifact["owner"] = ownerList
      }

      // console.log(this.allContainers)


    })
    .catch(error => {
      console.error(error)
      this.error = true;
      this.loading = false;
    })
  }

  newInstanceModal(event): void {
    event.target.blur();
    this.modalDataForInstance = new Instance(this.apiFormatterService).getData(
      this.globals.devDbUrl
    );
    this.openWindowCustomClass(this.instanceModalContent);
  }

  // Changes by Charan on Sept 24th after the latest git pull of the feature branch - 2

  newContainerModal(event, containerModalContent): void {
    event.target.blur();
    this.modalDataForContainer = new Container(
      this.apiFormatterService
    ).getData(this.globals.devDbUrl);
    this.openWindowCustomClass(containerModalContent);
  }

  async dataFromContainerModal(event, containerModal) {
    // console.log(event);
    // console.log(containerModal);
    // console.log("Heloooooooooooooooooooooooo");
    this.setDynamicDataForModal(event);
    let data = await event.postData(this.globals.devDbUrl, containerModal/*, this.awsS3*/);
    containerModal.close("Done");
    // console.log(data);
    localStorage.setItem(
      "container-" + data["artifactID"],
      JSON.stringify(data)
    );
    this.routeToAddContainer(data["artifactID"]);
  }

  // Changes by Charan ended here - 2

  openWindowCustomClass(content) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static"
    });
  }

  setDeleteModalContainer(container, deleteContent) {
    this.deleteObj = {
      title: "Delete Container",
      name: container.artifact.artifactName,
      id: container.artifact.artifactID,
      callbackFunc: this.dataService.postData(
        "/console/deleteContainer/" + container.artifact.artifactID,
        {}
      )
    };
    this.openDeleteModal(deleteContent);
  }
  openDeleteModal(deleteContent) {
    this.modalService.open(deleteContent, {
      centered: true,
      backdrop: "static"
    });
  }
  dataFromDeleteModal(deleteObj) {
    this.getContainers();
  }
  routeToAddContainer(containerId) {
    this.selectedContainerId = containerId;
    this.showEditContainer = !this.showEditContainer;
    // this.setShowList(this.ref, true);
    //  this.router.navigate(["container", "" + containerId]);
  }
  gobackToContainerList() {
    // this.selectedContainerId = containerId;
    this.getContainers();
    this.showEditContainer = false;
    // this.setShowList(this.ref, false);
  }

  checkIfRunningOrStopped(container): boolean {
    if(container.artifact.instances && container.artifact.instances.length>0)
    return true;
    else return false;
    // for(let instance of container.artifact.instances) {
    //   let status = instance.status.trim().toLowerCase();
    //   if(status === "running" || status === "stopped") return true;
    // }
    // return false;
  }

}
