import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-console-menu',
  templateUrl: './console-menu.component.html',
  styleUrls: ['./console-menu.component.css']
})
export class ConsoleMenuComponent implements OnInit {
  @Input() selectedMenu: string;
  constructor() { }

  ngOnInit() {
  }

}
