import { Component, OnInit, Input, OnChanges, ViewChild, ViewContainerRef, HostListener } from '@angular/core';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { ThemesComponent } from '../../topic/themes/themes.component';
import { TopicComponent } from '../../topic/topic.component';
import { Globals } from '../../config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Solution } from './solution';
import { ApiFormatterService } from '../../common/services/Api Formatter/api-formatter.service';
import { Content } from './content';
import { Topic } from '../custom-modal/topic';
import { ConsoleComponent } from '../console.component';
import { DataService } from '../../register/services/data.service';
import { FileUploadService } from '../services/fileUploadService/file-upload.service';
import { TopicListComponent } from '../topic-list/topic-list.component';
import { async } from '../../../../node_modules/@types/q';
import { Router } from '@angular/router';
import { SpeechService } from 'src/app/common/services/speech-service/speech.service';
import { Observable, of, from } from 'rxjs';

@Component({
  selector: 'app-edit-theme',
  templateUrl: './edit-theme.component.html',
  styleUrls: ['../../topic/themes/themes.component.css'],
  providers: [ThemesComponent, TopicComponent, Globals]
})
export class EditThemeComponent implements OnInit, OnChanges {
  constructor(
    private topicComponent: TopicComponent,
    private globals: Globals,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private apiFormatterService: ApiFormatterService,
    private consoleComponent: ConsoleComponent,
    private dataService: DataService,
    private uploadFilesService: FileUploadService,
    private topicListComponent: TopicListComponent,
    private tabset: ViewContainerRef,
    private router: Router,
    private speechService: SpeechService
  ) {
    // this.getTopics();
  }

  @ViewChild(NgbTabset)
  set content(content: ViewContainerRef) {
    this.tabset = content;
  }
  @Input() topic: any;
  @Input() username: any;
  error: any;
  topicName: string;
  topicDescription: string;
  selectedTopicID: any;
  topicLogo: any;
  selectedTopic: any;
  tabObj: any;
  tabs: any;
  dataForModal: Object;
  @Input() topics: any;
  consoleRoute: string = '/';
  selectedTabId: string;
  t: any;
  taborderupdate: any;
  public loading = false;

  ngOnInit() {
    this.loadthemes(this.topic);
    this.speechService.initializeSpeech();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.cancelAudioPlay();
  }

  @HostListener('window:beforeunload')
  doBeforeUnload() {
    this.cancelAudioPlay();
  }

  ngAfterViewInit() { }
  ngOnChanges(changes) { }

  beforeChange(event) {
    // console.log("tabChange:", event.nextId);
    this.selectedTabId = event.nextId;
    if (event.nextId === 'addContentTab') {
      event.preventDefault();
      if (event.stopImmediatePropagation) event.stopImmediatePropagation();
    }
  }

  async loadthemes(topic) {
    // debugger;
    this.selectedTopic = topic;
    //console.log(this.selectedTopic);
    this.selectedTopicID = topic.topicid;
    this.getTabs(topic.topicid);
    this.topicLogo = await this.topicComponent.getTopicLogoBasedOnType(topic.logos, 'blue', topic.topicid);
    // this.updateSelectedTopicAfterTopicUpdate(topic.topicid);
  }

  getTopics(topicId: any): void {
    this.error = false;

    this.apiFormatterService
      .getApiRequest(this.globals.backendServicesUrl + 'console/getTopicByTopicID/' + topicId)
      .subscribe(
        (topic) => {
          // console.log(topic);
          this.loadthemes(topic[0]);
        },
        (err) => {
          // console.log(err);
          this.error = true;
          this.loading = false;
        }
      );
  }

  getTabs(topicID, tabset?: any) {
    this.loading = true;
    this.error = false;
    // console.log(this.topic);
    // this.httpClient
    //   .get(this.globals.devDbUrl + "/getTabContent/" + topicID)
    this.apiFormatterService.getApiRequest(this.globals.devDbUrl + '/getTabContent/' + topicID).subscribe(
      (res) => {
        this.tabs = res;
        this.tabs.sort((tab1, tab2) => tab1.tabid - tab2.tabid);
        // console.log(this.tabs);
        //console.log("selectedTabId:", this.selectedTabId);
        // debugger;
        for (let index in this.tabs) {
          // this.httpClient
          //   .get(this.globals.devDbUrl + "/getThemes/" + this.tabs[index].tabid)
          this.apiFormatterService
            .getApiRequest(this.globals.devDbUrl + '/getThemes/' + this.tabs[index].tabid)
            .subscribe(
              (res) => {
                this.tabs[index].themes = res;
              },
              (err) => {
                // console.log(err);
                this.error = true;
                this.loading = false;
              }
            );
        }
        this.loading = false;
        if (tabset) {
          tabset.activeId = this.selectedTabId;
        }
        if (this.selectedTabId != undefined && this.selectedTabId != 'addContentTab' && this.t) {
          this.selectedTabId = this.selectedTabId.toString();
          this.t.select(this.selectedTabId);
        }
      },
      (err) => {
        // console.log(err);
        this.error = true;
        this.loading = false;
      }
    );
  }

  getLogoName(logoName, id) {
    if (logoName) return this.globals.imagePath + 'Theme_' + id + '/' + logoName.trim();
    else return this.globals.filesServerUrl + '/resources/eqpmtMonitor.svg';
  }

  getUrlBasedOnType(resources, type) {
    for (var resource of resources) {
      if (resource.resourcetype == type) {
        // console.log(resource.name);
        return this.sanitizer.bypassSecurityTrustResourceUrl(resource.resourcename);
      }
    }
    return '';
  }

  // AUDIO functionality from here

  audioStatus: number = 0;
  // Values of Audio status: -1 -> Paused, 0 -> Stopped, 1 -> Playing

  toggleAudioPlay() {
    let speech = this.speechService.getSpeech();
    if (speech && speech.speaking() && this.audioStatus == 1) {
      speech.pause();
      this.audioStatus = -1;
    } else if (speech && speech.paused() && this.audioStatus == -1) {
      speech.resume();
      this.audioStatus = 1;
    }
  }

  cancelAudioPlay() {
    let speech = this.speechService.getSpeech();
    if (speech) {
      speech.cancel();
    }
    this.audioStatus = 0;
  }

  async playAudioResource(p, id, resources, type, themeOrTab) {
    // console.log(this.audioStatus, this.speechService.getSpeech().paused())

    if (this.audioStatus == 1 || this.audioStatus == -1) {
      this.cancelAudioPlay();
      return;
    }
    let data = await this.getResourceData(id, resources, type, themeOrTab);
    if (this.speechService.getSpeech()) {
      // console.log("Start speaking")
      this.speechService.speak(data, p, this);
      this.audioStatus = 1;
    } else console.error('Speech service not available!');
  }

  async getResourceData(id, resources, type, themeOrTab) {
    let fileName = '';
    if (themeOrTab === 'theme') {
      fileName = 'Theme_' + id;
    } else {
      fileName = 'Tab_' + id;
    }
    try {
      for (let resource of resources) {
        if (type != 'URL' && resource.resourcetype == type) {
          // console.log([fileName, resource.resourcename]);
          let data = await this.dataService.getDataFromNode([fileName, resource.resourcename]).toPromise();
          // console.log(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  // TILL HERE

  getResourceBasedOnType(id, resources, type, themeOrTab, x?: any) {
    //console.log(x);
    let fileName = '';
    if (themeOrTab === 'theme') {
      fileName = 'Theme_' + id;
    } else {
      fileName = 'Tab_' + id;
    }
    for (var resource of resources) {
      if (resource.resourcetype == type) {
        if (type === 'URL') {
          return resource.resourcename;
        }
        return this.globals.imagePath + fileName + '/' + resource.resourcename;
      }
    }
    // console.log(this.sanitizer.bypassSecurityTrustResourceUrl(""));
    return '';
  }
  themeClicked(event, resources) {
    let urlFound = false;
    for (var resource of resources) {
      if (resource.resourcetype === 'URL' && resource.resourcename.length > 0) {
        urlFound = true;
      }
    }
    if (!urlFound) {
      event.preventDefault();
    }
    event.stopPropagation();
  }
  getResourceNameOnly(resources, type) {
    for (var resource of resources) {
      if (resource.resourcetype == type) {
        if (type === 'URL') {
          return resource.resourcename;
        }
        return resource.resourcename;
      }
    }
    // console.log(this.sanitizer.bypassSecurityTrustResourceUrl(""));
    return '';
  }
  isResource(resources, type) {
    // debugger;
    for (var resource of resources) {
      if (resource.resourcetype == type && resource.resourcename.trim().length > 0) {
        return true;
      }
    }

    return false;
  }

  goToHome() {
    this.consoleRoute = '/console';
  }

  findFieldAndAssign(fields: any[], arrayToPopulateWith: any[]) {
    fields.map((field) => {
      let index: number = arrayToPopulateWith.findIndex((object) => {
        if (field.jsonKey == object.jsonKey) return true;
      });
      if (index >= 0) field.value = arrayToPopulateWith[index].value;
    });
  }

  getFieldValueByJsonKey(fields: any[], jsonKey: string): any {
    let index = fields.findIndex((field) => field.jsonKey == jsonKey);
    if (index >= 0) return fields[index]['value'];
    return '';
  }

  getFieldIndexByJsonKey(fields: any[], jsonKey: string): number {
    return fields.findIndex((field) => field.jsonKey == jsonKey);
  }

  setDynamicDataForModal(typeOfData, arrayToAdd: any[], objectToAddTo: any) {
    // debugger;
    if (typeOfData == 'content') {
      let content: any = <Content>objectToAddTo;
      let arrayForResources: any[] = [];
      let index: number = this.getFieldIndexByJsonKey(content.fields, 'resources');
      let index2: number = this.getFieldIndexByJsonKey(content.fields, 'contenttype');
      if (index >= 0) {
        let field = content.fields[index];
        field.fields.map((subField) => {
          if (index2 >= 0 && subField.jsonKey === "image") {
            if (content.fields[index2].value === "text") {
              subField.required = false;
            } else {
              subField.required = true;
            }
          }
          if (subField.value != undefined) {
            subField.jsonObject.resourcename = subField.value;
            arrayForResources.push(subField.jsonObject);
          }
        });
      }
      //  debugger;
      // let taborder = 0;

      if (content.title != 'Update Content Tab') {
        this.taborderupdate = 0;
      }

      let arrayToPopulateWith: any[] = [
        {
          jsonKey: 'tabContent',
          value: {
            contentname: this.getFieldValueByJsonKey(content.fields, 'themename'),
            contentdesc: this.getFieldValueByJsonKey(content.fields, 'themedesc'),
            taborder: this.taborderupdate ? this.taborderupdate : this.tabs.length + 1,
            topicid: this.selectedTopicID,
            tabid: this.getFieldValueByJsonKey(content.fields, 'tabid'),
            showtab: this.getFieldValueByJsonKey(content.fields, 'showtab'),
            contenttype: this.getFieldValueByJsonKey(content.fields, 'contenttype')
          }
        },
        {
          jsonKey: 'resources',
          value: arrayForResources
        }
      ];
      arrayToPopulateWith.push(...arrayToAdd);
      this.findFieldAndAssign(content.fields, arrayToPopulateWith);
      // arrayToAdd.map(object => content.fields.push(object));
    } else if (typeOfData == 'solution') {
      let solution: any = <Solution>objectToAddTo;
      let arrayForResources: any[] = [];
      let index: number = this.getFieldIndexByJsonKey(solution.fields, 'resources');
      if (index >= 0) {
        let field = solution.fields[index];
        field.fields.map((subField) => {
          if (subField.value != undefined) {
            subField.jsonObject.resourcename = subField.value;
            arrayForResources.push(subField.jsonObject);
          }
        });
      }

      let arrayToPopulateWith: any[] = [
        {
          jsonKey: 'topicid',
          value: '' + this.selectedTopicID + ''
        },
        {
          jsonKey: 'contenttype',
          value: 'text'
        },
        {
          jsonKey: 'resources',
          value: arrayForResources
        }
      ];
      arrayToPopulateWith.push(...arrayToAdd);
      this.findFieldAndAssign(solution.fields, arrayToPopulateWith);
      // arrayToAdd.map(object => solution.fields.push(object));
    } else if (typeOfData == 'topic') {
      // debugger;
      let topic = objectToAddTo;
      let arrayToPopulateWith: any[] = [];
      let index = this.getFieldIndexByJsonKey(topic.fields, 'topicArtifacts');
      if (index >= 0) {
        let arrayToSetAsValue: any[] = [];
        topic.fields[index].selectedValues.map((value) => {
          arrayToSetAsValue.push({
            taid: '',
            artifactid: value.value,
            topicid: ''
          });
        });

        arrayToPopulateWith.push({
          jsonKey: 'topicArtifacts',
          value: arrayToSetAsValue
        });
      }

      index = this.getFieldIndexByJsonKey(topic.fields, "topicInstances");
      if (index >= 0) {
        let arrayToSetAsValue: any[] = [];
        topic.fields[index].selectedValues.map(value => {
          arrayToSetAsValue.push(value.value);
        });
        arrayToPopulateWith.push({
          jsonKey: "topicInstances",
          value: arrayToSetAsValue
        });
      }

      index = this.getFieldIndexByJsonKey(topic.fields, 'owner');
      if (index >= 0) {
        let arrayToSetAsValue: any[] = [];
        topic.fields[index].selectedValues.map((value) => {
          arrayToSetAsValue.push(value.value);
        });
        arrayToPopulateWith.push({
          jsonKey: 'owner',
          value: arrayToSetAsValue
        });
      }
      index = this.getFieldIndexByJsonKey(topic.fields, 'country');
      if (index >= 0) {
        let arrayToSetAsValue: any[] = [];
        topic.fields[index].selectedValues.map((value) => {
          arrayToSetAsValue.push(value.value);
        });
        arrayToPopulateWith.push({
          jsonKey: 'country',
          value: arrayToSetAsValue
        });
      }


      let arrayForLogos: any[] = [];
      index = this.getFieldIndexByJsonKey(topic.fields, 'logos');
      if (index >= 0) {
        let field = topic.fields[index];
        field.fields.map((subField) => {
          if (subField.value != undefined) {
            subField.jsonObject.logoname = subField.value;
            arrayForLogos.push(subField.jsonObject);
          }
        });
      }
      //   console.log(arrayForLogos);
      arrayToPopulateWith.push({
        jsonKey: 'logos',
        value: arrayForLogos
      });
      //  console.log(arrayToPopulateWith);
      this.findFieldAndAssign(topic.fields, arrayToPopulateWith);
    }
  }

  selectDataForModal(typeOfData, arrayToAdd: any[]) {
    if (typeOfData == 'content') {
      let content: any = new Content(this.apiFormatterService, this.uploadFilesService);
      this.setDynamicDataForModal(typeOfData, arrayToAdd, content);
      this.dataForModal = content;
    } else if (typeOfData == 'solution') {
      let solution: any = new Solution(this.apiFormatterService, this.uploadFilesService).getData(this.globals.devDbUrl, this.selectedTopicID);
      this.setDynamicDataForModal(typeOfData, arrayToAdd, solution);
      this.dataForModal = solution;
    }
    this.dataForModal['modalCreateButtonClicked'] = false;
  }

  openVerticallyCentered(content, $event) {
    this.openModalWindow(content, 'pdf-and-video-modal');
  }
  // customTrackBy(index: number, obj: any): any {
  //   // console.log("obj:", obj);
  //   return index;
  // }
  openModalWindow(content, applyClass = '') {
    //   console.log(this.dataForModal);
    // content['modalCreateButtonClicked'] = false;
    if (applyClass) this.modalService.open(content, { centered: true, windowClass: applyClass });
    else this.modalService.open(content, { centered: true, backdrop: 'static' });
  }

  openAddSolutionModal(modalContent: any) {
    this.openModalWindow(modalContent);
  }

  openAddContentModal(modalContent: any) {
    this.openModalWindow(modalContent);
  }

  openEditModal(typeOfData, objectToPopulateWith: any, modalContent, $event) {
    if ($event.stopImmediatePropagation) $event.stopImmediatePropagation();
    $event.preventDefault();
    //console.log(objectToPopulateWith);
    if (typeOfData == 'content') {
      if (objectToPopulateWith.themes[0]) {
        this.taborderupdate = objectToPopulateWith.taborder;
        objectToPopulateWith.themes[0]['showtab'] = objectToPopulateWith.showtab;
        objectToPopulateWith = objectToPopulateWith.themes[0];
      }

      let content: Content = new Content(this.apiFormatterService, this.uploadFilesService).getPopulatdData(
        objectToPopulateWith,
        this.globals.devDbUrl
      );
      //console.log(content);
      this.setDynamicDataForModal(typeOfData, [], content);
      this.dataForModal = content;
    }
    if (typeOfData == 'solution') {
      let solution: Solution = new Solution(this.apiFormatterService, this.uploadFilesService).getPopulatdData(
        objectToPopulateWith,
        this.globals.devDbUrl,
        this.selectedTopicID
      );
      this.setDynamicDataForModal(typeOfData, [], solution);
      this.dataForModal = solution;
    }
    if (typeOfData == 'topic') {
      if (typeof objectToPopulateWith["horizantals"] === 'string') {
        objectToPopulateWith["horizantals"] = ((objectToPopulateWith["horizantals"]).replace(/\s/g, '')).split(",");
      }

      this.dataForModal = new Topic(
        this.globals,
        this.apiFormatterService,
        this.topicListComponent,
        this.uploadFilesService
      ).getPopulatdData(objectToPopulateWith, this.globals.devDbUrl);
    }
    this.openModalWindow(modalContent);
  }

  async dataFromModal(event: any, modal) {
    // console.log(event);
    // console.log(modal);
    if (event instanceof Content) {
      // console.log("Heyyyyy");
      this.setDynamicDataForModal('content', [], event);
    }
    if (event instanceof Topic) {
      this.setDynamicDataForModal('topic', [], event);
    }
    if (event instanceof Solution) {
      this.setDynamicDataForModal('solution', [], event);
    }
    modal['editThemeRef'] = this;

    await event.postData(this.globals.devDbUrl, modal);
    // await this.loadthemes(this.selectedTopic);
    // console.log(this.selectedTopic);
    //await this.getTabs(this.selectedTopicID);
    await modal.close('Done');
  }

  setRequiredOfImage(event: any, modal) {
    if (event instanceof Content) {
      let selectItem;
      let imageSubItem;
      event["fields"].map((item) => {
        if (item.jsonKey == "contenttype") {
          selectItem = item;
        } else if (item.jsonKey === "resources") {
          item["fields"].map((rsrc) => {
            if (rsrc.jsonKey === "image") {
              imageSubItem = rsrc;
            }
          });
        }
      });
      if (selectItem && imageSubItem) {
        if (selectItem.value === "text") {
          imageSubItem.required = false;
        } else {
          imageSubItem.required = true;
        }
      }
    }
  }

  deleteModalButtonName = "";

  onDeselectInMultiselect(event, deleteContent) {
    if (!event.item || !event.item.handleDeselect) return;
    // let observableOfItem = of(event);

    let isAnyDeselectValueFromOriginalValues = false;

    event.deselectedValues.forEach(deselectOption => {
      let index = event.item.value.findIndex(valueOption => valueOption.value == deselectOption.value);
      if (index >= 0) isAnyDeselectValueFromOriginalValues = true;
    });

    if (!isAnyDeselectValueFromOriginalValues) return;

    let valuesAfterDeselect = [...event.item.selectedValues];
    event.deselectedValues.forEach(option => {
      event.item.selectedValues.push(option);
    });

    let itemOptionsBackup = [...event.item.options];
    event.item.options = [];
    // event.item.options = itemOptionsBackup;

    this.tabObj = {
      title: "Remove Container Confirmation",
      name: "None",
      showMessageInstead: "The demo links are going to be broken and need a manual change.",
      action: () => {
        event.item.selectedValues = valuesAfterDeselect;
        event.item.options = itemOptionsBackup;
      }
    };
    this.deleteModalButtonName = "Accept";

    this.modalService.open(deleteContent, {
      centered: true,
      backdrop: 'static',
      beforeDismiss: () => {
        event.item.options = itemOptionsBackup;
        if (this.dataForModal instanceof Topic && event.isDeselectAll) {
          if (event.isDeselectAll) {
            event.item.selectedValues = [...event.deselectedValues];
          }
          this.dataForModal.handleMultiselectChange(this.globals.devDbUrl, event.item);
        }
        return true;
      }
    });
  }

  openDeleteModal(deleteContent) {
    this.modalService.open(deleteContent, {
      centered: true,
      backdrop: 'static'
    });
  }

  dataFromDeleteModal(event) {
    this.getTabs(this.selectedTopicID);
    // console.log(JSON.stringify(event));
  }

  setDeleteModalContent(tab, deleteContent) {
    // console.log(tab);

    this.tabObj = {
      title: 'Delete Tab',
      name: tab.contentname,
      id: tab.tabid,
      callbackFunc: this.dataService.postData('/console/deleteTabContent', {
        tabid: tab.tabid
      })
    };
    this.deleteModalButtonName = "";
    // debugger;
    this.openDeleteModal(deleteContent);
  }
  setDeleteModalTheme(theme, deleteContent) {
    this.tabObj = {
      title: 'Delete Theme',
      name: theme.themename,
      id: theme.themeid,
      callbackFunc: this.dataService.putData('/console/deleteTheme', {
        themeid: theme.themeid
      })
    };
    this.openDeleteModal(deleteContent);
  }
}
