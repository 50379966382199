import { ConsoleComponent } from './console.component';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';


export class WebSocketAPI {
    webSocketEndPoint: string = "";
    topic: string = "";
    sendTo: string = ""
    
    stompClient: any;
    componentRef: any;
    status: number = 0;
    // topicName: string;

    // selectTopicApi(topicName) {
    //     let api = topicName;
    //     if(topicName == "Predictive Maintenance")
    //         api = "pdm";
    //     return api.toLowerCase();
    // }
    
    constructor(webSocketEndPoint: string, topicToSubscribe: string, sendToLink: string, componentRef: any){
        this.componentRef = componentRef;
        this.webSocketEndPoint = webSocketEndPoint;
        this.topic = topicToSubscribe;
        this.sendTo = sendToLink;
    }

    assignTopicStatusBasedOnMessage(message){
        if(message.topicName && message.listOfIntances && message.listOfInstances[0]['ipaddress'])
            this.componentRef.topicStatusCode[message.topicName][message.listOfInstances[0].ipaddress] = -1;
            
        // if(message == "start") {
        //     this.consoleComponent.topicStatusCode[this.topicIndex] = -1;
        // } else if(message == "stop") {
        //     this.consoleComponent.topicStatusCode[this.topicIndex] = 1;
        // }
    }

    _connect(msg = null) {
        // if(!this.consoleComponent.topicApi[this.topicName]) return;
        // console.log("Initialize Websocket Connection");
        let ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(ws);
        const _this = this;
        // Comment the line below to show STOMP message to console
        this.stompClient.debug = () => {};
        _this.stompClient.connect({}, (frame) => {
            // console.log("Connected");
            _this.status = 1;
            if(msg){
                _this._send(msg);
                // this.assignTopicStatusBasedOnMessage(msg);
            }
            
            _this.stompClient.subscribe(_this.topic, (sdkEvent) => {
                _this.onMessageReceived(sdkEvent);
                // console.log(_this.status);
            });
        }, () => {
            // console.log(_this)
            let id = setInterval(() => {
                if(_this.status == 1) clearInterval(id)
                _this._disconnect()
                _this._connect()
            }, 5000)
        });
    }

    _disconnect(){
        if (this.stompClient != null){
            this.stompClient.disconnect();
            this.status = 0
        }
        // console.log("Disconnected");
    }

    errorCallBack(error){
        setTimeout(() => {
            this._connect();
        }, 5000);
    }

    _send(message) {
        if(this.status == 0){
            this._connect(message);
            if(this.componentRef.callOnStompMessageSent) this.componentRef.callOnStompMessageSent(message);
            return;
        }
        // console.log("calling logout api via web socket");
        // app/instance/demo
        this.stompClient.send(this.sendTo, {}, JSON.stringify(message));
        if(this.componentRef.callOnStompMessageSent) this.componentRef.callOnStompMessageSent(message);
        // console.log(this.status);
        // console.log(this.consoleComponent.topicStatusCode);
    }

    onMessageReceived(message) {
        // console.log("Message Recieved from Server :: " + message);
        if(this.componentRef.handleMessageFromStomp) this.componentRef.handleMessageFromStomp(message.body);
    }
}