import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Globals } from "../../config";

@Injectable({
  providedIn: "root"
})
export class DataService {
  constructiveUrl = this.globals.devDbUrl;
  constructor(private httpClient: HttpClient, private globals: Globals) {}
  getData(url): Observable<any> {
    url = this.globals.backendServicesUrl + url;
    return this.httpClient.get<any>(url);
  }
  getResourcesData(url): Observable<any> {
    url = this.globals.filesServerUrl + url;
    return this.httpClient.get<any>(url);
  }

  getDataFromNode(params: any[]): Observable<string> {
    let constructiveUrl = this.globals.imagePath;
    for (let i of params) {
      constructiveUrl += i + "/";
    }
    return this.httpClient.get(constructiveUrl, { responseType: "text" });
  }

  postData(url, data): Observable<any> {
    // debugger;
    url = this.constructiveUrl + url;
    return this.httpClient.post(url, data, { responseType: "text" });
  }

  putData(url, data): Observable<any> {
    url = this.constructiveUrl + url;
    return this.httpClient.put<any>(url, data, {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    });
  }
  getDataWithParam(url, param): Observable<any> {
    url = this.constructiveUrl + url;
    return this.httpClient.get<any>(url + "/" + param);
  }
}
