import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Globals } from "src/app/config";

@Injectable({
  providedIn: "root"
})
export class FileUploadService {
  constructor(private httpClient: HttpClient, private globals: Globals) {}

  postFiles(formData): Observable<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    return this.httpClient.post<any>(
      this.globals.filesUploadServicePath,
      formData,
      { headers: headers }
    );
  }

  postFilesToTemp(uploadObj): Observable<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    return this.httpClient.post<any>(
      this.globals.tempFilesUploadServicePath,
      uploadObj,
      { headers: headers }
    );
  }

  moveFilesFromTempToUploads(uploadObj): Observable<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    return this.httpClient.post<any>(
      this.globals.moveFilesFromTempToUploads,
      uploadObj,
      { headers: headers }
    );
  }

  deleteFilesFromTemp(uploadObj): Observable<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    return this.httpClient.post<any>(
      this.globals.deleteFilesFromTemp,
      uploadObj,
      { headers: headers }
    );
  }

}
