import { Options } from "./options";
import { Globals } from "src/app/config";
import { ApiFormatterService } from "src/app/common/services/Api Formatter/api-formatter.service";
// import { AwsS3Service } from '../services/AwsS3Service/aws-s3.service';

export class Container {
  constructor(
    private apiFormatter: ApiFormatterService,
    containerObject?: any
  ) {
    if (containerObject) {
      this.fields[0].value = containerObject["artifactName"];
      this.fields[1].value = containerObject["artifactType"];
      this.fields[2].value = containerObject["version"];
      this.fields[3].value = containerObject["createdON"];
      //  console.log(this.fields[3].value);
      // this.fields.splice(3, 1);
    }
  }

  title = "New Container";
  buttons = [
    {
      value: "Next",
      url: "/console/addArtifact",
      methodType: "PUT"
    }
  ];
  action = "INSERT";
  fields = [
    // {
    //   type: "",
    //   jsonKey: "instanceid",
    //   label: "Instance",
    //   options: [],
    //   // TODO: Update this after getting new API
    //   value: "instance1",
    //   required: true,
    //   error: false,
    //   errorMsg: "",
    //   pattern: "",
    //   patternErrorMsg: ""
    // },
    {
      type: "text",
      jsonKey: "artifactName",
      label: "Name",
      value: "",
      required: true,
      error: false,
      errorMsg: "Required",
      pattern: "",
      patternErrorMsg: ""
    },
    // {
    //   type: "",
    //   jsonKey: "ipaddress",
    //   label: "IP Address",
    //   // TODO: Update this after getting new API
    //   value: "111.11.1.1",
    //   required: true,
    //   error: false,
    //   errorMsg: "",
    //   pattern: "",
    //   patternErrorMsg: ""
    // },
    {
      type: "select",
      jsonKey: "artifactType",
      options: [
        { name: "Cafe Based", value: "Cafe Based" },
        { name: "Other", value: "Other" }
      ],
      label: "Type",
      value: "Cafe Based",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "",
      helpIcon: "The system supports  2 type containers: cafe platform or others."
    },
    // {
    //   type: "",
    //   // TODO: Correct the speling below
    //   jsonKey: "continerid",
    //   label: "Container ID",
    //   value: "" + Math.random() * 1000 + "",
    //   required: true,
    //   error: false,
    //   errorMsg: "",
    //   pattern: "",
    //   patternErrorMsg: ""
    // },
    // {
    //   type: "",
    //   jsonKey: "port",
    //   label: "Port",
    //   // TODO: Update this after getting new API
    //   value: "1091",
    //   required: true,
    //   error: false,
    //   errorMsg: "",
    //   pattern: "",
    //   patternErrorMsg: ""
    // },
    // {
    //   type: "",
    //   jsonKey: "description",
    //   label: "Description",
    //   // TODO: Update this after getting new API
    //   value: "desc",
    //   required: false,
    //   error: false,
    //   errorMsg: "",
    //   pattern: "",
    //   patternErrorMsg: ""
    // },
    {
      type: "text",
      jsonKey: "version",
      label: "Version",
      value: "",
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "",
      jsonKey: "createdON",
      label: "Created On",
      value: "",
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "multiSelect",
      jsonKey: "owner",
      label: "Owner",
      value: [],
      selectedValues: [],
      options: [],
      settings: {
        singleSelection: false,
        idField: "value",
        textField: "name",
        selectAllText: "Select All",
        unSelectAllText: "Unselect All",
        itemsShowLimit: 3,
        allowSearchFilter: true
      },
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "",
      helpIcon: "Select users who can add instances to the container"
    },
    {
      type: "",
      jsonKey: "artifactID",
      label: "",
      value: null
    },
    // {
    //   type: "",
    //   jsonKey: "status",
    //   options: [
    //     { name: "Running", value: "RUNNING" },
    //     { name: "Stopped", value: "STOPPED" }
    //   ],
    //   label: "Status",
    //   value: "STOPPED",
    //   required: true,
    //   error: false,
    //   errorMsg: "",
    //   pattern: "",
    //   patternErrorMsg: ""
    // }

    {
      type: "preUploadFileWithUrl",
      jsonKey: "codeUrl",
      label: "Demo Package",
      selectedType: "file",
      fieldType: "file",
      placeholder: "Choose docker file (*.zip) to upload",
      helpIcon: "Docker image that include the demo to deploy",
      showInUpdateAs: "text",
      value: undefined,
      fileType: "zip",
      fileObj: "",
      preUpload: true,
      uploading: false,
      uploadToS3: true,
      progress: {
        loaded: 0,
        total: 0,
        message: ""
      },
      directoryPathFieldJsonKey: "artifactName",
      directoryPath: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: ".(zip)$",
      patternErrorMsg: "Upload a valid File (.zip)",
      accept: ".zip",
      showOptions: false,
      sideOptions: [
        { name: "File", value: "file" },
        { name: "Url", value: "url" }
      ],
      jsonObject: {
        fileid: "",
        filename: "",
        filetype: "",
      },
      specialValidationNotMet: function (containerObj) {
        // console.log('validating');
        let index: number = containerObj.fields.findIndex(field =>
          field.jsonKey == "artifactName"
        );
        if (index >= 0) {
          if (!containerObj.fields[index].value) {
            containerObj.fields[index].error = true;
            this.error = true;
            this.errorMsg = "Please fill the Name before you upload a file.";
          } else if (this.value) {
            this.error = false;
            containerObj.fields[index]["makeReadOnly"] = true;
          }
        }
      }
    },
    {
      type: "preUploadFileWithUrl",
      jsonKey: "dscrptrUrl",
      label: "Demo Descriptor",
      selectedType: "file",
      fieldType: "file",
      placeholder: "Choose file (*.json) to upload",
      helpIcon: "Container descriptor to expose resources availables and deployment scripts",
      showInUpdateAs: "text",
      value: undefined,
      fileType: "json",
      fileObj: undefined,
      preUpload: true,
      uploading: false,
      uploadToS3: true,
      progress: {
        loaded: 0,
        total: 0,
        message: ""
      },
      directoryPath: "",
      directoryPathFieldJsonKey: "artifactName",
      readFileContent: true,
      required: true,
      error: false,
      errorMsg: "",
      pattern: ".(json)$",
      patternErrorMsg: "Upload a valid File Type",
      accept: ".json",
      showOptions: false,
      sideOptions: [
        { name: "File", value: "file" },
        { name: "URL", value: "url" }
      ],
      jsonObject: {
        fileid: "",
        filename: "",
        filetype: "",
      },
      specialValidationNotMet: function (containerObj) {
        let index: number = containerObj.fields.findIndex(field =>
          field.jsonKey == "artifactName"
        );
        if (index >= 0) {
          if (!containerObj.fields[index].value) {
            containerObj.fields[index].error = true;
            this.error = true;
            this.errorMsg = "Please fill the Name before you upload a file.";
          } else if (this.value) {
            this.error = false;
            containerObj.fields[index]["makeReadOnly"] = true;
          }
        }
      }
    }

  ];

  async postData(globalUrl, containerComponentRef) {
    // debugger;
    // this.fields[3].value = new Date();
    containerComponentRef.loading = true;

    // for(let i = 0; i < this.fields.length; i++) {
    //   if(this.fields[i].uploadToS3 && this.fields[i].fileObj) {
    //     let res = await awsS3.uploadFileToBucket(this.fields[i].fileObj);
    //     console.log(res);
    //     if(res['Location']) this.fields[i].value = res['Location'];
    //   }
    // }



    // console.log(this.fields);


    //### TO read file and set content as field value ###
    // let i = this.fields.findIndex(field => {
    //   return field.jsonKey == "demoDescriptor";
    // })
    // if(i >= 0){
    //   let read = new FileReader();
    //   // if(this.fields[i]["fileObj"] instanceof Blob)
    //   read.readAsText(this.fields[i]["fileObj"]);
    //   read.onloadend = () => {
    //     this.fields[i].value = read.result;
    //   };
    // }

    // Changes made by Charan on Sept 24th - 5 (other changes in container-list component)
    try {
      var data = await this.apiFormatter
        .putApiRequest(globalUrl + this.buttons[0].url, this.fields)
        .toPromise();
    } catch (err) {
      console.error(err);
    }


    // console.log(data);
    containerComponentRef.responseFromSaveContainer = data;
    containerComponentRef.loading = false;
    return data;
    // .subscribe(data => {
    //   console.log(data);
    //   containerComponentRef.responseFromSaveContainer = data;
    //   containerComponentRef.loading = false;
    //   return data;
    // });

    //Changes by Charan ended here - 5 (other changes in container-list component)
  }

  async linkSelectedInstances(
    globalUrl,
    instancesList: any[],
    containerComponentRef
  ) {
    let data = await this.apiFormatter
      .putApiRequestWithRawData(
        globalUrl + "/console/addDeployDetails",
        instancesList
      )
      .toPromise();
    return data;
  }

  getData(globalUrl): Container {
    let container: Container = new Container(this.apiFormatter);

    // new Options(this.apiFormatter).setOptionsFromApi(
    //   globalUrl + "/console/getInstances",
    //   container,
    //   "instanceId",
    //   {
    //     name: "Add new Instance...",
    //     value: "newInstance"
    //   },
    //   "instanceId"
    // );

    let currentUser: string = this.apiFormatter.removeQuotesFromString(localStorage.getItem('currentUser').trim());

    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/user/getUsers",
      container,
      "owner",
      null,
      "userName",
      "firstName",
      [currentUser]
    );

    return container;
  }

  getPopulatdData(objectToPopulateWith: Object, globalUrl: string): Container {
    // debugger;
    let container: Container = new Container(this.apiFormatter);
    for (let field of container.fields) {
      if (field.type != "multiSelect")
        field.value = objectToPopulateWith[0][field.jsonKey];
      if (field.showInUpdateAs) {
        field.type = field.showInUpdateAs;
        field['makeReadOnly'] = true;
      }
      if (field.jsonKey == "artifactName") {
        field['makeReadOnly'] = true;
      }
    }
    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/user/getUsers",
      container,
      "owner",
      null,
      "userName",
      "firstName",
      objectToPopulateWith[0]["owner"]
    );

    return container;
  }

  async isContainerNameDuplicate(globalUrl, name) {
    let result = await this.apiFormatter.getApiRequest(globalUrl + "/console/isContainerNameDuplicate/" + name).toPromise();
    return result;
  }
}
