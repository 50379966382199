import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TopicComponent } from "./topic/topic.component";
import { LoginComponent } from "./login/login.component";
// import { ThemesComponent } from "./topic/themes/themes.component";
import { ConsoleComponent } from "./console/console.component";
import { RegisterComponent } from "./register/register.component";
import { EditThemeComponent } from "./console/edit-theme/edit-theme.component";
import { ApprovalComponent } from "./approval/approval.component";
import { ContainerComponent } from "./container/container.component";
import { TopicListComponent } from "./console/topic-list/topic-list.component"
import { ContainerListComponent } from './console/container-list/container-list.component';
import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: "topic", component: TopicComponent },
  { path: "console", component: ConsoleComponent },
  { path: "approval", component: ApprovalComponent },
  { path: "register/:type", component: RegisterComponent },
  { path: "container/:containerId", component: ContainerComponent },
  { path: "topicList", component: TopicListComponent },
  { path: "containerList", component: ContainerListComponent },
  { path: "error", component: ErrorPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const appRoutingModule = RouterModule.forRoot(routes);
