import { Options } from "../console/custom-modal/options";
import { ApiFormatterService } from '../common/services/Api Formatter/api-formatter.service';
import { Globals } from '../config';

export class User {
  constructor(private apiFormatter: ApiFormatterService, private globals: Globals,) { }

  title = "User Registration";
  buttons = [
    {
      value: "Submit",
      url: "user/user", //localhost:8080/barista/user/user
      methodType: "PUT"
    }
  ];
  action = "INSERT";
  fields = [
    {
      type: "",
      jsonKey: "userID",
      value: null
    },
    {
      type: "heading",
      value: "User Information"
    },
    {
      label: "First Name",
      type: "text",
      jsonKey: "firstName",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "^[a-zA-Z ]*$",
      patternErrorMsg: "First Name should contain only alphabets"
    },
    {
      label: "Last Name",
      type: "text",
      jsonKey: "lastName",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "^[a-zA-Z]*$",
      patternErrorMsg: "Last Name should contain only alphabets"
    },
    {
      label: "Organization Type",
      type: "select",
      jsonKey: "usertype",
      value: "",
      options: [],
      deciderOption: true,
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      helpIcon: ""
    },{
      label: "Organization Name",
      type: "select",
      jsonKey: "organization",
      value: "",
      options: [],
      deciderOption: true,
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      helpIcon: "Select the close name to your organization"
    },
    {
      label: "Department/Division",
      type: "text",
      jsonKey: "department",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: ""
    },
    {
      label: "Country",
      type: "select",
      jsonKey: "country",
      value: "US",
      options: [],
      deciderOption: true,
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      helpIcon: ""
    },
    {
      type: "heading",
      value: "Email Information"
    },
    {
      label: "Email(user)",
      type: "text",
      jsonKey: "userName",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern:
        "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
      patternErrorMsg: "Email must be of the form abc@def.com"
    },
    {
      label: "Password",
      type: "password",
      jsonKey: "password",
      value: "",
      doNotShowInEdit: true,
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg:
        ""
    },
    {
      label: "Secret question",
      type: "select",
      jsonKey: "secretQuestion",
      options: [
        {
          name: "What is your mother's maiden name?",
          value: "What is your mother's maiden name?"
        },
        {
          name: "What is your favorite color?",
          value: "What is your favorite color?"
        }
      ],
      value: "",
      doNotShowInEdit: true,
      required: true,
      error: false,
      errorMsg: "",
      pattern: ""
    },
    {
      label: "Secret answer",
      type: "password",
      jsonKey: "secretAnswer",
      value: "",
      doNotShowInEdit: true,
      required: true,
      error: false,
      errorMsg: "",
      pattern: ""
    },
    {
      type: "heading",
      value: "Barista Information"
    },
    {
      label: "Desired Role",
      type: "select",
      options: [],
      jsonKey: "roleID",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      helpIcon: ["Select the desired role.", "• Guest user has only access to portal.", "• Presenter can access the portal and only monitor in the console.", "• Contributor can access portal and can also has full access to manage the topics in the console.", "• Admin can access both portal and console and has access to manage everything in the console."]
    },
    {
      type: "",
      jsonKey: "role",
      value: ""
    },
    {
      label: "Topics of interest",
      type: "multiSelect",
      jsonKey: "userTopic",
      value: [],
      decidedBy: ["usertype", "country"],
      selectedValues: [],
      options: [],
      settings: {
        singleSelection: false,
        idField: "value",
        textField: "name",
        selectAllText: "Select All",
        unSelectAllText: "Unselect All",
        itemsShowLimit: 3,
        allowSearchFilter: true,
        allSelectedPlaceholder: "All"
      },
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      helpIcon: "Select the topics of your interest! The system will filter demos based on your topics of interest."
    },
    {
      type: "",
      jsonKey: "status",
      value: "Registered"
    },
    {
      showInApproveAs: "text",
      label: "Country Check",
      type: "",
      decidedBy: ["country"],
      jsonKey: "countryConfidentiality",
      value: "OK",
      required: false,
      makeReadOnly: true,
      error: false,
      errorMsg: "",
      pattern: "",
      showIcon: true
    },
    {
      showInApproveAs: "text",
      label: "Confidentiality Check",
      type: "",
      decidedBy: ["usertype"],
      jsonKey: "confidentialDemosExposed",
      value: "OK",
      required: false,
      makeReadOnly: true,
      error: false,
      errorMsg: "",
      pattern: "",
      showIcon: true
    },
  ];

  getData(globalUrl): User {


    // console.log(countriesData["default"]);

    let user: User = new User(this.apiFormatter, this.globals);

    // new Options(this.apiFormatter).setOptionsFromApi(
    //   globalUrl + "/getTopics",
    //   user,
    //   "userTopic",
    //   null,
    //   "topicid",
    //   "topicname",
    //   ["all"]
    // );

    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/user/getRoles",
      user,
      "roleID",
      null,
      "roleID",
      "rolename",
      [3]
    );


    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/user/getOrganizations",
      user,
      "organization",
      null,
      "name",
      "name",
      []
    );

    new Options(this.apiFormatter).setOptionsFromApi(
      this.globals.filesServerUrl + "/getJson/resources/countries.json",
      user,
      "country",
      null,
      "value",
      "name",
      []
    );

    new Options(this.apiFormatter).setOptionsFromApi(
      this.globals.filesServerUrl + "/getJson/resources/hitachiUserTypes.json",
      user,
      "usertype",
      null,
      "value",
      "name",
      []
    );

    return user;
  }

  getUrlForUserTopicsByTypeAndCountry(globalUrl, userType = "", country = "", filters = "both") {

    let index = this.fields.findIndex(field => field.jsonKey == "userTopic");
    if (!this.fields[index].decidedBy || !this.fields[index].decidedBy.length) return;
    //  console.log("fields", this.fields);
    if (this.title === "User Registration") {
      this.fields[index].options = [];
      this.fields[index].selectedValues = [];
      this.fields[index].value = [];
    }
    if (!userType || !country) {

      let deciderValues = {};

      this.fields.forEach(field => {
        if (this.fields[index].decidedBy.includes(field.jsonKey)) {
          deciderValues[field.jsonKey] = field.value;
        }
      });

      for (let key in deciderValues) {
        if (!deciderValues[key] || !deciderValues[key].length) return;
      }

      //console.log(deciderValues);

      userType = deciderValues[this.fields[index].decidedBy[0]];
      country = deciderValues[this.fields[index].decidedBy[1]];
    }
    if (filters === "both")
      return globalUrl + "getTopics/" + userType + "/" + country;
    if (filters === "user-type")
      return globalUrl + "getTopicsByConfidentiality/" + userType;
    if (filters === "country")
      return globalUrl + "getTopicsByCountry/" + country;

  }

  updateUserTopics(globalUrl, userType = "", country = "", alreadySelectedOptionsValues = []) {
    let url = this.getUrlForUserTopicsByTypeAndCountry(globalUrl, userType, country);

    if (!alreadySelectedOptionsValues.length) alreadySelectedOptionsValues = ["all"];
    if (alreadySelectedOptionsValues && this.title === "User Registration") alreadySelectedOptionsValues = ["all"];
    //console.log("req",  url)

    new Options(this.apiFormatter).setOptionsFromApi(
      url,
      this,
      "userTopic",
      null,
      "topicid",
      "topicname",
      alreadySelectedOptionsValues
    );
  }




  async checkTopicsConfidentiality(globalUrl: string, topicsData = undefined, selectedOptionsValues = undefined) {
    let topicsforUserType = undefined, topicsforCountry = undefined;
    let topicField = undefined;
    let index = this.fields.findIndex(field => field.jsonKey == "userTopic");
    topicField = this.fields[index];
    // if(this.title==="Approve User" || this.title==="Edit User"){
    let url = this.getUrlForUserTopicsByTypeAndCountry(globalUrl, "", "", "user-type");
    topicsforUserType = await this.apiFormatter.getApiRequest(url).toPromise();
    url = this.getUrlForUserTopicsByTypeAndCountry(globalUrl, "", "", "country");
    topicsforCountry = await this.apiFormatter.getApiRequest(url).toPromise();
    // }
    if (!topicsData) {
      let url = this.getUrlForUserTopicsByTypeAndCountry(globalUrl);
      topicsData = await this.apiFormatter.getApiRequest(url).toPromise();
      topicField["allowedTopics"] = topicsData;
    }

    let notAllowedTopicsForUserType: any[] = [], notAllowedTopicsForCountry: any[] = [];

    let topicNamesAvailable: boolean = false;

    let promise = new Promise((resolve, reject) => {
      let count = 0;
      let id = setInterval(() => {
        count++;
        if (topicField.options && topicField.options.length) {
          resolve(true);
          clearInterval(id);
        }
        if (count > 10) {
          resolve(false);
          clearInterval(id);
        }
      }, 100);
    });

    let optionsAvailable = await promise;

    if (!selectedOptionsValues || optionsAvailable) {
      topicNamesAvailable = true;
      selectedOptionsValues = []
      if (topicField.selectedValues.length) {
        topicField.selectedValues.forEach(option => selectedOptionsValues.push({ name: option.name, value: option.value }));
      }
    } else {
      let values = [];
      selectedOptionsValues.forEach(value => values.push({ name: '', value: value }));
      selectedOptionsValues = values;
    }

    if (topicsforUserType instanceof Array) {
      selectedOptionsValues.forEach(option => {
        let ind = topicsforUserType.findIndex(topic => topic.topicid == option.value);
        if (ind < 0) {
          notAllowedTopicsForUserType.push(option.name);
        }
      });
    }
    if (topicsforCountry instanceof Array) {
      selectedOptionsValues.forEach(option => {
        let ind = topicsforCountry.findIndex(topic => topic.topicid == option.value);
        if (ind < 0) {
          notAllowedTopicsForCountry.push(option.name);
        }
      });
    }


    let countryConfIndex = this.fields.findIndex(field => field.jsonKey == "countryConfidentiality");
    if (notAllowedTopicsForCountry.length) {
      if (!topicNamesAvailable) this.fields[countryConfIndex].value = "Not OK";
      else this.fields[countryConfIndex].value = "Not OK for " + notAllowedTopicsForCountry.join();
    } else this.fields[countryConfIndex].value = "OK";

    let confIndex = this.fields.findIndex(field => field.jsonKey == "confidentialDemosExposed");
    if (notAllowedTopicsForUserType.length) {
      if (!topicNamesAvailable) this.fields[confIndex].value = "Not OK";
      else this.fields[confIndex].value = "Not OK for " + notAllowedTopicsForUserType.join();
    } else this.fields[confIndex].value = "OK";

    this.fields[countryConfIndex].showIcon = (this.fields[countryConfIndex].value === "OK");
    this.fields[confIndex].showIcon = (this.fields[confIndex].value === "OK");

  }

  getPopulatdData(objectToPopulateWith: Object, globalUrl: string): User {
    // debugger;
    let user: User = new User(this.apiFormatter, this.globals);

    user.title = "Update User";
    user.buttons = [
      {
        value: "Update",
        url: "user/editUser",
        methodType: "PUT"
      }
    ];

    for (let field of user.fields) {
      if (field.doNotShowInEdit) field.type = "";
      if (field.showInApproveAs) field.type = field.showInApproveAs;
      if (field.type == "multiSelect") continue;
      if (objectToPopulateWith[field.jsonKey]) field.value = objectToPopulateWith[field.jsonKey];
      if (field.jsonKey == "userName") field["makeReadOnly"] = true;
    }

    let alreadySelectedOptionsValues: any[] = [];

    if (objectToPopulateWith["userTopic"])
      for (let obj of objectToPopulateWith["userTopic"]) {
        alreadySelectedOptionsValues.push(obj.topicID);
      }

    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/getTopics",
      user,
      "userTopic",
      null,
      "topicid",
      "topicname",
      alreadySelectedOptionsValues
    );


    // user.updateUserTopics(globalUrl, objectToPopulateWith["usertype"], objectToPopulateWith["country"], alreadySelectedOptionsValues);

    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/user/getRoles",
      user,
      "roleID",
      null,
      "roleID",
      "rolename",
      [objectToPopulateWith["roleID"]]
    );

   new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/user/getOrganizations",
      user,
      "organization",
      null,
      "name",
      "name",
      []
    );

    new Options(this.apiFormatter).setOptionsFromApi(
      this.globals.filesServerUrl + "/getJson/resources/countries.json",
      user,
      "country",
      null,
      "value",
      "name",
      [objectToPopulateWith["country"]]
    );

    new Options(this.apiFormatter).setOptionsFromApi(
      this.globals.filesServerUrl + "/getJson/resources/hitachiUserTypes.json",
      user,
      "usertype",
      null,
      "value",
      "name",
      [objectToPopulateWith["usertype"]]
    );
    user.checkTopicsConfidentiality(globalUrl, undefined, alreadySelectedOptionsValues);
    return user;
  }

  getUserApprovalData(objectToPopulateWith: Object, globalUrl: string): User {
    let user: User = this.getPopulatdData(objectToPopulateWith, globalUrl);

    user.title = "Approve User";
    user.buttons = [
      {
        value: "Approve",
        url: "user/editUser",
        methodType: "PUT"
      }
    ];

    // let fieldsToShow = ["userTopic", "roleID"];
    return user;
  }

  handleMultiselectChange(globalUrl, field) {
    if (field.jsonKey == "userTopic")
      this.checkTopicsConfidentiality(globalUrl, field["allowedTopics"]);
  }

  editData(globalUrl, approvalRef) {
    this.apiFormatter
      .putApiRequest(globalUrl + this.buttons[0].url, this.fields)
      .subscribe(
        data => {
          // console.log(data);
          approvalRef.modalService.dismissAll();
          approvalRef.loadUsers();
        },
        error => {
          console.error(error);
          approvalRef.modalService.dismissAll();
        }
      );
  }

  postData(globalUrl, registerRef) {
    registerRef.submitting = true;
    // debugger;
    this.apiFormatter
      .putApiRequest(globalUrl + this.buttons[0].url, this.fields)
      .subscribe(data => {
        //console.log(data);
        registerRef.submitting = false;
        registerRef.showMsgs = true;
        registerRef.title = "User registration confirmation";
        registerRef.msgs = [
          "Thanks for registering to BaristaOnClound.",
          "You will receive a confirmation once your role is approved."
        ];
      });
  }
}
