import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Globals } from "../config";
import { ApiFormatterService } from "../common/services/Api Formatter/api-formatter.service"

@Component({
  selector: 'app-side-content',
  templateUrl: './side-content.component.html',
  styleUrls: ['./side-content.component.css']
})

export class SideContentComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @Input() username: string = '';
  @Input() selectedCategory: string = 'All';

  constructor(private globals: Globals,
    private apiFormatter: ApiFormatterService
  ) { }
  industries: any;
  ngOnInit() {
    if (this.username != '') {
      this.apiFormatter.getApiRequest(this.globals.filesServerUrl + "/getJson/resources/industries.json").subscribe(res => {
        let tempIndustries = res;
        tempIndustries.splice(0, 0, { "name": "All", "value": "All" });
        this.industries = tempIndustries.sort()
      });
    }
  }
  valueChanged(item: string) {
    // console.log("Selected Item=" + item);
    this.selectedCategory = item;
    this.valueChange.emit(item);
  }
}
