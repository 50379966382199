import { ApiFormatterService } from "src/app/common/services/Api Formatter/api-formatter.service";
import { FileUploadService } from "../services/fileUploadService/file-upload.service";
import { Options } from '../custom-modal/options';

export class Solution {
  constructor(
    private apiFormatter: ApiFormatterService,
    private uploadFilesService: FileUploadService
  ) {}

  title = "New";
  buttons = [
    {
      value: "Save",
      url: "/console/addTabContent", ///console/solution
      methodType: "PUT"
    }
  ];
  action = "INSERT";
  fields = [
    {
      type: "text",
      jsonKey: "themename",
      label: "Name",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "textarea",
      jsonKey: "themedesc",
      label: "Description",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "text",
      jsonKey: "displayorder",
      label: "Display Order",
      value: "",
      required: false,
      error: false,
      errorMsg: "",
      pattern: "^[0-9]*$",
      patternErrorMsg: "Requires a numeric value",
      helpIcon: "Indicates the order to be displayed in the the list of themes",
    },
    {
      type: "fileWithDropdown",
      jsonKey: "logo",
      label: "Logo",
      uploading: false,
      prefix: "logo",
      value: "",
      placeholder: "Choose or Upload a *.jpg/*.png file",
      fileType: "img",
      options: [
        { optionFilePath: "activetime_prediction.svg", optionLabel: "activetime_prediction.svg" },
        { optionFilePath: "ComponentPerformanceMonitoring.svg", optionLabel: "ComponentPerformanceMonitoring.svg" },
        { optionFilePath: "demand_forecasting.svg", optionLabel: "demand_forecasting.svg" },
        { optionFilePath: "DriverPerformanceMonitoring.svg", optionLabel: "DriverPerformanceMonitoring.svg" },
        { optionFilePath: "dynamic_dispatching.svg", optionLabel: "dynamic_dispatching.svg" },
        { optionFilePath: "evntfailure.svg", optionLabel: "evntfailure.svg" },
        { optionFilePath: "factorIDF.svg", optionLabel: "factorIDF.svg" },
        { optionFilePath: "kpi_prediction.svg", optionLabel: "kpi_prediction.svg" },
        { optionFilePath: "manufacturing_blue.svg", optionLabel: "manufacturing_blue.svg" },
        { optionFilePath: "modelPrediction.svg", optionLabel: "modelPrediction.svg" },
        { optionFilePath: "operating_envelop.svg", optionLabel: "operating_envelop.svg" },
        { optionFilePath: "operator_profiling.svg", optionLabel: "operator_profiling.svg" },
        { optionFilePath: "oprEnvelop.svg", optionLabel: "oprEnvelop.svg" },
        { optionFilePath: "production_monitorning.svg", optionLabel: "production_monitorning.svg" },
        { optionFilePath: "repairRecommend.svg", optionLabel: "repairRecommend.svg" },
        { optionFilePath: "rul.svg", optionLabel: "rul.svg" },
        { optionFilePath: "scheduling.svg", optionLabel: "scheduling.svg" },
        { optionFilePath: "sensorprediction.svg", optionLabel: "sensorprediction.svg" },
        { optionFilePath: "VehiclePerformanceMonitoring.svg", optionLabel: "VehiclePerformanceMonitoring.svg" }
      ],
      showOptions: false,
      fileObj: "",
      required: false,
      error: false,
      errorMsg: "",
      pattern: ".(gif|jpg|jpeg|tiff|png|svg)$",
      patternErrorMsg: "Upload a valid file",
      jsonObject: {
        logoid: "",
        logoname: "",
        logotype: "blue"
      }
      // type: "text",
      // jsonKey: "logo",
      // label: "Logo",
      // value: "Logo",
      // required: true,
      // error: false,
      // errorMsg: "",
      // pattern: "",
      // patternErrorMsg: ""
    },
    {
      type: "",
      jsonKey: "author",
      label: "",
      value: ""
    },
    {
      type: "",
      jsonKey: "tabid",
      label: "",
      value: ""
    },
    {
      type: "",
      jsonKey: "topicid",
      label: "",
      value: ""
    },
    {
      type: "",
      jsonKey: "themeid",
      label: "",
      value: ""
    },
    {
      type: "",
      jsonKey: "contenttype",
      label: "",
      value: "text"
    },

    {
      type: "many",
      heading: "Resources",
      jsonKey: "resources",
      value: "",
      fields: [
        {
          type: "textWithOptions",
          jsonKey: "url",
          label: "URL",
          value: "",
          showOptions: false,
          options: [],
          required: false,
          error: false,
          errorMsg: "",
          pattern: "^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*(:[0-9]{1,5})?(\/.*)?$",
          patternErrorMsg: "Enter a valid URL",
          jsonObject: {
            resourcename: "",
            resourcedesc: "",
            resourcetype: "URL",
            themeid: "",
            resourceid: ""
          }
        },
        {
          type: "file",
          uploading: false,
          jsonKey: "pdf",
          label: "Pdf",
          prefix: "pdf",
          value: "",
          fileType: "PDF",
          fileObj: "",
          required: false,
          error: false,
          errorMsg: "",
          pattern: ".(pdf|PDF)$",
          patternErrorMsg: "Upload a valid pdf file",
          accept: ".pdf",
          placeholder: 'Choose *.pdf file',
          jsonObject: {
            resourcename: "",
            resourcedesc: "",
            resourcetype: "PDF",
            themeid: "",
            resourceid: ""
          }
        },
        {
          type: "file",
          uploading: false,
          jsonKey: "audio",
          label: "Text-to-Speech",
          prefix: "audio",
          value: "",
          fileType: "AUDIO",
          fileObj: "",
          required: false,
          error: false,
          errorMsg: "",
          pattern: ".(txt|TXT)$",
          patternErrorMsg: "Upload a valid text file",
          accept: ".txt, text/plain",
          placeholder: 'Choose *.txt file',
          jsonObject: {
            resourcename: "",
            resourcedesc: "",
            resourcetype: "AUDIO",
            themeid: "",
            resourceid: ""
          },
          helpIcon: "Requires a text file which will automatically converted into audio file. (Max word limit : 500 Words)"
        },
        {
          type: "file",
          uploading: false,
          jsonKey: "video",
          label: "Video",
          prefix: "video",
          value: "",
          fileType: "Video",
          fileObj: "",
          required: false,
          error: false,
          errorMsg: "",
          pattern: ".(mp4|3gp|wmv|webm|avi|mkv|flv|MP4|3GP|WMV|WEBM|AVI|MKV|FLV)$",
          patternErrorMsg: "Upload a valid video file",
          accept: "video/mp4, video/x-m4v, video/*",
          placeholder: 'Choose *.mp4 file',
          jsonObject: {
            resourcename: "",
            resourcedesc: "",
            resourcetype: "VIDEO",
            themeid: "",
            resourceid: ""
          }
        }
      ]
    }
  ];

  async uploadFiles(themeId) {
    let formData = new FormData();
    let logoIndex = this.fields.findIndex(field => field.jsonKey == "logo");
    // debugger;
    if (logoIndex >= 0 && this.fields[logoIndex].value.length && this.fields[logoIndex].fileObj) {
      formData.append(
        "uploads[]",
        this.fields[logoIndex].fileObj,
        this.fields[logoIndex].prefix + "_" + this.fields[logoIndex].fileObj["name"]
      );
    } else {
      //  console.log("Helllllllllllllllllllllllllllllllllooooooooooo");
      formData.append("logo", "logo");
    }
    for (let field of this.fields) {
      if (field.jsonKey === "resources") {
        for (let file of field.fields) {
          if (file.fileObj) {
            formData.append("uploads[]", file.fileObj, file.prefix + "_" + file.fileObj["name"]);
          }
        }
        break;
      }
    }
    formData.append("name", "Theme_" + themeId);
    // formData.forEach((value, key) => {
    //   console.log(key + " " + value);
    // });
    let data1 = "";
    return this.uploadFilesService.postFiles(formData).toPromise();
    // console.log(data1);
  }

  moveFilesToUploadsAndDeleteTemp(themeid){

    let fileNames: string[] = [];
    let fileIndex = this.fields.findIndex(field => field.jsonKey == "logo");
    if (fileIndex >= 0 && this.fields[fileIndex].value.length && this.fields[fileIndex].fileObj) {
      fileNames.push(this.fields[fileIndex].value);
    }
    fileIndex = this.fields.findIndex(field => field.jsonKey == "resources");
    for(let field of this.fields[fileIndex].fields) {
      if(field.value && field.fileObj) fileNames.push(field.value);
    }
    let currentUser = this.apiFormatter.removeQuotesFromString(localStorage.getItem('currentUser').trim());
    let type = "Theme";
    

    // let formData = new FormData();
    let objectToSend = {
      files: fileNames,
      userName: currentUser,
      type: type,
      id: themeid
    }
    // console.log(objectToSend);

    this.uploadFilesService.moveFilesFromTempToUploads(objectToSend).subscribe(data => {
      // console.log(data);
      this.uploadFilesService.deleteFilesFromTemp(objectToSend).subscribe(data => {
        // console.log(data);
      });
    });



  }

  postData(globalUrl, modal): void {
    //  debugger;
    this.apiFormatter
      .putApiRequest(globalUrl + this.buttons[0].url, this.fields)
      .subscribe(data => {
        
        // console.log(data);
        //moveFilesToMainDirectory(currentUser, datafiles)
        // let filesToDelete
        
        this.moveFilesToUploadsAndDeleteTemp(data["themeid"]);
        // this.uploadFiles(data["themeid"]).then(() => {
        //   // console.log(data);
        //   return true;
        // });
        // modal.close("Saved");
        modal.editThemeRef.loadthemes(modal.editThemeRef.selectedTopic);
      });
  }

  getData(globalUrl, topicId): Solution {
    let solution: Solution = new Solution(
      this.apiFormatter,
      this.uploadFilesService
    );
    solution.setOptionsForUrl(globalUrl, topicId);
    return solution;
  }

  findSubFieldAndAssign(
    innerFields: any[],
    arrayToPopulateWith: any[],
    themeid?: string
  ) {
    // console.log(innerFields);
    // console.log(arrayToPopulateWith);
    innerFields.map(innerField => {
      innerField.jsonObject.themeid = themeid;
      let index: number = arrayToPopulateWith.findIndex(object => {
        if (
          innerField.jsonObject.resourcetype.trim() ==
          object.resourcetype.trim()
        ) {
          innerField.jsonObject = object;
          return true;
        }
      });
      if (index >= 0)
        innerField.value = arrayToPopulateWith[index]["resourcename"];
    });
  }

  setOptionsForUrl(globalUrl, topicid) {
    for(let field of this.fields) {
      if(field.type == "many") {
        let urlIndex: number = field.fields.findIndex(subField => subField.jsonKey == "url");
        if(urlIndex >= 0) {
          this.apiFormatter.getApiRequest(globalUrl + "/console/getTopicInstancesUrl/" + topicid).subscribe(data => {
            // console.log(data)
            if(data instanceof Array) {
              data.forEach(url => field.fields[urlIndex].options.push({name: url, value: url}));
            }
          });
        }
      }
    }
  }

  getPopulatdData(objectToPopulateWith: Object, globalUrl: string, topicId): Solution {
    // debugger;
    let solution: Solution = new Solution(
      this.apiFormatter,
      this.uploadFilesService
    );

    solution.title = "Update";
    solution.buttons = [
      {
        value: "Update",
        url: "/console/editTheme",
        methodType: "PUT"
      }
    ];
    let themeid = "";
    for (let field of solution.fields) {
      if (field.jsonKey === "themeid")
        themeid = objectToPopulateWith[field.jsonKey];
      if (field.type == "many") {
        this.findSubFieldAndAssign(
          field.fields,
          objectToPopulateWith["resources"],
          themeid
        );
      } else {
        field.value = objectToPopulateWith[field.jsonKey];
      }
    }
    
    solution.setOptionsForUrl(globalUrl, topicId);

    return solution;
  }
}
