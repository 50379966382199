import { ApiFormatterService } from "src/app/common/services/Api Formatter/api-formatter.service";
import { FileDetector } from "protractor";
import { Globals } from 'src/app/config';
import _ from 'lodash';

export class Options {
  constructor(private apiFormatterService: ApiFormatterService) { }

  async appendOptionsAndSetAsValues(
    url: string,
    optionsToAddTo: any[],
    fieldWhoseValueToBeSet: any,
    itemToPopulate: any,
    objectFieldToPopulateOptionValue: any,
    objectFieldToPopulateOptionName: any = "name",
    doNotAppendInOptions: boolean = true
  ) {
    class Option {
      name = "";
      value = "";
    }

    let options: any[] = [];

    let data = await this.apiFormatterService.getApiRequest(url).toPromise();

    //console.log(data);

    let index: any = itemToPopulate.fields.findIndex(field => {
      return field.jsonKey == fieldWhoseValueToBeSet;
    });

    for (let obj of data) {
      var option = new Option();
      option.value = obj[objectFieldToPopulateOptionValue];
      option.name = obj[objectFieldToPopulateOptionName];
      options.push(option);
    }

    itemToPopulate.fields[index].value = options;
    this.apiFormatterService.copyMultiselectValuesToSelectedValues(itemToPopulate.fields[index]);

    if (!doNotAppendInOptions)
      optionsToAddTo.push(...options);
    // console.log(optionsToAddTo);
  }

  setOptionsFromApi(
    url: string,
    itemToPopulate: any,
    itemFieldToPopulate: any,
    newOption: any,
    objectFieldToPopulateOptionValue: any,
    objectFieldToPopulateOptionName: any = "name",
    alreadySelectedOptionsValues: any[] = [],
    additionalOptionsObject = null
  ): any {
    class Option {
      name = "";
      value = "";
    }

    let options = [];
    // console.log(url);
    this.apiFormatterService.getApiRequest(url).subscribe(async data => {
      //  console.log(data);

      if (additionalOptionsObject && additionalOptionsObject["url"]) {
        await this.appendOptionsAndSetAsValues(
          additionalOptionsObject.url,
          options,
          itemFieldToPopulate,
          itemToPopulate,
          additionalOptionsObject.objectFieldToPopulateOptionValue ? additionalOptionsObject.objectFieldToPopulateOptionValue : objectFieldToPopulateOptionValue,
          additionalOptionsObject.objectFieldToPopulateOptionName ? additionalOptionsObject.objectFieldToPopulateOptionName : objectFieldToPopulateOptionName,
          additionalOptionsObject["doNotAppendInOptions"]
        );
      }

      // console.log(options);
      let index: any = itemToPopulate.fields.findIndex(field => {
        return field.jsonKey == itemFieldToPopulate;
      });

      if (!(itemToPopulate.fields[index].value instanceof Array) && itemToPopulate.fields[index].type == "multiSelect")
        itemToPopulate.fields[index].value = [];
      if (!(itemToPopulate.fields[index].options instanceof Array))
        itemToPopulate.fields[index].options = [];

      // if (setCompleteValueAsAlreadySelectedOption) {
      //   options.push(...setCompleteValueAsAlreadySelectedOption);
      //   itemToPopulate.fields[
      //     index
      //   ].value = setCompleteValueAsAlreadySelectedOption;
      // }
      // console.log(alreadySelectedOptionsValues);
      for (let obj of data) {
        var option = new Option();
        option.value = obj[objectFieldToPopulateOptionValue];
        option.name = obj[objectFieldToPopulateOptionName];
        options.push(option);
      }

      if (newOption) {
        options.push(newOption);
      }

      // TODO:Delete this implementation if the server returning unique values
      if (itemToPopulate.fields[index].jsonKey === "contact") {
        options = _.uniqBy(options, function (e) {
          return e.name;
        });
      }

      if (itemToPopulate.fields[index].jsonKey === "owner") {
        options = _.uniqBy(options, function (e) {
          return e.name;
        });
      }

      itemToPopulate.fields[index].options.push(...options);
      itemToPopulate.fields[index]["dataFromApi"] = data;
      if (alreadySelectedOptionsValues.length > 0) {
        // console.log(itemToPopulate.fields[index].type);
        if (itemToPopulate.fields[index].type == "multiSelect") {
          if (alreadySelectedOptionsValues[0] == "all") {
            itemToPopulate.fields[index].value.push(...options);
          } else {
            let arrayToSetAsValue: any[] = [];
            alreadySelectedOptionsValues.map(optionValue => {
              let index: number = options.findIndex(
                option => option.value == optionValue
              );
              if (index >= 0) arrayToSetAsValue.push(options[index]);
            });
            itemToPopulate.fields[index].value.push(...arrayToSetAsValue);
          }
          //Copying values to selected values Array
          //We will use selectedValues now to store selected values
          this.apiFormatterService.copyMultiselectValuesToSelectedValues(itemToPopulate.fields[index]);
        } else if (itemToPopulate.fields[index].type == "select") {
          alreadySelectedOptionsValues.map(optionValue => {
            let optionIndex: number = options.findIndex(
              option => option.value == optionValue
            );
            if (index >= 0 && optionIndex >= 0)
              itemToPopulate.fields[index].value = options[
                optionIndex
              ].value.toString();
          });
        }
      }
    });
  }
}
