import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';

@Injectable({
  providedIn: 'root'
})
export class AwsS3Service {

  defaultBucketName = 'barista-container';

  constructor() { }

  awsConfig: any = {
    accessKeyId: 'AKIA26HQ4S6BPWXYWQCM',
    secretAccessKey: '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',
    region: 'us-west-1',
    apiVersion: '2006-03-01'
  }

  getBucketName() {
    return this.defaultBucketName;
  }

  async uploadFileToBucket(file: File, directoryPath = "default_directory", timestamp, item, bucketName = this.defaultBucketName) {

    this.defaultBucketName = bucketName;

    // console.log(timestamp);

    let res: Object = null;

    let s3: AWS.S3 = new AWS.S3(this.awsConfig);

    directoryPath = directoryPath.split(" ").join("_");

    let uploadParams = {
      Bucket: bucketName,
      Key: directoryPath + "/" + (timestamp? timestamp : new Date().getTime()) + "/" + file.name,
      Body: file,
      ContentType: file.type
    }

    // res = await s3.upload(uploadParams).promise();
    // console.log('uploading');
    res = await s3.upload(uploadParams).on('httpUploadProgress', (progress) => {
      if(item) {
        item.progress.loaded = progress.loaded;
        item.progress.total = progress.total;
      }
     // console.log(progress);
    }).promise();
    // console.log(res);
    return res;
  }

  async retrieveFileFromBucket(fileLocation: string, bucketName = 'container-files') {
    let res = null;
    let ind: number = fileLocation.search(bucketName);
    if(ind < 0) return res;
    fileLocation = fileLocation.substring(ind);
    let fileLocationParams: string[] = fileLocation.split(".");
    if(fileLocationParams[1] != 's3' || fileLocationParams[2] != this.awsConfig.region || fileLocation.includes('/')) return res;
    let fileName: string = fileLocation.split('/')[1];

    let fileRetrieveParams = {
      Bucket: bucketName,
      Key: fileName
    }

    let s3: AWS.S3 = new AWS.S3(this.awsConfig);
    res = await s3.getObject(fileRetrieveParams).promise();
    // console.log(res);
    return res;

  }

}
