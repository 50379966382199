import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "./services/data.service";
import { User } from "./user";
import { ApiFormatterService } from "../common/services/Api Formatter/api-formatter.service";
import { Globals } from "../config";

enum keysOfRegisterFormElements {
  email = 0,
  password,
  firstName,
  lastName,
  department,
  role,
  topics,
  secretQuestion,
  secretAnswer,
  organization2,
}

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {
  @ViewChild("multiple") multipleSelectDropDown;
  formData: any;
  formElements = [];
  submitting: boolean = false;
  resetPasswordFormElements = {
    title: "Reset Password!",
    fields: [
      {
        label: "Email(user)",
        type: "email",
        jsonKey: "userName",
        value: "",
        required: true,
        error: false,
        errorMsg: "",
        pattern:
          "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
        patternErrorMsg: "Email must be of the form abc@def.com"
      },
      {
        label: "Secret question",
        type: "select",
        options: [
          {
            name: "What is your mother's maiden name?",
            value: "What is your mother's maiden name?"
          },
          {
            name: "What is your favorite color?",
            value: "What is your favorite color?"
          }
        ],

        jsonKey: "secretQuestion",
        value: "",
        required: true,
        error: false,
        errorMsg: "",
        pattern: ""
      },
      {
        label: "Secret answer",
        type: "password",
        jsonKey: "secretAnswer",
        value: "",
        required: true,
        error: false,
        errorMsg: "",
        pattern: ""
      },
      {
        label: "New Password",
        type: "password",
        jsonKey: "password",
        value: "",
        required: true,
        error: false,
        errorMsg: "",
        pattern: "",
        patternErrorMsg:
          ""
      },
      {
        type: "",
        jsonKey: "userID",
        value: null
      },

      {
        label: "First Name",
        type: "",
        jsonKey: "firstName",
        value: "",
        required: false,
        error: false,
        errorMsg: "",
        pattern: "^[a-zA-Z ]*$",
        patternErrorMsg: "First Name should contain only alphabets"
      },
      {
        label: "Last Name",
        type: "",
        jsonKey: "lastName",
        value: "",
        required: false,
        error: false,
        errorMsg: "",
        pattern: "^[a-zA-Z]*$",
        patternErrorMsg: "Last Name should contain only alphabets"
      },
      {
        label: "Department/Org",
        type: "",
        jsonKey: "department",
        value: "",
        required: false,
        error: false,
        errorMsg: "",
        pattern: ""
      },
      {
        label: "Desire Role",
        type: "",
        options: [],
        selectedValues: [],
        jsonKey: "roleID",
        value: "",
        required: false,
        error: false,
        errorMsg: "",
        pattern: "",
        helpIcon: "Select the desired role. Guest user has only access to portal. Presenter or admin can access Portal and Console."
      },
      {
        type: "",
        jsonKey: "role",
        value: null
      },
      {
        label: "Topics of interest",
        type: "",
        options: [],
        selectedValues: [],
        settings: {
          singleSelection: false,
          idField: "value",
          textField: "name",
          selectAllText: "Select All",
          unSelectAllText: "Unselect All",
          itemsShowLimit: 3,
          allowSearchFilter: true,
          allSelectedPlaceholder: "All"
        },
        jsonKey: "userTopic",
        value: [],
        required: false,
        error: false,
        errorMsg: "",
        pattern: "",
        helpIcon: "Select the topics of your interest! The system will filter demos based on your topics of interest."
      },

      {
        type: "",
        jsonKey: "status",
        value: "Registered"
      }
    ]
  };
  jsonObject = {};


  tncAgreed = false;
  showTncErrorMsg = false;

  showMsgs = false;
  title = "";
  msgs = [
    "Thanks for registering to BaristaOnClound.",
    "You will receive a confirmation once your role is approved."
  ];
  type = "";
  usersList = [];
  errorMsg = "";
  user: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private apiFormatter: ApiFormatterService,
    private globals: Globals
  ) { }

  async loadUsers() {
    // this.loading = true;
    this.usersList = await this.dataService
      .getData("user/getUsers")
      .toPromise();
  }

  onTnCChange() {
    this.showTncErrorMsg = false;
  }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      this.type = params["type"];
      if (this.type === "reg") {
        //  debugger;
        this.formData = new User(this.apiFormatter, this.globals).getData(
          this.globals.backendServicesUrl
        );
        await this.loadUsers();
        // console.log(this.usersList);
        // console.log(this.formData);
        // this.formElements = this.registerFormElements;
        // this.title = "User Registration";
        // this.dataService.getData("/getTopics").subscribe(data => {
        //   console.log(data);
        //   data.map(topic => {
        //     this.registerFormElements[
        //       keysOfRegisterFormElements["topics"]
        //     ].options.push(topic.topicname);
        //     this.registerFormElements[
        //       keysOfRegisterFormElements["topics"]
        //     ].optionValues.push(topic.topicid);
        //   });
        //   this.multipleSelectDropDown.data = this.registerFormElements[
        //     keysOfRegisterFormElements["topics"]
        //   ].options;
        // });
        // this.dataService.getData("user/getRoles").subscribe(data => {
        //   console.log(data);
        //   data.map(role => {
        //     this.registerFormElements[
        //       keysOfRegisterFormElements["role"]
        //     ].options.push(role.name);
        //     this.registerFormElements[
        //       keysOfRegisterFormElements["role"]
        //     ].optionValues.push(role.roleID);
        //   });
        // });
      } else {
        this.title = "Reset Password";
        this.formData = this.resetPasswordFormElements;
      }
    });
  }
  gotoLogin() {
    this.router.navigateByUrl("login");
  }

  getFieldIndexByJsonKey(fields: any[], jsonKey: string): number {
    return fields.findIndex(field => field.jsonKey == jsonKey);
  }

  findFieldAndAssign(fields: any[], arrayToPopulateWith: any[]) {
    fields.map(field => {
      let index: number = arrayToPopulateWith.findIndex(object => {
        if (field.jsonKey == object.jsonKey) return true;
      });
      if (index >= 0) field.value = arrayToPopulateWith[index].value;
    });
  }

  setDynamicDataForUser(arrayToAdd: any[], objectToAddTo: any) {
    let arrayToPopulateWith: any[] = [];
    arrayToAdd.map(object => arrayToPopulateWith.push(object));
    let index = this.getFieldIndexByJsonKey(objectToAddTo.fields, "roleID");
    if (index >= 0 && objectToAddTo.fields[index]["dataFromApi"]) {
      let roleIndex = objectToAddTo.fields[index]["dataFromApi"].findIndex(
        role => role.roleID == objectToAddTo.fields[index].value
      );
      arrayToPopulateWith.push({
        jsonKey: "role",
        value: objectToAddTo.fields[index]["dataFromApi"][roleIndex]
      });
    }
    index = this.getFieldIndexByJsonKey(objectToAddTo.fields, "userTopic");
    if (index >= 0) {
      let arrayToSetAsValue: any[] = [];
      objectToAddTo.fields[index].selectedValues.map(value => {
        arrayToSetAsValue.push({
          userID: null,
          topicID: value.value
        });
      });
      arrayToPopulateWith.push({
        jsonKey: "userTopic",
        value: arrayToSetAsValue
      });
    }
    this.findFieldAndAssign(objectToAddTo.fields, arrayToPopulateWith);
  }

  convertIntoJson() {
    this.formElements.map(element => {
      if (element.value instanceof Array) {
        if (
          element.value.length ===
          this.formElements[keysOfRegisterFormElements["topics"]]["options"]
            .length
        ) {
          this.jsonObject[element.key] = "all";
        } else this.jsonObject[element.key] = element.value.join(",");
      } else {
        this.jsonObject[element.key] = element.value.trim();
      }
    });
  }

  doesUserNameAlreadyExists(userName): boolean {
    for (let i = 0; i < this.usersList.length; i++) {
      if (this.usersList[i]["userName"].trim() === userName) {
        return true;
      }
    }
    return false;
  }

  validateForm() {
    let submit = true;
    // debugger;
    this.formData.fields.map(element => {
      if (element.required) {
        if (element.value instanceof Array === false) {
          element.value = element.value.trim();
        }
        if (element.value.length <= 0) {
          submit = false;
          element.error = true;
          element.errorMsg = "Required";
        } else {
          let regex = new RegExp(element.pattern);
          if (element.pattern.length && typeof element.value === "string") {
            if (!regex.test(element.value)) {
              element.error = true;
              element.errorMsg = element.patternErrorMsg;
              submit = false;
            } else {
              element.error = false;
            }
          }
        }
        if (element.jsonKey == "userName" && this.type === "reg") {
          if (this.doesUserNameAlreadyExists(element.value.trim())) {
            element.error = true;
            element.errorMsg = "Email already exists !";
            // console.log("Email already exists!");
            submit = false;
          }
        }
      }
    });
    if (this.tncAgreed && submit) {
      this.gotoMsgDisplay();
    }
    // console.log(this.tncAgreed)
    if (!this.tncAgreed) {
      this.showTncErrorMsg = true;
      // console.log(this.showTncErrorMsg);
    }
  }
  gotoMsgDisplay() {
    this.setDynamicDataForUser([], this.formData);

    if (this.type === "reg") {
      this.formData.postData(this.globals.backendServicesUrl, this);
    } else if (this.type === "rstPswd") {
      this.apiFormatter
        .putApiRequest(this.globals.backendServicesUrl + "user/resetPassword", this.formData.fields)
        .subscribe(data => {
          this.user = {};
          this.user = data;
          if (this.user && this.user.message === "Password Reset done successfully!") {
            this.showMsgs = true;
            this.title = "Reset Password confirmation";
            this.msgs = [
              "Thanks for visiting BaristaOnCloud.",
              "Password Reset done Successfully! Proceed to login."
            ];
          } else {
            this.errorMsg = this.user.message ? this.user.message : "Something went wrong! Please try again later.";
          }

        },
          error => {
            this.errorMsg = error.message;
            // console.log(error);
          });

    }
  }


  deciderValueChange() {
    if (this.formData instanceof User) this.formData.updateUserTopics(this.globals.backendServicesUrl);
  }

  isArray(arr) {
    return Array.isArray(arr);
  }


}


