import { ApiFormatterService } from "src/app/common/services/Api Formatter/api-formatter.service";
// import { AwsS3Service } from '../services/AwsS3Service/aws-s3.service';

export class Instance {
  constructor(private apiFormatter: ApiFormatterService, instanceObject?: any) {
    if (instanceObject) {
      this.fields[0].value = instanceObject["instanceId"];
      this.fields[1].value = instanceObject["instancename"];
      this.fields[2].value = instanceObject["instancetype"];
      this.fields[3].value = instanceObject["ipaddress"];
      this.fields[4].value = instanceObject["port"];
      this.fields[5].value = instanceObject["status"];
    }
  }

  title = "New Instance";
  buttons = [
    {
      value: "Check Availability",
      url: "/console/instance",
      methodType: "PUT"
    }
  ];
  showClose = false;
  message = {
    showStatus: "",
    showTimestamp: "",
    showDate: "",
    showTaskCount: 0,
    totalTaskCount: 5,
    error: false
  };
  progressBar = 0;
  progressMax = 9;
  fields = [
    {
      type: "",
      jsonKey: "instanceId",
      label: "Instance ID",
      value: "",
      // required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "",
      helpIcon: "A proper formatted Instance ID needed! For example, in aws you can use the ec2 instance name."
    },
    {
      type: "select",
      jsonKey: "instancetype",
      label: "Type",
      value: "AWS",
      deciderOption: true,
      options: [{ name: "AWS", value: "AWS" }/*, { name: "GCP", value: "GCP" }*/],
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "select",
      jsonKey: "awsEc2Type",
      label: "Sub-Type",
      value: "",
      deciderJsonKey: "instancetype",
      options: [],
      required: true,
      optionsToChoose: [
        { name : "t2.small", value : "t2.small", deciderValue: "AWS" },
        { name : "t2.medium", value : "t2.medium", deciderValue: "AWS" },
        { name : "t2.large", value : "t2.large", deciderValue: "AWS" },
        { name : "t2.xlarge", value : "t2.xlarge", deciderValue: "AWS" }
        // { name : "GCP dummy", value : "no-value", deciderValue: "GCP" }
      ],
      // required: true,
      error: false,
      errorMsg: "Required",
      pattern: "",
      patternErrorMsg: "",
      specialValidationNotMet: function (instanceObj) {
        // console.log(instanceObj);
        let index: number = instanceObj.fields.findIndex(field =>
          field.jsonKey == "instancetype"
        );
        // console.log(index);
        if (index >= 0) {
          // console.log(instanceObj.fields[index].value);
          if (instanceObj.fields[index].value == "AWS" && !this.value) {
            // console.log("Error");
            this.error = true;
          } else {
            this.error = false;
          }
        }
      }
    },
    {
      type: "select",
      jsonKey: "awsRegion",
      label: "Region",
      value: "",
      deciderJsonKey: "instancetype",
      required: true,
      options: [],
      validated: false,
      optionsToChoose: [
        { name: "US East (Ohio)", value: "us-east-2", deciderValue: "AWS" },
        { name: "US East (N. Virginia)", value: "us-east-1", deciderValue: "AWS" },
        { name: "US West (N. California)", value: "us-west-1", deciderValue: "AWS" },
        { name: "US West (Oregon)", value: "us-west-2", deciderValue: "AWS" },
        { name: "Asia Pacific (Hong Kong)", value: "ap-east-1", deciderValue: "AWS" },
        { name: "Asia Pacific (Mumbai)", value: "ap-south-1", deciderValue: "AWS" },
        { name: "Asia Pacific (Osaka-Local)", value: "ap-northeast-3", deciderValue: "AWS" },
        { name: "Asia Pacific (Seoul)", value: "ap-northeast-2", deciderValue: "AWS" },
        { name: "Asia Pacific (Singapore)", value: "ap-southeast-1", deciderValue: "AWS" },
        { name: "Asia Pacific (Sydney)", value: "ap-southeast-2", deciderValue: "AWS" },
        { name: "Asia Pacific (Tokyo)", value: "ap-northeast-1", deciderValue: "AWS" },
        { name: "Canada (Central)", value: "ca-central-1", deciderValue: "AWS" },
        { name: "China (Beijing)", value: "cn-north-1", deciderValue: "AWS" },
        { name: "China (Ningxia)", value: "cn-northwest-1", deciderValue: "AWS" },
        { name: "EU (Frankfurt)", value: "eu-central-1", deciderValue: "AWS" },
        { name: "EU (Ireland)", value: "eu-west-1", deciderValue: "AWS" },
        { name: "EU (London)", value: "eu-west-2", deciderValue: "AWS" },
        { name: "EU (Paris)", value: "eu-west-3", deciderValue: "AWS" },
        { name: "EU (Stockholm)", value: "eu-north-1", deciderValue: "AWS" },
        { name: "Middle East (Bahrain)", value: "me-south-1", deciderValue: "AWS" },
        { name: "South America (Sao Paulo)", value: "sa-east-1", deciderValue: "AWS" },
        { name: "AWS GovCloud (US-East)", value: "us-gov-east-1", deciderValue: "AWS" },
        { name: "AWS GovCloud (US-West)", value: "us-gov-west-1", deciderValue: "AWS" },
        { name: "GCP dummy", value: "no-value", deciderValue: "GCP" }
      ],
      // required: true,
      error: false,
      errorMsg: "Required",
      pattern: "",
      patternErrorMsg: "",
      onChange: function (instanceObj) {
        this.validated = false;
        instanceObj.buttons[0].value = "Check Availability";
        let amiIdFieldIndex = instanceObj.fields.findIndex(field => field.jsonKey == "amiId");
        instanceObj.fields[amiIdFieldIndex].value = "";
        let instanceNameIndex = instanceObj.fields.findIndex(field => field.jsonKey == "instancename");
        instanceObj.fields[instanceNameIndex].value = instanceObj.fields[instanceNameIndex].originalValue;
      },
      specialValidationNotMet: function (instanceObj) {
        // console.log(instanceObj);
        let index: number = instanceObj.fields.findIndex(field =>
          field.jsonKey == "instancetype"
        );
        // console.log(index);
        if (index >= 0) {
          // console.log(instanceObj.fields[index].value);
          if (instanceObj.fields[index].value == "AWS" && !this.value) {
            // console.log("Error");
            this.error = true;
          } else {
            this.error = false;
          }
        }
      }
    },
    {
      type: "text",
      jsonKey: "instancename",
      label: "Name",
      value: "",
      originalValue: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "",
      onChange: function(instanceObj) {
        this.originalValue = this.value;
      }
    },
    {
      type: "",
      jsonKey: "homefolder",
      label: "Home Folder",
      value: "",
      required: false,
      error: false,
      errorMsg: "Invalid Path",
      pattern: "",
      patternErrorMsg: "Invalid Path"
    },
    {
      type: "",
      jsonKey: "ipaddress",
      label: "IP Address",
      value: "",
      showInUpdateAs: "text",
      makeReadOnly: true,
      // required: true,
      error: false,
      errorMsg: "Invalid IP address",
      pattern: "^(?=.*[^.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).?){4}$",
      patternErrorMsg: "Invalid IP address"
    },
    {
      type: "text",
      jsonKey: "port",
      label: "Port",
      value: "9092",
      required: true,
      error: false,
      errorMsg: "Invalid port number",
      pattern:
        "^(9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])$",
      patternErrorMsg: "Port number must be in range 9000 - 9999"
    },
    {
      type: "",
      jsonKey: "status",
      showInUpdateAs: "text",
      makeReadOnly: true,
      label: "Status",
      value: ""
    },
    {
      type: "",
      jsonKey: "errorMessage",
      showInUpdateAs: "text",
      makeReadOnly: true,
      label: "Description",
      value: "N/A"
    },
    {
      type: "",
      jsonKey: "id",
      label: "",
      value: "",
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "",
      jsonKey: "codeUrl",
      label: "Demo Package",
      selectedType: "file",
      fieldType: "file",
      placeholder: "Choose file to upload",
      helpIcon: "Docker image that include the demo to deploy",
      value: undefined,
      fileType: "",
      fileObj: "",
      preUpload: true,
      uploading: false,
      uploadToS3: true,
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "Invalid File Type",
      showOptions: false,
      sideOptions: [
        { name: "File", value: "file" },
        { name: "Url", value: "url" }
      ],
      jsonObject: {
        fileid: "",
        filename: "",
        filetype: "",
      }
    },
    {
      type: "",
      jsonKey: "dscrptrUrl",
      label: "Demo Descriptor",
      selectedType: "file",
      fieldType: "file",
      placeholder: "Choose file to upload",
      helpIcon: "Container descriptor to expose resources availables and deployment scripts",
      value: undefined,
      fileType: "json",
      fileObj: undefined,
      preUpload: true,
      uploading: false,
      uploadToS3: true,
      readFileContent: true,
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "Invalid File Type",
      showOptions: false,
      sideOptions: [
        { name: "File", value: "file" },
        { name: "URL", value: "url" }
      ],
      jsonObject: {
        fileid: "",
        filename: "",
        filetype: "",
      }
    },
    {
      type: "",
      jsonKey: "amiId",
      label: "",
      value: "",
      required: false,
    },
    {
      type: "",
      jsonKey: "artifactId",
      label: "",
      value: "",
      required: false,
    },
    {
      type: "",
      jsonKey: "instanceResources",
      label: "Instance Resources",
      headings: ["Resource", "Type", "URL"],
      showInResourcesAs: "table",
      tableData: [],
      emptyTableMessage: "No Resources Available"
    }
  ];

  async postData(globalUrl/*,  awsS3: AwsS3Service*/) {

    // for(let i = 0; i < this.fields.length; i++) {
    //   if(this.fields[i].uploadToS3 && this.fields[i].fileObj) {
    //     let res = await awsS3.uploadFileToBucket(this.fields[i].fileObj);
    //     console.log(res);
    //     if(res['Location']) this.fields[i].value = res['Location'];
    //   }
    // }

    // console.log(this.fields);


    let data = await this.apiFormatter
      .putApiRequest(globalUrl + this.buttons[0].url, this.fields)
      .toPromise();
    // console.log(data);
    return data;
    // .subscribe(data => {
    //   console.log(data);
    //   modal["containerRef"].loadInstances();
    //   modal.close("Saved");
    // });
  }

  getData(globalUrl): Instance {
    return new Instance(this.apiFormatter);
  }

  getPopulatdData(objectToPopulateWith: Object, globalUrl: string): Instance {
    // debugger;
    // console.log(objectToPopulateWith);
    let instance: Instance = new Instance(this.apiFormatter);

    instance.title = "Instance Status";
    instance.buttons = [
      {
        value: "Update",
        //TODO: Change API once available
        url: "/console/updateInstance",
        methodType: "PUT"
      }
    ];

    let lastLogBeforeError = undefined;

    if(objectToPopulateWith["status"] && (objectToPopulateWith["status"].includes("ERROR") || objectToPopulateWith["status"].includes("TEST-FAILED")) && objectToPopulateWith["statusLog"]) {
      let logs: Array<string> = objectToPopulateWith["statusLog"].split(";")
      for(let i = logs.length - 1; i >= 0; --i) {
        if(logs[i] && !logs[i].includes("ERROR")) {
          lastLogBeforeError = logs[i]
          break
        }
      }
    }

    if(lastLogBeforeError) {
      instance.message.showStatus = lastLogBeforeError
    }

    for (let field of instance.fields) {
      if (!field.type && field.showInUpdateAs) {
        field.type = field.showInUpdateAs;
      }
      field.value = objectToPopulateWith[field.jsonKey];
    }

    if(objectToPopulateWith["updatedTime"]) {
      let date: Date = undefined;
      try {
        date = new Date(objectToPopulateWith["updatedTime"]);
        if(date) {
          instance.message.showTimestamp = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
          instance.message.showDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
        }
      } catch (error) {
        console.error(error);
        instance.message.showTimestamp = "";
      }
    }

    return instance;
  }

  getInstanceResources(globalUrl, instanceId): Instance {
    let instance: Instance = new Instance(this.apiFormatter);
    instance.title = "Instance Resources";
    instance.buttons = [];

    instance.fields = [];
    instance.showClose = true;

    this.fields.forEach(field => {
      if(field.showInResourcesAs) {
        field.type = field.showInResourcesAs;
        instance.fields.push(field);
      }
    });

    let index = instance.fields.findIndex(field => field.jsonKey == "instanceResources");

    if(index >= 0) {
      // console.log(globalUrl + "/console/getInstanceResourcesById/" + instanceId);
      this.apiFormatter.getApiRequest(globalUrl + "/console/getInstanceResourcesById/" + instanceId).subscribe(data => {
        // console.log(data);
        if(data instanceof Array) {
          data.forEach(dataItem => {
            let dataRow = [dataItem["name"], dataItem["type"], dataItem["maskedUrl"] ? dataItem["maskedUrl"] : dataItem["url"]];
            instance.fields[index].tableData.push(dataRow);
          });
        }
      });
    }

    return instance;
    
  }

}
