import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../register/services/data.service";
import { User } from "../register/user";
import { ApiFormatterService } from "../common/services/Api Formatter/api-formatter.service";
import { Globals } from "../config";

@Component({
  selector: "app-approval",
  templateUrl: "./approval.component.html",
  styleUrls: ["./approval.component.css"],
  providers: [Globals]
})
export class ApprovalComponent implements OnInit {
  dataForMoal: any;
  tabs = [
    {
      name: "Pending Approvals",
      filterByStatus: "registered"
    },
    {
      name: "Active Users",
      filterByStatus: "approved"
    },
    {
      name: "Inactive Users",
      filterByStatus: "inactive"
    }
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private dataService: DataService,
    private apiFormatter: ApiFormatterService,
    private globals: Globals
  ) {

  }

  error: boolean = false;
  items = [];
  users = [];
  username: string;
  formElements = [];
  populatedUser: any = null;
  userObjFormElements = {
    title: "Edit User",
    buttons: [
      {
        value: "Save"
      }
    ],
    fields: [],
    id: ""
  };
  fieldLabels = {
    userName: "Username",
    firstName: "First Name",
    lastName: "Last Name",
    department: "Department",
    status: "Status"
  };

  userObj = {};
  loading: boolean = false;
  deleteModalButtonName = "";
  loadUsers() {
    this.loading = true;
    this.error = false;
    this.dataService.getData("user/getUsers").subscribe(data => {
      this.loading = false;
      this.items = data;
    },
      err => {
        // console.log(err);
        this.loading = false;
        this.error = true;
      });
  }

  ngOnInit() {
    this.username = localStorage.getItem("currentUser");
    if (this.username && localStorage.getItem("consoleAccess") === "true") {
      this.loadUsers();
    } else {
      this.router.navigate([""]);
    }
  }

  beforeChange(event) {
    let tabId = event.nextId;
    // this.users.map((user) => )
  }

  usernameToIndexMap = {};

  setUserObjFieldElements(item, index) {
    this.userObjFormElements.fields = [];
    //console.log(item);
    for (let key in item) {
      if (
        key === "password" ||
        key.includes("secret") ||
        key.includes("ID") ||
        key === "status"
      ) {
        continue;
      }
      let pattern = "";
      let patternErrorMsg = "";
      if (key === "userName") {
        this.usernameToIndexMap[item[key].trim()] = index;
        this.userObjFormElements["id"] = item[key].trim();
        // console.log(this.usernameToIndexMap);
        // pattern =
        //   "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$";
        // patternErrorMsg =
        //   "Password must be at-least of size 8 and must contain at-least 1 alphabet, 1 digit and at-least 1 special character";
      }
      let obj = {
        label: this.fieldLabels[key],
        type: "text",
        key: key,
        value: typeof item[key] === "string" ? item[key].trim() : item[key],
        required: true,
        error: false,
        errorMsg: "",
        pattern: pattern,
        patternErrorMsg: patternErrorMsg
      };
      this.userObjFormElements["fields"].push(obj);
    }
  }

  getFieldIndexByJsonKey(fields: any[], jsonKey: string): number {
    return fields.findIndex(field => field.jsonKey == jsonKey);
  }

  findFieldAndAssign(fields: any[], arrayToPopulateWith: any[]) {
    fields.map(field => {
      let index: number = arrayToPopulateWith.findIndex(object => {
        if (field.jsonKey == object.jsonKey) return true;
      });
      if (index >= 0) field.value = arrayToPopulateWith[index].value;
    });
  }

  setDynamicDataForUser(arrayToAdd: any[], objectToAddTo: any) {
    let arrayToPopulateWith: any[] = [];
    arrayToAdd.map(object => arrayToPopulateWith.push(object));
    let index = this.getFieldIndexByJsonKey(objectToAddTo.fields, "roleID");
    if (index >= 0) {
      let roleIndex = objectToAddTo.fields[index]["dataFromApi"].findIndex(
        role => role.roleID == objectToAddTo.fields[index].value
      );
      arrayToPopulateWith.push({
        jsonKey: "role",
        value: objectToAddTo.fields[index]["dataFromApi"][roleIndex]
      });
    }
    index = this.getFieldIndexByJsonKey(objectToAddTo.fields, "userTopic");
    if (index >= 0) {
      let arrayToSetAsValue: any[] = [];
      objectToAddTo.fields[index].selectedValues.map(value => {
        arrayToSetAsValue.push({
          userID: null,
          topicID: value.value
        });
      });
      arrayToPopulateWith.push({
        jsonKey: "userTopic",
        value: arrayToSetAsValue
      });
    }
    this.findFieldAndAssign(objectToAddTo.fields, arrayToPopulateWith);
  }

  getPopulatedUser(item): any {
    let editFields: any[] = [];
    let user: User = new User(this.apiFormatter, this.globals).getPopulatdData(
      item,
      this.globals.backendServicesUrl
    );
    for (let field of user.fields) {
      if (field.doNotShowInEdit) continue;
      editFields.push(field);
    }
    user["allFields"] = user.fields;
    user.fields = editFields;
    this.dataForMoal = user;
    // console.log(this.dataForMoal);
  }


  setDeleteModalContent(userObj, type?: string) {
    let tempUserObj = { ...userObj };
    if (type === "activate") {
      this.deleteModalButtonName = "Activate";
      tempUserObj.status = "Registered";
    } else if(type == "deactivate") {
      this.deleteModalButtonName = "Deactivate";
      tempUserObj.status = "Inactive";
    } else {
      this.deleteModalButtonName = "Delete";
      tempUserObj.status = "Inactive";
    }
    this.userObj = {
      title: this.deleteModalButtonName + " User",
      name: tempUserObj.firstName.trim() + " " + tempUserObj.lastName.trim(),
      id: tempUserObj.userName.trim(),
      callbackFunc: this.dataService.putData("/user/editUser", tempUserObj),
      onSuccess: this.saveChanges.bind(userObj, tempUserObj)
    };
  }

  saveChanges(actualUserObj, tempUserObj) {
    actualUserObj = tempUserObj;
  }

  async dataFromModal(event, modal) {
    // let objFromItems = this.items[this.usernameToIndexMap[event.id]];
    // for (let field of event.fields) {
    //   objFromItems[field["key"]] = field.value;
    // }
    // for (let key in objFromItems) {
    //   if (objFromItems[key] instanceof String)
    //     objFromItems[key] = objFromItems[key].trim();
    // }
    // console.log(objFromItems);
    // this.dataService.putData("user/editUser", objFromItems).subscribe(
    //   data => {
    //     this.modalService.dismissAll();
    //   },
    //   error => {
    //     console.error(error);
    //     this.modalService.dismissAll();
    //   }
    // );
    if (event instanceof User) {
      if(event.buttons[0].value == "Activate") {
        let tempUserObj = {...event["tempObj"]};
        //SET STATUS TO APPROVE
        tempUserObj.status = "Approved";
        let data = await this.dataService.putData("/user/editUser", tempUserObj).toPromise();
        this.loadUsers();
        modal.close();
        return;
      }
      // for (let i in event["allFields"]) {
      //   if (!event["allFields"][i]["jsonKey"]) continue;
      //   let index: number = event.fields.findIndex(
      //     fieldToSearch =>
      //       fieldToSearch["jsonKey"] == event["allFields"][i]["jsonKey"]
      //   );
      //   if (index >= 0) {
      //     event["allFields"][i] = event.fields[index];
      //   }
      // }
      // event.fields = event["allFields"];
      // console.log(event);
      this.setDynamicDataForUser([], event);
      let index = this.getFieldIndexByJsonKey(event.fields, "status");
      if(index>0 && event.fields[index].value === "Registered")
      if(event.buttons[0].value == "Approve") event.fields[index].value = "Approved";
      event.editData(this.globals.backendServicesUrl, this);
    }
  }

  dataFromDeleteModal(userObj) {
    this.loadUsers();
  }


  openApproveModal(item, content, type = "") {
    let user: User = new User(this.apiFormatter, this.globals).getUserApprovalData(item, this.globals.backendServicesUrl);
    this.dataForMoal = user;
    if(type == "activate"){
      this.dataForMoal.buttons[0].value = "Activate";
      this.dataForMoal["tempObj"] = item;
      this.dataForMoal.title = "Activate User";
    }
    this.openWindowCustomClass(content);
  }

  approveUser(item, i) {
    for (let key in item) {
      if (item[key] instanceof String) item[key] = item[key].trim();
      if (key === "status") {
        item[key] = "Approved";
      }
    }
    this.dataService.putData("/user/editUser", item).subscribe(
      data => {
        //debugger;
        // console.log(data)
      },
      error => {
        // console.log(error);
        item.status = "Registered";
      }
    );
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static"
    });
  }

  openDeleteModal(deleteContent) {
    this.modalService.open(deleteContent, {
      centered: true,
      backdrop: "static"
    });
  }
}
