import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../register/services/data.service";
import { Instance } from "../console/custom-modal/instance";
import { Globals } from "../config";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiFormatterService } from "../common/services/Api Formatter/api-formatter.service";
import { Container } from "../console/custom-modal/container";
import { AwsS3Service } from '../console/services/AwsS3Service/aws-s3.service';
import { WebSocketAPI } from '../console/WebSocketAPI';
import { AppComponent } from '../app.component';
// import { LoginComponent } from '../login/login.component';
// import { AwsS3Service } from '../console/services/AwsS3Service/aws-s3.service';

declare function clickFileLoader(id): any;
@Component({
  selector: "app-container",
  templateUrl: "./container.component.html",
  styleUrls: ["./container.component.css"],
  providers: [Globals]
})
export class ContainerComponent implements OnInit, OnDestroy {
  formData: any;
  containerId: string;
  keyToFormElementMap = {};
  instanceList = [];
  selectedInstancesList = [];
  modalDataForInstance: any = {};
  responseFromSaveContainer: Object = new Object();
  loading: boolean = false;
  webSocketApi: WebSocketAPI;
  webSocketEndPointForStomp: string = this.globals.webSocketEndPointForStomp;
  topicURIForStomp: string = this.globals.containerTopicURIForStomp;
  sendURIForStomp: string = this.globals.containerSendURIForStomp;
  mapForInstanceStatus = {};
  artifactResources = [];

  tabs = [
    {
      name: "Detail"
    },
    {
      name: "Resources"
    }
  ];
  
  @Input() selectedContainerId;
  @Output() doneButtonClick = new EventEmitter()

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private globals: Globals,
    private modalService: NgbModal,
    private apiFormatterService: ApiFormatterService,
    private awsS3: AwsS3Service,
    private appComponenetRef: AppComponent
  ) { }

  loadInstances() {
    this.loading = true;
    this.dataService
      .getData("/console/getInstancesByArtifact/" + this.containerId)
      .subscribe(res => {
        this.instanceList = res;
        this.loading = false;
        this.instanceList.forEach(instance => {
          this.mapForInstanceStatus[instance.instancename] = instance.status;
        });
        // console.log(res);
      },
      err => {
        this.router.navigate(["error"]);
        this.loading = false;
      });

    if (!this.instanceList) {
      this.instanceList = [];
    }
  }
  username: string;
  timestamp = undefined;
  async ngOnInit() {

    this.webSocketApi = new WebSocketAPI(this.webSocketEndPointForStomp, this.topicURIForStomp, this.sendURIForStomp, this);
    this.webSocketApi._connect();

    this.timestamp = new Date().getTime();
    //NEW
    this.username = localStorage.getItem("currentUser");
    if (this.username && localStorage.getItem("consoleAccess") === 'true') {


      // this.formData = new Container(this.apiFormatterService).getData(
      //   this.globals.devDbUrl
      // =
      this.loading = true;
      this.formData = new Container(this.apiFormatterService).getPopulatdData(await this.dataService
        .getData("console/getArtifactByID/" + this.selectedContainerId)
        .toPromise(),this.globals.devDbUrl);
      // console.log(this.formData);
      this.loading = false;
      this.containerId = this.selectedContainerId;
      this.loadInstances();
      this.loadArtifactResources();
    } else {
      this.router.navigate([""]);
    }
  }

  ngOnDestroy() {
    if(this.webSocketApi) this.webSocketApi._disconnect();
  }

  async loadArtifactResources() {
    this.loading = true;
    try {
      let data = await this.apiFormatterService.getApiRequest(this.globals.backendServicesUrl + "console/getArtifactResourcesById/" + this.containerId).toPromise();
      this.artifactResources = data;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }


  toggleSideOptions(modalDataField) {
    this.formData.fields.map((element) => {
      if(element.jsonKey != modalDataField.jsonKey && element.showOptions) {
        element.showOptions = false;
      }
      else if(element.type == "many"){
        element.fields.map((subElement) => {
          if(subElement.jsonKey != modalDataField.jsonKey && subElement.showOptions) {
            subElement.showOptions = false;
          }
        });
      }
    });
    if(modalDataField.showOptions != undefined)
    modalDataField.showOptions = !modalDataField.showOptions;
  }

  async uploadToAwsS3(item) {
    // console.log(this.timestamp);
    item.uploading = true;
    // console.log(item.directoryPath);
    let res = await this.awsS3.uploadFileToBucket(item.fileObj, item.directoryPath, this.timestamp? this.timestamp : new Date().getTime(), item);
    // console.log(res);
    if(res['Location']){
      item.value = res['Location'];
      item.error = false;
    } 
    item.uploading = false;
    item.fileObj = undefined;
  }



  callClose(event, index) {
    event.preventDefault();
    event.stopPropagation();
  }

 

  async submit() {
    this.router.navigate(["console"]);
  }

  async saveContainer() {
    // formData = <Container>formData;
    await this.formData.postData(this.globals.devDbUrl, this);
    // console.log(this.responseFromSaveContainer);
    // this.router.navigate(["console"]);
  }
  async updateContainer() {
    this.formData.action = "Update"
    this.formData.buttons[0].url = "/console/editArtifact";
    for (var field of this.formData.fields) {
      if (field.jsonKey == "artifactID") {
        field.value = this.containerId;
      }
      if (field.jsonKey == 'owner') {
        let arrayToSetAsValue: any[] = [];
        field.selectedValues.forEach(value => {
          arrayToSetAsValue.push(value.value);
        });
        field.value = arrayToSetAsValue;
      }
    }
    await this.formData.postData(this.globals.devDbUrl, this);
    // console.log("updateContainer:", this);
  }
  formatDate(date): string {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  findFieldAndAssign(fields: any[], arrayToPopulateWith: any[]) {
    fields.map(field => {
      let index: number = arrayToPopulateWith.findIndex(object => {
        if (field.jsonKey == object.jsonKey) return true;
      });
      if (index >= 0) field.value = arrayToPopulateWith[index].value;
    });
  }

  async dataFromInstanceModal(event: any, modal) {
    
    let amiIdFieldIndex = event.fields.findIndex(field => field.jsonKey == "amiId");
    let regionFieldIndex = event.fields.findIndex(field => field.jsonKey == "awsRegion");

    if(event.buttons[0].value == "Check Availability" && amiIdFieldIndex >=0 && !event.fields[amiIdFieldIndex].value) {
      event["modalCreateButtonClicked"] = false;
      event.buttons[0].url = "console/getAmiIdForInstance";
      let data = undefined;
      try {
        data = await event.postData(this.globals.backendServicesUrl);
        // data = {amiId: "123"};
      } catch (error) {
        console.error(error);
      }
      // console.log(data);
      if(data && data["amiId"]) {
        event.fields[amiIdFieldIndex].value = data["amiId"];
        event.buttons[0].value = "Create";
        event["modalCreateButtonClicked"] = true;
        event.fields[regionFieldIndex].validated = true;
        let instanceNameIndex = event.fields.findIndex(field => field.jsonKey == "instancename");
        event.fields[instanceNameIndex].originalValue = event.fields[instanceNameIndex].value;
        let optionsIndex = event.fields[regionFieldIndex].options.findIndex(option => option.value == event.fields[regionFieldIndex].value);
        if(event.fields[instanceNameIndex].value && optionsIndex >= 0) event.fields[instanceNameIndex].value += " - " + event.fields[regionFieldIndex].options[optionsIndex].name;
        event.message.showStatus = "Estimated time of creation: 10 minutes"
      } else {
        if(regionFieldIndex >= 0) {
          event.fields[regionFieldIndex].error = true;
          event.fields[regionFieldIndex].errorMsg = "AWS AMI not available for this region";
        }
        event["modalCreateButtonClicked"] = true;
      }
      return;
    }

    this.appComponenetRef.restart()
    modal["containerRef"] = this;
    // console.log(event);
    // debugger;
    let arrayToPopulateWith: any[] = [];

    //Setting URLs for instance from the Container
    let codeUrl: string, descrptrUrl: string, homeFolder: string = "";
    let index = this.getFieldIndexByJsonKey(this.formData.fields, "codeUrl");
    codeUrl = index >= 0? this.formData.fields[index].value: "";
    index = this.getFieldIndexByJsonKey(this.formData.fields, "dscrptrUrl");
    descrptrUrl = index >= 0? this.formData.fields[index].value: "";
    
    if(codeUrl) {
      let codeUrlSplitted = codeUrl.split("/");
      let resourceName = codeUrlSplitted[codeUrlSplitted.length - 1];
      homeFolder = resourceName.split(".")[0];
    }
    arrayToPopulateWith.push(
      {
        jsonKey: "codeUrl",
        value: codeUrl
      },
      {
        jsonKey: "dscrptrUrl",
        value: descrptrUrl
      },
      {
        jsonKey: "homefolder",
        value: homeFolder
      }
      );

    this.findFieldAndAssign(event.fields, arrayToPopulateWith);


    // SENDING STOMP MESSAGE
    if(event.buttons[0].value == "Create"){
      this.sendCreateStompMessageForInstance(event.fields);
      
      this.modalDataForInstance.buttons = [];
  
      this.setFieldsReadOnlyContraint(event.fields, true);
      return;
    }

    // let data = await event.postData(this.globals.devDbUrl/*, this.awsS3*/);
    // // console.log(data);
    // modal.close("Saved");
    // this.addAndLinkInstance(data["id"])

    // let jsonArrayToSend: any[] = [
    //   {
    //     deployID: null,
    //     id: data["id"],
    //     artifactID: this.containerId
    //   }
    // ];
    // //console.log(jsonArrayToSend);
    // let data2 = await this.formData.linkSelectedInstances(
    //   this.globals.backendServicesUrl,
    //   jsonArrayToSend,
    //   this
    // );
    //console.log(data2);
    // localStorage.setItem(
    //   this.containerId + "-instances",
    //   JSON.stringify(this.instanceList)
    // );
    // this.loadInstances();
  }

  async addAndLinkInstance(instanceId, artifactId) {
    // let data = await instance.postData(this.globals.devDbUrl/*, this.awsS3*/);
    // console.log(data);
    let jsonArrayToSend: any[] = [
      {
        deployID: null,
        id: instanceId,
        artifactID: artifactId
      }
    ];
    // console.log(jsonArrayToSend);
    let data2 = await this.formData.linkSelectedInstances(
      this.globals.backendServicesUrl,
      jsonArrayToSend,
      this
    );
    // console.log(data2);
    localStorage.setItem(
      this.containerId + "-instances",
      JSON.stringify(this.instanceList)
    );

    this.loadInstances();
  }

  addDirectoryPathForInstanceFiles(instance: Instance) {
    let index = this.getFieldIndexByJsonKey(instance.fields, "codeUrl");
    let containerNameIndex = this.getFieldIndexByJsonKey(this.formData.fields, "artifactName");
    if(index >= 0 && containerNameIndex >= 0)
      instance.fields[index]["directoryPath"] = this.formData.fields[containerNameIndex].value;
    index = this.getFieldIndexByJsonKey(instance.fields, "dscrptrUrl");
    if(index >= 0 && containerNameIndex >= 0)
      instance.fields[index]["directoryPath"] = this.formData.fields[containerNameIndex].value;
  }
  getFieldIndexByJsonKey(fields: any[], jsonKey: string): number {
    return fields.findIndex(field => field.jsonKey == jsonKey);
  }

  newInstanceModal(content): void {
    this.modalDataForInstance = new Instance(this.apiFormatterService).getData(
      this.globals.devDbUrl
    );
    this.modalDataForInstance["modalCreateButtonClicked"] = false;
    this.addDirectoryPathForInstanceFiles(this.modalDataForInstance);
    this.openWindowCustomClass(content);
  }

  openInstanceReosurcesModal(content, instanceId): void {
    this.modalDataForInstance = new Instance(this.apiFormatterService).getInstanceResources(this.globals.devDbUrl, instanceId);
    this.openWindowCustomClass(content);
  }

  // NO LONGER NEEDED TO UPDATE INSTANCE
  // openEditModal(content, objectToPopulateWith, event) {
  //   objectToPopulateWith.status = this.mapForInstanceStatus[objectToPopulateWith.instancename];
  //   // console.log(objectToPopulateWith);
  //   this.appComponenetRef.stopWatching();
  //   event.stopPropagation();
  //   this.modalDataForInstance = new Instance(
  //     this.apiFormatterService
  //     ).getPopulatdData(objectToPopulateWith, this.globals.devDbUrl);
    
  //   this.modalDataForInstance["modalCreateButtonClicked"] = false;
  //   this.addDirectoryPathForInstanceFiles(this.modalDataForInstance);
  //   this.openWindowCustomClass(content);
  // }
  deleteObj: object;
  setDeleteModalInstance(instance, deleteContent) {
    // debugger;
    this.deleteObj = {
      title: "Delete Instance",
      name: instance.instancename,
      id: instance.id,
      callbackFunc: this.dataService.postData(
        "/console/deleteInstance/" + instance.id,
        {}
      )
    };
    this.openDeleteModal(deleteContent);
  }
  openDeleteModal(deleteContent) {
    // debugger;
    this.modalService.open(deleteContent, {
      centered: true,
      backdrop: "static"
    });
  }
  openWindowCustomClass(content) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static"
    });
  }
  dataFromDeleteModal(event) {
    this.loadInstances();
  }

  

  getPath(fileInput, item) {
    if (fileInput && fileInput.target.files && fileInput.target.files[0]) {
      item.value = fileInput.target.files[0].name;
      item.fileObj = fileInput.target.files[0];
    }
  }

  selectFileUrlType(modalDataField, fieldType){
    if(modalDataField.fieldType == fieldType) return;
    modalDataField.fieldType = fieldType;
    modalDataField.value = '';
  }

  containerBodyClicked(event) {
    // console.log(this.formData);
    this.formData.fields.map((element) => {
      if(element.showOptions) {
        element.showOptions = false;
      }
      else if(element.type == "many"){
        element.fields.map((subElement) => {
          if(subElement.showOptions) {
            subElement.showOptions = false;
          }
        });
      }
    });
  }

  fileLoader(id) {
    clickFileLoader(id);
  }

  onModalClose(instanceModal) {
    instanceModal.close();
    this.appComponenetRef.restart();
  }

  setFieldsReadOnlyContraint(fields: any[], value: boolean) {
    fields.forEach(field => {
      field["makeReadOnly"] = value;
    });
  }

  

  async openStatusModal(content, instanceName, event) {
    this.appComponenetRef.stopWatching();
    event.stopPropagation();
    let urlToGetLatestInstance = this.globals.devDbUrl + "/console/getInstanceByName/" + instanceName;
    this.loading = true;
    let objectToPopulateWith = await this.apiFormatterService.getApiRequest(urlToGetLatestInstance).toPromise();
    // console.log(objectToPopulateWith);
    this.loading = false;
    this.modalDataForInstance = new Instance(this.apiFormatterService).getPopulatdData(objectToPopulateWith, this.globals.devDbUrl);
    this.modalDataForInstance.buttons = [];
    this.assignProgressValue(objectToPopulateWith.instancename, objectToPopulateWith.status);
    this.setFieldsReadOnlyContraint(this.modalDataForInstance.fields, true);
    this.openWindowCustomClass(content);
  }

  errorMessageForInstance = {
    "CREATING" : "Error occurred while CREATING demo instance",
    "DEPLOYING": "Error occurred while DEPLOYING demo instance",
    "CONFIGURING" : "Error occurred while CONFIGURING demo instance",
    "STARTING DEMO" : "Error occurred while STARTING demo instance",
    "TESTING" :  "Error occurred while TESTING demo instance"
  }

  assignProgressValue(key: string, value: string) {
    if(!this.modalDataForInstance || !(this.modalDataForInstance.fields instanceof Array)) return;
    let instanceNameIndex = this.getFieldIndexByJsonKey(this.modalDataForInstance.fields, "instancename");
    if(instanceNameIndex < 0 || key != this.modalDataForInstance.fields[instanceNameIndex].value) return;
    let instanceStatusIndex = this.getFieldIndexByJsonKey(this.modalDataForInstance.fields, "status");
    if(instanceStatusIndex >= 0) this.modalDataForInstance.fields[instanceStatusIndex].value = value;
    let date = new Date();
    // console.log(this.modalDataForInstance.message.showTimestamp);
    if(!this.modalDataForInstance.message.showTimestamp) this.modalDataForInstance.message.showTimestamp = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    if(value.includes("CREATING")) { this.modalDataForInstance.progressBar = 1; this.modalDataForInstance.message.showTaskCount = "0" }
    else if(value.includes("CREATED")) { this.modalDataForInstance.progressBar = 1; this.modalDataForInstance.message.showTaskCount = "0" }
    else if(value.includes("DEPLOYING")) { this.modalDataForInstance.progressBar = 2; this.modalDataForInstance.message.showTaskCount = 1 }
    else if(value.includes("DEPLOYED")) { this.modalDataForInstance.progressBar = 3; this.modalDataForInstance.message.showTaskCount = 2 }
    else if(value.includes("CONFIGURING")) { this.modalDataForInstance.progressBar = 4; this.modalDataForInstance.message.showTaskCount = 2 }
    else if(value.includes("CONFIGURED")) { this.modalDataForInstance.progressBar = 5; this.modalDataForInstance.message.showTaskCount = 3 }
    else if(value.includes("STARTING")) {
      this.modalDataForInstance.progressBar = 6;
      this.modalDataForInstance.message.showTaskCount = 3;
      this.modalDataForInstance.message.showStatus = value + " DEMO" 
      return
    }
    else if(value.includes("STARTED")) { this.modalDataForInstance.progressBar = 7; this.modalDataForInstance.message.showTaskCount = 3 }
    else if(value.includes("TESTING")) { this.modalDataForInstance.progressBar = 8; this.modalDataForInstance.message.showTaskCount = 4 }
    else if(value.includes("RUNNING")) {
      this.modalDataForInstance.message.showTaskCount = 5;
      this.modalDataForInstance.message.showStatus = "";
      this.modalDataForInstance.progressBar = 0;
      return
    }
    else if(value.includes("ERROR") || value.includes("TEST-FAILED")) {
      this.modalDataForInstance.message.error = true;
      this.modalDataForInstance.progressBar = 0;
      this.modalDataForInstance.message.showTimestamp = "";
      if(this.modalDataForInstance.message.showStatus.includes("ERROR")) return
      let ind = this.getFieldIndexByJsonKey(this.modalDataForInstance.fields, "errorMessage")
      this.modalDataForInstance.fields[ind].label = "Error Description"
      let msg = this.errorMessageForInstance[this.modalDataForInstance.message.showStatus]
      if(!this.modalDataForInstance.message.showStatus) msg = this.errorMessageForInstance["CREATING"]
      if(!msg) msg = "Error occurred at " + this.modalDataForInstance.message.showStatus + "state of instance demo"
      this.modalDataForInstance.message.showStatus = value + "! " + msg + ". Please try creating a new instance."
      return
    }
    this.modalDataForInstance.message.showStatus = value;
  }

  sendCreateStompMessageForInstance(fields: any[]) {
    let messageToSend: any = this.getFormattedStompMessageForInstance("create", fields);
    this.webSocketApi._send(messageToSend);
  }

  getFormattedStompMessageForInstance(startOrStopMessage, fields: any[]) {

    let artifactIndex = fields.findIndex(field => field.jsonKey == "artifactId");
    if(artifactIndex >= 0) {
      fields[artifactIndex].value = this.containerId;
    }

    let formattedInstanceObject = JSON.parse(this.apiFormatterService.getPUTJsonFormat(fields));
    let stompMessageObject = {
      startOrStop: startOrStopMessage,
      instance: formattedInstanceObject
    }
    return stompMessageObject;
  }

  setInstanceDescription(message) {
    if(!message) return
    let ind = this.getFieldIndexByJsonKey(this.modalDataForInstance.fields, "errorMessage")
    if(ind < 0) return
    this.modalDataForInstance.fields[ind].value = message
    if(!this.modalDataForInstance.fields[ind].type) this.modalDataForInstance.fields[ind].type = this.modalDataForInstance.fields[ind].showInUpdateAs
  }

  handleMessageFromStomp(message) {
    // debugger;
    message = JSON.parse(message);

    if(message.instance && message.instance instanceof Object) {
      this.setInstanceDescription(message.instance.errorMessage)
    }
    if(message.instance && message.instance instanceof Object && message.instance.id != undefined && !message.instance.ipaddress) {
      this.addAndLinkInstance(message.instance.id, message.instance.artifactId);
      return;
    }

    let msgKey = Object.keys(message)[0];
    if(message[msgKey] == "DuplicateName") {
      let ind = this.getFieldIndexByJsonKey(this.modalDataForInstance.fields, "instancename");
      if(ind >= 0){
        this.modalDataForInstance.fields[ind].error = true;
        this.modalDataForInstance.fields[ind].errorMsg = "Duplicate Value for Instance Name not allowed!";
        this.modalDataForInstance.buttons = [{
          value: "Create",
          url: "/console/instance",
          methodType: "PUT"
        }];
        this.modalDataForInstance["modalCreateButtonClicked"] = true;
        this.setFieldsReadOnlyContraint(this.modalDataForInstance.fields, false);
        return;
      }
    }

    let date = new Date();
    if(this.modalDataForInstance.message) this.modalDataForInstance.message.showTimestamp = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    this.mapForInstanceStatus[msgKey] = message[msgKey];
    this.assignProgressValue(msgKey, message[msgKey]);
  }

  doneButtonClicked() {
    this.doneButtonClick.emit()
  }

}
