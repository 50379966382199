import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "../config";
import { Router, ActivatedRoute } from "../../../node_modules/@angular/router";
import { DataService } from "../register/services/data.service";
import { ApiFormatterService } from "../common/services/Api Formatter/api-formatter.service"
import { from } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: "app-topic",
  templateUrl: "./topic.component.html",
  styleUrls: ["./topic.component.css"],
  providers: [Globals]
})
export class TopicComponent implements OnInit {
  alltopics: any;
  filteredTopics = [];
  themes: any;
  selectedTopicID: any;
  selectedTopicName: any;
  selectedTopicDescription: any;
  selectedTopic: any;
  showthemes: boolean;
  index: number = -1;
  topicLogo: string;
  closeResult: string;
  username: string;
  tabs: any;
  bgAndIconObj = {};
  error: boolean = false;
  searchText: string = "";
  organizations = [];
  selectedOrganizations = [];
  countries = [];
  showSearchFilters = false;
  selectedCategory = "All";

  item =
    {
      type: "multiSelect",
      value: [],
      label: "Organization",
      selectedValues: [],
      options: [],
      showPlaceholderAlways: true,
      always: true,
      placeholder: "Hitachi Center",
      settings: {
        singleSelection: false,
        idField: "value",
        textField: "name",
        itemsShowLimit: 0,
        disabled: true,
        enableCheckAll: false,
        allowSearchFilter: true
      },
      required: false,
      error: false,
      errorMsg: "",
      pattern: ""
    }
  countryItem =
    {
      type: "multiSelect",
      value: [],
      label: "Country",
      selectedValues: [],
      options: [],
      showPlaceholderAlways: true,
      always: true,
      placeholder: "Country",
      settings: {
        singleSelection: false,
        idField: "value",
        textField: "name",
        itemsShowLimit: 0,
        disabled: true,
        enableCheckAll: false,
        allowSearchFilter: true
      },
      required: false,
      error: false,
      errorMsg: "",
      pattern: ""
    }
  constructor(
    private globals: Globals,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private apiFormatter: ApiFormatterService,
  ) {
    this.showthemes = false;
    this.username = this.apiFormatter.removeQuotesFromString(localStorage.getItem("currentUser"));

    // TODO: Check if the getTopics is right way to implement in constructor
    this.getTopics();
    // this.route.queryParams.subscribe(params => {
    //   this.username = params["username"];
    // });
    this.item.options = this.organizations;
    this.item.selectedValues = this.item.options;

    // Fetch countries to populate in dropdown
    this.constructCountryDropdownValues();

  }


  ngOnInit() {

    if (this.username) {
      return;
    } else {
      this.router.navigate([""]);
    }
  }


  constructOrganizationDropdown(contact: any) {
    const value = contact["organization"] + " - " + contact["department"];
    const flag = _.includes(this.organizations, value);
    if (!flag) {
      this.organizations.push(value);
    }
  }
  constructCountryDropdownValues() {
    this.apiFormatter.getApiRequest(this.globals.filesServerUrl + "/getJson/resources/countries.json").subscribe(res => {
      const temp = _.sortBy(res, o => o.name)
      this.countryItem.options = temp;
      this.countryItem.selectedValues = this.countryItem.options;
    });

  }
  /**
    * Filter the topics based on selected search text,
    * selected organization and selected country.
    * @MethodName searchFormSubmit
    */
  searchFormSubmit() {
    const value = this.searchText;
    // Return if the value is empty
    if (value == '') {
      this.filteredTopics = this.alltopics;
      return;
    }

    // Separate values based on comma or space and search
    const spaceSeparatedValues = value.split(" ");
    var finalFilteredValues = [];
    for (var text of spaceSeparatedValues) {
      const tempResult = _.filter(this.alltopics, function (topic) {
        return topic.topicname.includes(text) || topic.topicdesc.includes(text) || topic.horizantals.includes(text) || topic.keywords.includes(text);
      });
      finalFilteredValues.push(tempResult)
    }
    this.filteredTopics = [].concat.apply([], finalFilteredValues);
  }

  open(content) {
    const modalRef = this.modalService.open(content);
  }
  async loadThemes(topic) {
    this.topicLogo = await this.getTopicLogoBasedOnType(
      topic.logos,
      "blue",
      topic.topicid
    );
    this.showthemes = true;
    this.selectedTopic = topic;
    this.selectedTopicID = topic.topicid;
    this.selectedTopicName = topic.topicname;
    this.selectedTopicDescription = topic.topicdesc;
  }

  /**
   * Filter the topics based on selected search text,
   * selected organization and selected country.
   * @MethodName filterSubmitButtonClick
   */
  filterSubmitButtonClick() {
    // Get all selected values for search, organization and country
    const selectedOrganizationValues = this.item.selectedValues;
    const selectedCountriesValues = this.countryItem.selectedValues;
    const searchValue = this.searchText;
    const selectedCategoryValue = this.selectedCategory;

    var tempFinalTopics = this.alltopics;

    // console.log("showSelectedCategoryValues " + value);
    // Return all topics as filtered topics for all industries option.
    //if (value == "All" ) {
    if (selectedCategoryValue=='All'){
      tempFinalTopics = this.alltopics;

    } else {
      // Filter topics based on selected industry.
      var tempFilteredTopics = [];
      tempFilteredTopics = tempFinalTopics.filter(function (topic) {
        //fixed amount of values, not working for my purpose
        return topic["horizantals"].includes(selectedCategoryValue)
      });
      tempFinalTopics = tempFilteredTopics;
    }

    // Search related filter
    if (searchValue != '') {
      // Separate values based on comma or space and search
      const spaceSeparatedValues = searchValue.split(" ");
      var tempFilteredTopics = [];
      for (var text of spaceSeparatedValues) {
        const tempResult = _.filter(tempFinalTopics, function (topic) {
          return topic.topicname.includes(text) || topic.topicdesc.includes(text) || topic.horizantals.includes(text) || topic.keywords.includes(text);
        });
        tempFilteredTopics.push(tempResult)
      }
      tempFinalTopics = [].concat.apply([], tempFilteredTopics);
    }


    // Filter based on selected organization items.
    if (selectedOrganizationValues.length > 0) {
      var tempFilteredTopics = [];
      for (var value of selectedOrganizationValues) {
        var separatedValues;
        if (value.name != undefined && value.name != null) {
          separatedValues = value.name.split(" - ");
        } else {
          separatedValues = value.split(" - ");
        }
        const tempResult = _.filter(tempFinalTopics, { contact: { organization: separatedValues[0], department: separatedValues[1] } });
        tempFilteredTopics.push(tempResult);
      }
      tempFinalTopics = [].concat.apply([], tempFilteredTopics);
    }


    // Filter based on selected country items.
    if (selectedCountriesValues.length > 0) {
      var tempFilteredTopics = [];
      for (var selectedValue of selectedCountriesValues) {
        for (let topic of tempFinalTopics) {
          if (topic.contact.country == selectedValue.value) {
            tempFilteredTopics.push(topic)
          }
        }
      }
      tempFinalTopics = tempFilteredTopics;
    }

    // Assign temp filtered values to main filtered topics
    this.filteredTopics = tempFilteredTopics;
  }
  /**
   * @MethodName filterSubmitButtonClick
   * Gets script version
   * @param
   * @returns script version
   */
  exploreTopicsButtonClick(event: any) {
    this.showSearchFilters = !this.showSearchFilters
  }
  /**
   * @MethodName topicsSearchFilter
   * Gets script version
   * @param fileName
   * @returns script version
   */
  inputClicked(item: any) {
    //console.log(JSON.stringify(item));
  }

  /**
   * @MethodName topicsSearchFilter
   * Gets script version
   * @param fileName
   * @returns script version
   */
  getDemosCount() {
    var count = 0;
    for (var topic of this.filteredTopics) {
      if ((topic["themeNames"] != null) && (topic["themeNames"] != undefined)) {
        count = count + topic.themeNames.length;
      }
    }
    return count;
  }
  /**
   * @MethodName topicsSearchFilter
   * Gets script version
   * @param fileName
   * @returns script version
   */
  showSelectedCategoryValues(value: string) {
    this.selectedCategory = value;
    // console.log("showSelectedCategoryValues " + value);
    // Return all topics as filtered topics for all industries option.
    if (value == "All") {
      this.filteredTopics = this.alltopics;
      return this.filteredTopics;
    }
    // Filter topics based on selected industry.
    this.filteredTopics = this.alltopics.filter(function (topic) {
      //fixed amount of values, not working for my purpose
      return topic["horizantals"].includes(value)
    });
  }
  getIndustryString(value) {
    var str = value;
    str = value.replace(/[^\w\s]/gi, "") // Remove non word characters
      .trim() // Remove trailing and leadings spaces
      .replace(/\b\w/g, (s) => s.toLowerCase()) // Make the first letter of each word upper case

    if (str == "none") {
      return '';
    }
    return str;
  }
  getTopics() {
    this.error = false;
    this.apiFormatter.getApiRequest(this.globals.devDbUrl + "/getTopics/" + this.username).subscribe(res => {
      this.alltopics = res;
      this.filteredTopics = res;
      this.index = -1;
      for (const topic of this.alltopics) {
        this.index++;
        // Add contacts to organizations object for filtering organizations
        this.constructOrganizationDropdown(topic["contact"]);
        // this.constructCountryDropdownValues(topic["country"]);
        // this.organizations.push(topic["contact"]);

        let topicid = topic.topicid;
        this.getThemesNames(topic.topicid, this.index);
        this.bgAndIconObj[topicid] = {};
        this.bgAndIconObj[topicid]["white"] = this.globals.filesServerUrl + "/img/cloud-white.svg";

        this.bgAndIconObj[topicid]["blue"] = this.globals.filesServerUrl + "/img/cloud.svg";

        this.bgAndIconObj[topicid]["background"] = this.globals.filesServerUrl + "/resources/pdm/pdm_bg.svg";

        for (let logo of topic.logos) {
          this.dataService
            .getDataFromNode(["Topic_" + topicid, logo.logoname])
            .subscribe(
              data => {
                this.bgAndIconObj[topicid][logo.logotype] =
                  this.globals.imagePath +
                  "Topic_" +
                  topicid +
                  "/" +
                  logo.logoname;
              },
              err => {
                let type = logo.logotype;
                if (type === "white") {
                  this.bgAndIconObj[topicid][logo.logotype] = this.globals.filesServerUrl + "/img/cloud-white.svg";
                } else if (type === "blue") {
                  this.bgAndIconObj[topicid][logo.logotype] = this.globals.filesServerUrl + "/img/cloud.svg";
                } else if (type === "background") {
                  this.bgAndIconObj[topicid][logo.logotype] = this.globals.filesServerUrl + "/resources/pdm/pdm_bg.svg";
                }
              }
            );
        }
      }
    },
      err => {
        // console.log(err);
        this.error = true;
      });
  }
  getThemesNames(topicID, index) {
    this.error = false;
    // this.httpClient
    //   .get(this.globals.devDbUrl + "/getThemesByTopic/" + topicID + "/text")
    this.apiFormatter.getApiRequest(this.globals.devDbUrl + "/getThemesByTopic/" + topicID + "/text")
      .subscribe(res => {
        this.alltopics[index].themeNames = res;
      },
        err => {
          // console.log(err);
          this.error = true;
        });
  }
  getTabs(topicID) {
    this.error = false;
    this.apiFormatter
      .getApiRequest(this.globals.devDbUrl + "/getTabContent/" + topicID)
      .subscribe(res => {
        this.tabs = res;
        this.tabs.sort((tab1, tab2) => tab1.tabid - tab2.tabid);
        for (let index in this.tabs) {
          this.apiFormatter
            .getApiRequest(this.globals.devDbUrl + "/getThemes/" + this.tabs[index].tabid)
            .subscribe(res => {
              this.tabs[index].themes = res;
            });
        }
      },
        err => {
          // console.log(err);
          this.error = true;
        });
  }
  async getTopicLogoBasedOnType(logos, type, topicid) {
    if (!logos.length) {
      if (type === "white") {
        return this.globals.filesServerUrl + "/img/cloud-white.svg";
      } else if (type === "blue") {
        return this.globals.filesServerUrl + "/img/cloud.svg";
      } else if (type === "background") {
        return this.globals.filesServerUrl + "/resources/pdm/pdm_bg.svg";
      }
    }
    for (var logo of logos) {
      if (logo.logotype == type && logo.logoname.length > 0) {
        //return this.globals.imagePath + logo.name + ".svg";
        // return (
        //   this.globals.imagePath + "Topic_" + topicid + "/" + logo.logoname
        // );
        try {
          let data = await this.dataService
            .getDataFromNode(["Topic_" + topicid, logo.logoname])
            .toPromise();
          return (
            this.globals.imagePath + "Topic_" + topicid + "/" + logo.logoname
          );
        } catch (err) {
          if (type === "white") {
            return this.globals.filesServerUrl + "/img/cloud-white.svg";
          } else if (type === "blue") {
            return this.globals.filesServerUrl + "/img/cloud.svg";
          } else if (type === "background") {
            return this.globals.filesServerUrl + "/resources/pdm/pdm_bg.svg";
          }
        }
        // this.dataService
        //   .getDataFromNode(["Topic_" + topicid, logo.logoname])
        //   .subscribe(
        //     data => {
        //       return (
        //         this.globals.imagePath +
        //         "Topic_" +
        //         topicid +
        //         "/" +
        //         logo.logoname
        //       );
        //     },
        //     err => {
        //       console.log(err);
        //       return "../../../assets/img/cloud.svg";
        //     }
        //   );
      }
    }
  }
}
