import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  devDbUrl: string = "https://baristaaioncloud.com:8080/barista"; //http://54.219.187.204:8080/barista";
  //54.219.187.204
  routingUrl: string = "/";
  filesServerUrl:String = "https://baristaaioncloud.com:3000"
  imagePath: string = "https://baristaaioncloud.com:3000/getImage/";
  filesUploadServicePath: string = "https://baristaaioncloud.com:3000/api/upload/";
  tempFilesUploadServicePath: string = "https://baristaaioncloud.com:3000/uploadTempFile/";
  moveFilesFromTempToUploads: string = "https://baristaaioncloud.com:3000/moveFilesFromTempToUploads";
  deleteFilesFromTemp: string = "https://baristaaioncloud.com:3000/deleteFilesFromTemp";
  backendServicesUrl: string = "https://baristaaioncloud.com:8080/barista/";
  showPortal: boolean = true;
  userName: string;
  allowableFileSize: number = 100 * 1024 * 1024; // in bytes
  s3_key='AKIA26HQ4S6BPWXYWQCM';
  s3_s_key ='UFWmceVhdTUHYUpXg8L+6k9SFB9V5omhOx7xKFlC';
  s3_bucket='barista-container';

  // STOMP variables
  webSocketEndPointForStomp: string = "https://baristaaioncloud.com:8080/gs-guide-websocket";
  
  consoleTopicURIForStomp: string = "/topic/instance/demo";
  consoleSendURIForStomp: string = "/app/instance/demo";

  containerTopicURIForStomp: string = "/topic/instance/conf1";
  containerSendURIForStomp: string = "/app/instance/conf1";

  accessType: string = "hal";;

  industries = ['All', 'Transportation', 'Mining', 'Mobility', 'Manufacturing', 'Energy', 'Rails', 'Machinery', 'Digitalization', 'Finance', 'Operations'];
}
