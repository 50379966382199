import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomMultiselectDropdownComponent } from './custom-multiselect-dropdown.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { ListFilterPipe } from './list-filter.pipe';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [CustomMultiselectDropdownComponent, ClickOutsideDirective, ListFilterPipe],
  exports: [CustomMultiselectDropdownComponent]
})

export class CustomMultiSelectDropDownModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CustomMultiSelectDropDownModule
    };
  }
}
