import { ApiFormatterService } from "src/app/common/services/Api Formatter/api-formatter.service";
import { Solution } from "./solution";
import { FileUploadService } from "../services/fileUploadService/file-upload.service";

export class Content {
  constructor(
    private apiFormatter: ApiFormatterService,
    private uploadFileService: FileUploadService
  ) { }

  title = "New Content Tab";
  buttons = [
    {
      value: "Save",
      url: "/console/addTabContent", // /console/solution
      methodType: "PUT"
    }
  ];
  action = "INSERT";
  fields = [
    {
      type: "text",
      jsonKey: "themename",
      label: "Tab Name",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "select",
      jsonKey: "showtab",
      options: [
        { name: "Show", value: "1" },
        { name: "Hide", value: "0" }
      ],
      label: "Visibility",
      value: "1",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "",
      helpIcon: "Makes the tab visible/hidden in the portal."
    },
    {
      type: "",
      jsonKey: "logo",
      label: "",
      value: "Logo"
    },
    {
      type: "text",
      jsonKey: "author",
      label: "Author",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "select",
      jsonKey: "contenttype",
      options: [
        { name: "Multi-Card", value: "text" },
        { name: "Single-Card", value: "image" }
      ],
      label: "Type",
      value: "image",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "",
      helpIcon: "Conent tabs can display 2 types of information: single card (enables a single image views) or multi-card (enables multiple resources like demonstration)."
    },
    {
      type: "",
      jsonKey: "themeid",
      label: "",
      value: ""
    },
    {
      type: "",
      jsonKey: "tabid",
      label: "",
      value: ""
    },
    {
      type: "textarea",
      jsonKey: "themedesc",
      label: "Description",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "many",
      heading: "Resources",
      jsonKey: "resources",
      value: "",
      fields: [
        {
          type: "file",
          jsonKey: "image",
          label: "Image",
          prefix: "image",
          value: "",
          fileType: "Image",
          fileObj: "",
          uploading: false,
          required: false,
          error: false,
          errorMsg: "Required",
          pattern: ".(gif|jpg|jpeg|tiff|png|svg|GIF|JPG|JPEG|TIFF|PNG|SVG)$",
          patternErrorMsg:"Upload a valid file",
          accept: "image/*, image/svg",
          placeholder: "Choose *.png or *.jpg file",
          jsonObject: {
            resourcename: "",
            resourcedesc: "",
            resourcetype: "IMAGE",
            themeid: "",
            resourceid: ""
          },
          specialValidationNotMet: function(contentObj){
            // console.log(contentObj);
            let index: number = contentObj.fields.findIndex(field => 
              field.jsonKey == "contenttype"
            );
            // console.log(index);
            if(index >= 0){
              // console.log(contentObj.fields[index].value);
              if(contentObj.fields[index].value == "image" && !this.value)  {
                // console.log("Error");
                this.error = true;
              } else {
                this.error = false;
              }
            }
          }
        },
        {
          type: "file",
          jsonKey: "pdf",
          label: "Pdf",
          prefix: "pdf",
          value: "",
          fileType: "PDF",
          uploading: false,
          fileObj: "",
          required: false,
          error: false,
          errorMsg: "",
          pattern: ".(pdf|PDF)$",
          patternErrorMsg: "Upload a valid PDF file",
          accept: ".pdf",
          placeholder: 'Choose *.pdf file',
          jsonObject: {
            resourcename: "",
            resourcedesc: "",
            resourcetype: "PDF",
            themeid: "",
            resourceid: ""
          }
        },
        {
          type: "file",
          jsonKey: "audio",
          label: "Text-to-Speech",
          prefix: "audio",
          value: "",
          fileType: "AUDIO",
          uploading: false,
          fileObj: "",
          required: false,
          error: false,
          errorMsg: "",
          pattern: ".(txt)$",
          patternErrorMsg: "Upload a valid text file",
          accept: ".txt",
          placeholder: "Choose *.txt file",
          jsonObject: {
            resourcename: "",
            resourcedesc: "",
            resourcetype: "AUDIO",
            themeid: "",
            resourceid: ""
          },
          helpIcon: "Requires a text file which will automatically converted into audio file. (Max word limit : 500 Words)"
        },
        {
          type: "file",
          jsonKey: "video",
          label: "Video",
          prefix: "video",
          uploading: false,
          value: "",
          fileType: "Video",
          fileObj: "",
          required: false,
          error: false,
          errorMsg: "",
          pattern:
            ".(mp4|3gp|wmv|webm|avi|mkv|flv|MP4|3GP|WMV|WEBM|AVI|MKV|FLV)$",
          patternErrorMsg: "Upload a valid video file",
          accept: "video/mp4, video/x-m4v, video/*",
          placeholder: "Choose *.mp4 file",
          jsonObject: {
            resourcename: "",
            resourcedesc: "",
            resourcetype: "VIDEO",
            themeid: "",
            resourceid: ""
          }
        }
      ]
    },
    {
      type: "",
      jsonKey: "tabContent",
      value: ""
    }
  ];

  async uploadFiles(tabId) {
    let formData = new FormData();
    for (let field of this.fields) {
      if (field.jsonKey === "resources") {
        for (let file of field.fields) {
          if (file.fileObj) {
            formData.append("uploads[]", file.fileObj, file.prefix + "_" + file.fileObj["name"]);
          }
        }
        break;
      }
    }
    formData.append("name", "Tab_" + tabId);
    // formData.forEach((value, key) => {
    //   console.log(key + " " + value);
    // });
    return formData.has("uploads[]")
      ? this.uploadFileService.postFiles(formData).toPromise()
      : "";
  }

  moveFilesToUploadsAndDeleteTemp(tabid){

    let fileNames: string[] = [];

    let fileIndex = this.fields.findIndex(field => field.jsonKey == "resources");
    for(let field of this.fields[fileIndex].fields) {
      if(field.value && field.fileObj) fileNames.push(field.value);
    }
    let currentUser = this.apiFormatter.removeQuotesFromString(localStorage.getItem('currentUser').trim());
    let type = "Tab";
    

    // let formData = new FormData();
    let objectToSend = {
      files: fileNames,
      userName: currentUser,
      type: type,
      id: tabid
    }

    this.uploadFileService.moveFilesFromTempToUploads(objectToSend).subscribe(data => {
      // console.log(data);
      this.uploadFileService.deleteFilesFromTemp(objectToSend).subscribe(data => {
        // console.log(data);
      });
    });
  }

  postData(globalUrl, modal) {
    // debugger
    this.apiFormatter
      .putApiRequest(globalUrl + this.buttons[0].url, this.fields)
      .subscribe(data => {
        // console.log(data);
        this.moveFilesToUploadsAndDeleteTemp(data["tabid"]);
        // this.uploadFiles(data["tabid"]).then(data2 => {
          // console.log(data2);
          // modal.editThemeRef.loadthemes(modal.editThemeRef.selectedTopic);
        // });
        modal.editThemeRef.selectedTabId = data["tabContent"]["tabid"];
        //console.log("newtabid:", data["tabid"]);
        modal.editThemeRef.getTabs(modal.editThemeRef.selectedTopic.topicid, modal.editThemeRef.tabset);

      });
  }

  getData(globalUrl): Content {
    let content: Content = new Content(
      this.apiFormatter,
      this.uploadFileService
    );
    return content;
  }

  getPopulatdData(objectToPopulateWith: Object, globalUrl: string): Content {
    let content: Content = new Content(
      this.apiFormatter,
      this.uploadFileService
    );
    // console.log("editTab:", objectToPopulateWith);

    content.title = "Update Content Tab";
    content.buttons = [
      {
        value: "Update",
        url: "/console/editTabContent",
        methodType: "PUT"
      }
    ];
    let themeid: string = "";
    for (let field of content.fields) {
      if (field.jsonKey === "themeid")
        themeid = objectToPopulateWith[field.jsonKey];
      if (field.jsonKey === "contenttype") {
        field.type = "";
        field.value = "image";
      }
      if (field.type == "many") {
        new Solution(
          this.apiFormatter,
          this.uploadFileService
        ).findSubFieldAndAssign(
          field.fields,
          objectToPopulateWith["resources"],
          themeid
        );
      } else {
      }
      if (field.jsonKey != "contenttype")
        field.value = objectToPopulateWith[field.jsonKey];
    }
    //debugger;
    return content;
  }
}
