import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./delete-modal.component.css"]
})
export class DeleteModalComponent implements OnInit {
  @Input() modalData;
  @Input() modal;
  @Input() buttonName;
  @Output() event = new EventEmitter();

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    // console.log(this.buttonName);
    if (!this.buttonName) {
      this.buttonName = "Delete";
    }
  }
  sendModalData() {
    if(this.modalData.callbackFunc){
      this.modalData.callbackFunc.subscribe(
        data => {
          this.event.emit(data);
          console.log(data);
          this.modal.close("Close click");
          if (this.modalData.onSuccess) {
            this.modalData.onSuccess();
          }
        },
        err => {
          console.error(err);
          if (err.status === 200) {
            this.modal.close("Close click");
          }
        }
      );
    }
     
    if(this.modalData.action){
      this.modalData.action()
      this.modal.close("Close click");
    }

  }
}
