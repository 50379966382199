import { Injectable } from "@angular/core";
import { Options } from "./options";
import { ApiFormatterService } from "src/app/common/services/Api Formatter/api-formatter.service";
import { TopicListComponent } from "../topic-list/topic-list.component";
import { FileUploadService } from "src/app/console/services/fileUploadService/file-upload.service";
import { Globals } from 'src/app/config';

// @Injectable()
export class Topic {
  constructor(
    private globals: Globals,
    private apiFormatter: ApiFormatterService,
    private topicListComponent: TopicListComponent,
    private uploadFilesService: FileUploadService
  ) { }

  "title" = "New Topic";
  "buttons" = [
    {
      value: "Next",
      url: "/console/topic",
      methodType: "PUT"
    }
  ];
  "tabs" = [
    {
      tabId: "desc",
      tabName: "Description",
      error: false
    },
    {
      tabId: "conf",
      tabName: "Permissions",
      error: false
    },
    {
      tabId: "desg",
      tabName: "Design",
      error: false
    }
  ];
  "tabIndices" = {
    "desc": 0,
    "conf": 1,
    "desg": 2
  }
  "fields" = [
    {
      type: "text",
      jsonKey: "topicname",
      label: "Name",
      value: "",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "",
      tabId: "desc"
    },
    {
      type: "",
      jsonKey: "topicid",
      label: "TopicId",
      value: "",
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },

    {
      type: "",
      jsonKey: "shortdesc",
      label: "Shortdesc",
      value: "",
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "",
      jsonKey: "verticals",
      label: "Verticals",
      value: "",
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "currency",
      jsonKey: "marketvalue",
      label: "Market Value($M)",
      value: "",
      required: false,
      error: false,
      errorMsg: "Invalid value",
      pattern: "^([0-9]{1,3}(\,[0-9]{3})*|([0-9]+))(\.[0-9]{1,4})?$",
      patternErrorMsg: "Enter valid value",
      tabId: "desc",
      helpIcon: "Estimate market value of the solutions",
      handleKeyUp: undefined,
      // function() {
      //   let regex = new RegExp(this.pattern);
      //   let value = this.value.toString().replace(/[^0-9.-]+/g,"");
      //   if(regex.test(value)){
      //     let valueParts: string[] = value.split(".");
      //     for(let i = valueParts[0].length - 4; i >= 0; i -= 3) {
      //       valueParts[0] = [valueParts[0].slice(0, i + 1), valueParts[0].slice(i + 1)].join(",");
      //     }
      //     this.value = valueParts.join(".");
      //   }
      // },
      specialValidationNotMet: function (topicObj) {
        // console.log(instanceObj);
        let index: number = topicObj.fields.findIndex(field =>
          field.jsonKey == "marketvalue"
        );
        // console.log(index);
        if (index >= 0 && topicObj.fields[index].value) {
          let regex = new RegExp(topicObj.fields[index].pattern);
          if (!regex.test(topicObj.fields[index].value)) {
            topicObj.fields[index].error = true;
          }
          else {
            topicObj.fields[index].error = false;
            topicObj.fields[index].value = parseFloat(this.value.toString().replace(/[^0-9.-]+/g, ""));
          }
        }
      }
    },

    {
      type: "multiSelect",
      jsonKey: "horizantals",
      label: "Industries",
      value: [],
      selectedValues: [],
      options: [],
      settings: {
        singleSelection: false,
        idField: "value",
        textField: "name",
        selectAllText: "Select All",
        unSelectAllText: "Unselect All",
        itemsShowLimit: 3,
        allowSearchFilter: true
      },
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      tabId: "desc",
      helpIcon: "Select industries that are allowed to access this topic"
    },

    {
      type: "text",
      jsonKey: "displayorder",
      label: "Display Order",
      value: "",
      required: false,
      error: false,
      errorMsg: "",
      pattern: "^[0-9]*$",
      patternErrorMsg: "Requires a numeric value",
      tabId: "desg",
      helpIcon: "Indicates the display order in the portal."
    },
    {
      type: "",
      jsonKey: "keywords",
      label: "keywords",
      value: "",
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "textarea",
      jsonKey: "topicdesc",
      label: "Description",
      value: "",
      tabId: "desc"
    },

    {
      type: "multiSelect",
      jsonKey: "owner",
      label: "Owner",
      value: [],
      selectedValues: [],
      options: [],
      settings: {
        singleSelection: false,
        idField: "value",
        textField: "name",
        selectAllText: "Select All",
        unSelectAllText: "Unselect All",
        itemsShowLimit: 3,
        allowSearchFilter: true
      },
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "",
      tabId: "conf",
      helpIcon: "Add users who can contribute to the topic"
    },
    {
      type: "multiSelect",
      jsonKey: "contact",
      label: "Contact",
      value: [],
      selectedValues: [],
      options: [],
      settings: {
        singleSelection: true,
        idField: "value",
        textField: "name",
        itemsShowLimit: 3,
        allowSearchFilter: true
      },
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "",
      tabId: "conf",
      helpIcon: "Select contact for the topic."
    },
    {
      type: "select",
      jsonKey: "confidentiality",
      options: [
        { name: "Confidential", value: "Confidential" },
        { name: "Private", value: "Private" },
        { name: "Public", value: "Public" }
      ],
      label: "Confidentiality",
      value: "Confidential",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: "",
      helpIcon: ["Select the type of topic.", "1.Confidential is visible to R&D only.", "2.Private is to Hitachi internal and R&D.", "3.Public is visible for external users."],
      tabId: "conf"
    },
    {
      type: "multiSelect",
      jsonKey: "country",
      label: "Country",
      value: [],
      selectedValues: [],
      options: [],
      settings: {
        singleSelection: false,
        idField: "value",
        textField: "name",
        selectAllText: "Select All",
        unSelectAllText: "Unselect All",
        itemsShowLimit: 3,
        allowSearchFilter: true
      },
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      tabId: "conf",
      helpIcon: "Select countries that are allowed to access this topic"
    },
    {
      type: "multiSelect",
      jsonKey: "topicArtifacts",
      label: "Container",
      value: [],
      selectedValues: [],
      options: [],
      settings: {
        singleSelection: false,
        idField: "value",
        textField: "name",
        selectAllText: "Select All",
        unSelectAllText: "Unselect All",
        itemsShowLimit: 3,
        allowSearchFilter: true
      },
      handleDeselect: true,
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      helpIcon: "Topic requires at least one container! The topic offers demos deployed in related containers and instances.",
      onChange: this.loadInstances
    },
    {
      type: "none",
      jsonKey: "logo",
      label: "None",
      value: "logo.png",
      required: true,
      error: false,
      errorMsg: "",
      pattern: "",
      patternErrorMsg: ""
    },
    {
      type: "multiSelect",
      jsonKey: "topicInstances",
      label: "Instance",
      value: [],
      selectedValues: [],
      options: [],
      settings: {
        singleSelection: false,
        idField: "value",
        textField: "name",
        selectAllText: "Select All",
        unSelectAllText: "Unselect All",
        itemsShowLimit: 3,
        allowSearchFilter: true
      },
      handleDeselect: true,
      required: false,
      error: false,
      errorMsg: "",
      pattern: "",
      helpIcon: ''//"Topic requires atleast one container!Topic requires at least one container! The topic offers demos deployed in related containers and instances."
    },
    {
      type: "many",
      jsonKey: "logos",
      heading: "",
      value: "",
      fields: [
        {
          type: "fileWithDropdown",
          jsonKey: "logo",
          label: "Logo (Blue)",
          prefix: "blue",
          uploading: false,
          value: "",
          placeholder: "Choose or Upload a *.jpg/*.png file",
          fileType: "img",
          options: [
            { optionFilePath: "cloud.svg", optionLabel: "cloud.svg" },
            { optionFilePath: "connectedCars.svg", optionLabel: "connectedCars.svg" },
            { optionFilePath: "manufacturing_blue.svg", optionLabel: "manufacturing_blue.svg" },
            { optionFilePath: "operations_blue.svg", optionLabel: "operations_blue.svg" },
            { optionFilePath: "pdm_blue.svg", optionLabel: "pdm_blue.svg" },
          ],
          showOptions: false,
          fileObj: "",
          required: false,
          error: false,
          errorMsg: "",
          pattern: ".(gif|jpg|jpeg|tiff|png|svg|GIF|JPG|JPEG|TIFF|PNG|SVG)$",
          patternErrorMsg: "Invalid File Type",
          jsonObject: {
            logoid: "",
            logoname: "",
            logotype: "blue",
            topicid: 0
          }
        },
        {
          type: "fileWithDropdown",
          jsonKey: "icon",
          label: "Icon (White)",
          prefix: "white",
          uploading: false,
          value: "",
          placeholder: "Choose or Upload a *.jpg/*.png file",
          fileType: "img",
          fileObj: "",
          options: [
            { optionFilePath: "cloud-white.svg", optionLabel: "cloud-white.svg" },
            { optionFilePath: "connectedCars.svg", optionLabel: "connectedCars.svg" },
            { optionFilePath: "manufacturing_white.svg", optionLabel: "manufacturing_white.svg" },
            { optionFilePath: "operations_white.svg", optionLabel: "operations_white.svg" },
            { optionFilePath: "pdm_white.svg", optionLabel: "pdm_white.svg" }
          ],
          optionsBackground: "#276f9b",
          showOptions: false,
          required: false,
          error: false,
          errorMsg: "",
          pattern: ".(gif|jpg|jpeg|tiff|png|svg|GIF|JPG|JPEG|TIFF|PNG|SVG)$",
          patternErrorMsg: "Invalid File Type",
          jsonObject: {
            logoid: "",
            logoname: "",
            logotype: "white",
            topicid: 0
          }
        },
        {
          type: "fileWithDropdown",
          jsonKey: "background",
          label: "Background",
          prefix: "background",
          uploading: false,
          value: "",
          placeholder: "Choose or Upload a *.jpg/*.png file",
          fileType: "img",
          fileObj: "",
          options: [
            { optionFilePath: "fleet.png", optionLabel: "fleet.png" },
            { optionFilePath: "connectedCars.jpg", optionLabel: "connectedCars.jpg" },
            { optionFilePath: "manufacturing.png", optionLabel: "manufacturing.png" },
            { optionFilePath: "mining.png", optionLabel: "mining.png" },
            { optionFilePath: "pdm.png", optionLabel: "pdm.png" },
            { optionFilePath: "inspection.svg", optionLabel: "inspection.svg" },
            { optionFilePath: "operations.png", optionLabel: "operations.png" }
          ],
          showOptions: false,
          required: false,
          error: false,
          errorMsg: "",
          pattern: ".(gif|jpg|jpeg|tiff|png|svg|GIF|JPG|JPEG|TIFF|PNG|SVG)$",
          patternErrorMsg: "Invalid File Type",
          jsonObject: {
            logoid: "",
            logoname: "",
            logotype: "background",
            topicid: 0
          }
        }
      ],
      tabId: "desg"
    }
  ];

  async loadInstances(artifactIdList: Array<any>, globalUrl, topicRef, alreadySelectedValues = []) {
    // console.log(containerIdArr);
    let instanceIndex = topicRef.fields.findIndex(field => field.jsonKey == "topicInstances");
    if (instanceIndex < 0) return;

    topicRef.fields[instanceIndex].options = [];
    let options = [];
    await Promise.all(artifactIdList.map(async (id) => {
      // console.log(globalUrl + "/console/getInstancesByArtifact/" + id);
      let data = await topicRef.apiFormatter
        .getApiRequest(globalUrl + "/console/getRunningInstancesByArtifact/" + id)
        .toPromise();
      // console.log(data);
      data.map((ele) => {
        options.push({ "value": ele["id"], "name": ele["instancename"] });
      })
    }));
    topicRef.fields[instanceIndex].options = options;

    topicRef.fields[instanceIndex].selectedValues = [];
    let selectedOptions = []
    options.forEach(option => {
      if (alreadySelectedValues.includes(option.value))
        selectedOptions.push(option);
    });
    topicRef.fields[instanceIndex].selectedValues = selectedOptions;
    if (alreadySelectedValues.length) topicRef.fields[instanceIndex].value = [...selectedOptions];
    // console.log(topicRef);
  }

  handleMultiselectChange(globalUrl, field) {
    if (field.jsonKey == "topicArtifacts") {
      field.onChange(field.selectedValues.map(option => option.value), globalUrl, this);
    }
  }

  // postData(globalUrl, modal): void {
  //   this.apiFormatter
  //     .putApiRequest(globalUrl + this.buttons[0].url, this.fields)
  //     .subscribe(data => {
  //       console.log(data);
  //       this.consoleComponent.showAddTopic(data);
  //      // modal.close("Saved");
  //     });
  // }

  async uploadFiles(topicId) {
    let formData = new FormData();
    for (let field of this.fields) {
      if (field.jsonKey === "logos") {
        for (let file of field.fields) {
          if (file.fileObj) {
            formData.append("uploads[]", file.fileObj, file.prefix + "_" + file.fileObj["name"]);
          }
        }
        break;
      }
    }
    formData.append("name", "Topic_" + topicId);
    // formData.forEach((value, key) => {
    //   console.log(key + " " + value);
    // });
    let data1 = "";
    return formData.has("uploads[]")
      ? this.uploadFilesService.postFiles(formData).toPromise()
      : "";
    // console.log(data1);
  }

  moveFilesToUploadsAndDeleteTemp(topicid) {

    let fileNames: string[] = [];

    let fileIndex = this.fields.findIndex(field => field.jsonKey == "logos");
    for (let field of this.fields[fileIndex].fields) {
      if (field.value && field.fileObj) fileNames.push(field.value);
    }
    let currentUser = this.apiFormatter.removeQuotesFromString(localStorage.getItem('currentUser').trim());
    let type = "Topic";


    // let formData = new FormData();
    let objectToSend = {
      files: fileNames,
      userName: currentUser,
      type: type,
      id: topicid
    }

    this.uploadFilesService.moveFilesFromTempToUploads(objectToSend).subscribe(data => {
      // console.log(data);
      this.uploadFilesService.deleteFilesFromTemp(objectToSend).subscribe(data => {
        // console.log(data);
      });
    });
  }

  postData(globalUrl, modal) {
    // console.log(this);
    this.apiFormatter
      .putApiRequest(globalUrl + this.buttons[0].url, this.fields)
      .subscribe(data => {
        // console.log(data);
        // debugger;
        this.topicListComponent.showAddTopic(data);
        // this.uploadFiles(data["topicid"]).then(() => {
        this.moveFilesToUploadsAndDeleteTemp(data["topicid"]);
        let themeObj: any = {};
        for (let obj of this.fields) {
          if (obj.type == "many") {
            themeObj[obj.jsonKey] = obj.fields;
          } else themeObj[obj.jsonKey] = obj.value;
        }
        // debugger;
        // modal.editThemeRef.loadthemes(themeObj);
        // debugger;
        //modal.close("Saved");
        if (modal.editThemeRef) {
          modal.editThemeRef.getTopics(
            modal.editThemeRef.selectedTopic.topicid
          );
        }
        return true;
      });
  }

  getData(globalUrl): Topic {
    let topic: Topic = new Topic(
      this.globals,
      this.apiFormatter,
      this.topicListComponent,
      this.uploadFilesService
    );

    let currentUser: string = this.apiFormatter.removeQuotesFromString(localStorage.getItem('currentUser').trim());

    new Options(this.apiFormatter).setOptionsFromApi(
      this.globals.filesServerUrl + "/getJson/resources/industries.json",
      topic,
      "horizantals",
      null,
      "value",
      "name",
      ["Select"]
    );

    new Options(this.apiFormatter).setOptionsFromApi(globalUrl + "/console/getAvailableArtifact", topic, "topicArtifacts", null, "artifactID", "artifactName");

    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/user/getUsers",
      topic,
      "owner",
      null,
      "userName",
      "firstName",
      [currentUser]
    );
    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/user/getUsers",
      topic,
      "contact",
      null,
      "userName",
      "firstName",
      [currentUser]
      ,
      {

      }
    );
    new Options(this.apiFormatter).setOptionsFromApi(
      this.globals.filesServerUrl + "/getJson/resources/countries.json",
      topic,
      "country",
      null,
      "value",
      "name",
      ["US"]
    );
    return topic;
  }

  getPopulatdData(objectToPopulateWith: Object, globalUrl: string): Topic {
    // console.log(objectToPopulateWith);
    let topic: Topic = new Topic(
      this.globals,
      this.apiFormatter,
      this.topicListComponent,
      this.uploadFilesService
    );

    topic.title = "Update Topic";
    topic.buttons = [
      {
        value: "Update",
        //TODO: Insert upadte topic API here
        url: "/console/editTopic",
        methodType: "PUT"
      }
    ];

    let alreadySelectedValues: any[] = [];

    for (let field of topic.fields) {
      let arrayToSetAsValue: any[] = [];
      if (field.type == "multiSelect" && field.jsonKey == "topicArtifacts") {
        if (objectToPopulateWith["artifacts"] != null)
          objectToPopulateWith["artifacts"].map(artifact => {
            alreadySelectedValues.push({
              value: artifact.artifactID,
              name: artifact.artifactName
            });
            alreadySelectedValues.push(artifact.artifactID);
          });
        field.value = arrayToSetAsValue;
        continue;
      } else if (field.type === "many") {
        let logosArray: any[] = objectToPopulateWith[field.jsonKey];
        let actualLogosArray: any[] = field.fields;
        for (let i = 0; i < actualLogosArray.length; i++) {
          for (let j = 0; j < logosArray.length; j++) {
            if (
              logosArray[j]["logotype"] ===
              actualLogosArray[i]["jsonObject"]["logotype"]
            ) {
              actualLogosArray[i]["jsonObject"] = logosArray[j];
              actualLogosArray[i]["value"] = logosArray[j]["logoname"];
            }
          }
        }
      } else if (field.type != "multiSelect") {
        field.value = objectToPopulateWith[field.jsonKey];
        if (field.handleKeyUp) field.handleKeyUp();
      }
    }

    // objectToPopulateWith["topicInstances"] = [{instanceId: 158}];
    if (!(objectToPopulateWith["topicInstances"] instanceof Array)) {
      objectToPopulateWith["topicInstances"] = [];
    };

    this.loadInstances(objectToPopulateWith["topicArtifacts"].map(artifact => artifact.artifactid),
      globalUrl,
      topic,
      objectToPopulateWith["topicInstances"]);
    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/console/getAvailableArtifact",
      topic,
      "topicArtifacts",
      null,
      "artifactID",
      "artifactName",
      [],
      {
        url:
          globalUrl +
          "/console/getArtifactsByTopicID/" +
          objectToPopulateWith["topicid"],
        doNotAppendInOptions: true
      }
    );
    new Options(this.apiFormatter).setOptionsFromApi(
      this.globals.filesServerUrl + "/getJson/resources/countries.json",
      topic,
      "country",
      null,
      "value",
      "name",
      objectToPopulateWith["country"]
    );
    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/user/getUsers",
      topic,
      "owner",
      null,
      "userName",
      "firstName",
      [],
      {
        url: globalUrl + "/console/getTopicOwners/" +
          objectToPopulateWith["topicid"],
        doNotAppendInOptions: true,
        objectFieldToPopulateOptionName: "firstName",
        objectFieldToPopulateOptionValue: "userName"
      }
    );
    new Options(this.apiFormatter).setOptionsFromApi(
      globalUrl + "/user/getUsers",
      topic,
      "contact",
      null,
      "userName",
      "firstName",
      [objectToPopulateWith["contactName"]],
      {

      }
    );



    new Options(this.apiFormatter).setOptionsFromApi(
      this.globals.filesServerUrl + "/getJson/resources/industries.json",
      topic,
      "horizantals",
      null,
      "value",
      "name",
      objectToPopulateWith["horizantals"]
    );


    // console.log(topic);
    return topic;
  }
  async isTopicNameDuplicate(globalUrl, name) {
    let result = await this.apiFormatter.getApiRequest(globalUrl + "/console/isTopicNameDuplicate/" + name).toPromise();
    return result;
  }
}
