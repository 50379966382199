import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bw-loader',
  templateUrl: './bw-loader.component.html',
  styleUrls: ['./bw-loader.component.css']
})
export class BwLoaderComponent implements OnInit {

  @Input() colorCode: string;
  // arrayOfBars: number[] = [0,0,0,0,0,0,0];

  constructor() { }

  ngOnInit() {
    // if(this.numberOfBars) {
    //   this.arrayOfBars = [];
    //   for(let i = 0; i < this.numberOfBars; i++) this.arrayOfBars.push(0);
    // }
  }

}
