import { Component, OnInit } from "@angular/core";
import { Globals } from "../config";
import {
  Router,
  ActivatedRoute,
  NavigationExtras
} from "../../../node_modules/@angular/router";
import { UserInfoService } from '../common/services/user-info/user-info.service';
import { AppComponent } from '../app.component';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  providers: [Globals]
})
export class HeaderComponent implements OnInit {
  username: string;
  view: string = "none";
  buttonName: string = "";
  consoleAccess = "";
  accessType = "";
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private globals: Globals,
    private userInfoService: UserInfoService,
    private appComponentRef: AppComponent
  ) {
    this.username = localStorage.getItem('currentUser');

    // console.log(this.route);
    // this.route.queryParams.subscribe(params => {
    //   this.username = params["username"];
    // });
  }

  ngOnInit() {

    this.accessType = this.globals.accessType;
    this.consoleAccess = localStorage.getItem("consoleAccess");
    if (localStorage.getItem('currentUser') && this.consoleAccess.length > 0) {

    } else {
      this.router.navigate([""]);
    }
    if (this.route.component.toString().includes("TopicComponent") || this.consoleAccess === "false") {
      this.view = "Portal";
    } else if (this.route.component.toString().includes("ConsoleComponent") ||
      this.route.component.toString().includes("TopicListComponent") ||
      this.route.component.toString().includes("ContainerListComponent") ||
      this.route.component.toString().includes("ApprovalComponent")) {
      this.view = "Console";
    }
    // if (this.route.component.toString().includes("ApprovalComponent")) {
    //   this.buttonName = "Goto Home";
    // } else {
    //   this.buttonName = "Barista Users";
    // }
  }

  ngOnChange() {
    this.route.queryParams.subscribe(params => {
      this.username = params["username"];
    });
  }
  onLogout() {
    localStorage.removeItem("userRole");
    this.username = "";
    localStorage.removeItem('currentUser');
    localStorage.removeItem('consoleAccess');
    this.router.navigate([""]);
    this.appComponentRef.stop();
  }

  // gotoApproval() {
  //   // let navigationExtras: NavigationExtras = {
  //   //   queryParams: {
  //   //     username: this.username
  //   //   }
  //   // };
  //   let navigateUrl = "";
  //   this.buttonName === "Barista Users"
  //     ? (navigateUrl = "approval")
  //     : (navigateUrl = "topic");
  //   this.router.navigate([navigateUrl]);
  // }

  changeView() {
    // debugger;
    // this.route.queryParams.subscribe(params => {
    //   this.username = params["username"];
    // });
    // let navigationExtras: NavigationExtras = {
    //   queryParams: {
    //     username: this.username
    //   }
    // };
    if (!this.route.component.toString().includes("TopicComponent")) {
      this.router.navigate(["topic"]);
    } else {
      this.router.navigate(["console"]);
    }
  }
}
