import { Component, OnInit, Input, PipeTransform, Pipe, HostListener } from "@angular/core";
import { Globals } from "../../config";
import { TopicComponent } from "../topic.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import {
  Router,
  NavigationExtras
} from "../../../../node_modules/@angular/router";
import { FileUploadService } from "src/app/console/services/fileUploadService/file-upload.service";
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { DataService } from 'src/app/register/services/data.service';
import { SpeechService } from 'src/app/common/services/speech-service/speech.service';
import { ApiFormatterService } from 'src/app/common/services/Api Formatter/api-formatter.service';
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: "app-themes",
  templateUrl: "./themes.component.html",
  styleUrls: ["./themes.component.css"],
  providers: [Globals, TopicComponent]
})
export class ThemesComponent implements OnInit {
  constructor(
    private globals: Globals,
    private topicComponent: TopicComponent,
    private router: Router,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private dataService: DataService,
    private speechService: SpeechService,
    private apiFormatter: ApiFormatterService
  ) {
    this.topicRoute = this.globals.routingUrl;
  }

  // @Input() themes: any;
  @Input() topicName: any;
  @Input() topicDescription: any;
  @Input() topics: any;
  selectedTopicID: any;
  @Input() topicLogo: any;
  @Input() username: string;
  topicRoute: string;
  @Input() tabs: any;
  resourceFound: any;
  @Input() selectedTopic: any;
  showMenu: boolean = false;
  currentUser: string = undefined


  ngOnInit() {
    this.speechService.initializeSpeech();
    this.currentUser = this.apiFormatter.removeQuotesFromString(localStorage.getItem('currentUser').trim());
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.cancelAudioPlay();
  }

  @HostListener('window:beforeunload')
  doBeforeUnload() {
    this.cancelAudioPlay();
  }



  async loadthemes(topic) {
    // debugger;
    this.cancelAudioPlay();
    this.selectedTopic = topic;
    this.topicName = topic.topicname;
    this.topicDescription = topic.topicdesc;
    this.selectedTopicID = topic.topicid;
    this.getTabs(topic.topicid);
    this.topicLogo = await this.topicComponent.getTopicLogoBasedOnType(
      topic.logos,
      "blue",
      topic.topicid
    );
    // console.log(topic.topicid, this.topicLogo);
  }

  toggleMenu(): void {
    // console.log(this.(showMenu);
    this.cancelAudioPlay();
    this.showMenu = !this.showMenu;
  }

  themeClicked(event, resources) {
    let urlFound = false;
    for (var resource of resources) {
      if (resource.resourcetype === 'URL' && resource.resourcename.length > 0) {
        urlFound = true;
      }
    }
    if (!urlFound) {
      event.preventDefault();
    }
    event.stopPropagation();
  }

  getTabs(topicID) {
    this.apiFormatter
      .getApiRequest(this.globals.devDbUrl + "/getTabContent/" + topicID)
      .subscribe(res => {
        this.tabs = res;
        this.tabs.sort((tab1, tab2) => tab1.tabid - tab2.tabid);
        for (let index in this.tabs) {
          this.apiFormatter
            .getApiRequest(this.globals.devDbUrl + "/getThemes/" + this.tabs[index].tabid)
            .subscribe(res => {
              this.tabs[index].themes = res;
            });
        }
      });
  }
  getLogoName(logoName, id) {
    if (logoName)
      return this.globals.imagePath + "Theme_" + id + "/" + logoName.trim();
    else return this.globals.filesServerUrl + "/resources/eqpmtMonitor.svg";
  }

  getUrlBasedOnType(resources, type) {
    for (var resource of resources) {
      if (resource.resourcetype == type) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
          this.globals.imagePath + resource.resourcename
        );
        // return this.globals.imagePath+ resource.resourcename;
      }
    }
    return "";
  }


  // AUDIO functionality from here

  audioStatus: number = 0;
  // Values of Audio status: -1 -> Paused, 0 -> Stopped, 1 -> Playing

  toggleAudioPlay() {
    let speech = this.speechService.getSpeech();
    if (speech && speech.speaking() && this.audioStatus == 1) {
      speech.pause();
      this.audioStatus = -1;
    }
    else if (speech && speech.paused() && this.audioStatus == -1) {
      speech.resume();
      this.audioStatus = 1;
    }
  }

  cancelAudioPlay() {
    let speech = this.speechService.getSpeech();
    if (speech) {
      speech.cancel();
    }
    this.audioStatus = 0;
  }

  async playAudioResource(p, id, resources, type, themeOrTab) {

    // console.log(this.audioStatus, this.speechService.getSpeech().paused())

    if (this.audioStatus == 1 || this.audioStatus == -1) {
      this.cancelAudioPlay();
      return;
    }
    let data = await this.getResourceData(id, resources, type, themeOrTab);
    if (this.speechService.getSpeech()) {
      // console.log("Start speaking")
      this.speechService.speak(data, p, this);
      this.audioStatus = 1;
    }
    else console.error("Speech service not available!");
  }

  async getResourceData(id, resources, type, themeOrTab) {
    let fileName = "";
    if (themeOrTab === "theme") {
      fileName = "Theme_" + id;
    } else {
      fileName = "Tab_" + id;
    }
    try {
      for (let resource of resources) {
        if (type != "URL" && resource.resourcetype == type) {
          // console.log([fileName, resource.resourcename]);
          let data = await this.dataService.getDataFromNode([fileName, resource.resourcename]).toPromise();
          // console.log(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
      return "";
    }
  }


  // TILL HERE


  getResourceBasedOnType(id, resources, type, themeOrTab, x?: any) {

    // console.log(x);
    let fileName = "";
    if (themeOrTab === "theme") {
      fileName = "Theme_" + id;
    } else {
      fileName = "Tab_" + id;
    }
    for (var resource of resources) {
      if (resource.resourcetype == type) {
        if (type === "URL") {
          let urlResource: string = resource.resourcename
          if (this.currentUser) urlResource += (urlResource.includes('?') ? '&' : '?') + 'name=' + this.username
          return urlResource
        }

        return this.globals.imagePath + fileName + "/" + resource.resourcename;
      }
    }
    // console.log(this.sanitizer.bypassSecurityTrustResourceUrl(""));
    return "";
  }

  // getResourceBasedOnType(resources, type) {
  //   for (var resource of resources) {
  //     if (resource.resourcetype == type) {
  //       if (type === "URL") {
  //         return resource.resourcename;
  //       }
  //       return this.sanitizer.bypassSecurityTrustResourceUrl(
  //         this.globals.imagePath + resource.resourcename
  //       );
  //     }
  //   }
  //   return this.sanitizer.bypassSecurityTrustResourceUrl("");
  // }
  getResourceNameOnly(resources, type) {
    for (var resource of resources) {
      if (resource.resourcetype == type && resource.resourcename.length > 0) {
        return resource.resourcename;
      }
    }
    return "";
  }
  isResource(resources, type) {
    for (var resource of resources) {
      if (resource.resourcetype == type && resource.resourcename.length > 0) {
        return true;
      }
    }
    // console.log(this.sanitizer.bypassSecurityTrustResourceUrl(""));
    return false;
  }
  goToHome() {
    this.topicRoute = this.topicRoute + "topic";
  }
  /* PDF Viewer*/

  openVerticallyCentered(content, $event) {
    this.modalService.open(content, { centered: true, windowClass: "pdf-and-video-modal" });
  }

  trackByFunction(item, index) {
    if (!item) {
      return null;
    }
    return index;
  }
}
