import { Component, OnInit,AfterViewInit, Output, EventEmitter } from "@angular/core";
import { Globals } from "../config";
import { UserIdleService } from "angular-user-idle";
import {
  ActivatedRoute,
  Router,
  NavigationExtras
} from "../../../node_modules/@angular/router";
import { UserInfoService } from '../common/services/user-info/user-info.service';
import { ApiFormatterService } from '../common/services/Api Formatter/api-formatter.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [Globals]
})

export class LoginComponent implements OnInit,AfterViewInit {
  constructor(
    private globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private userIdle: UserIdleService,
    private userInfoService: UserInfoService,
    private apiFormatter: ApiFormatterService
  ) { }
  password: string = "";
  userName: string = "";
  errorMessage: string = "";
  user = {userName:null, password:null};
  //internal vars for autologin
  redirectedUser: string ="";
  redirectedPass: string ="";
  
  @Output() login = new EventEmitter<string>();

  submit() {
    // console.log(this.userName);
    this.user = { userName: this.userName, password: this.password };
    this.checkValidUser();
  }

  checkValidUser() {
    this.apiFormatter.postApiRequest(this.globals.devDbUrl + "/login", this.user)
      .subscribe(res => {
        if (res) {
          console.log("found res user:");
          console.log(res);
          this.login.emit(this.user.userName);
          //debugger;
          if (res['role'] && (res['role'].rolename.toLowerCase() === 'admin' || res['role'].rolename.toLowerCase() === 'presenter' || res['role'].rolename.toLowerCase() === 'contributor' )) {
            localStorage.setItem('consoleAccess', 'true');
          } else {
            localStorage.setItem('consoleAccess', 'false');
          }

          // Saving role of user
          if(res['role'] && res['role']['roleID'])
            localStorage.setItem('userRole', res['role']['roleID']);

          localStorage.setItem('currentUser', JSON.stringify(this.user.userName));
          this.userIdle.resetTimer();
          this.router.navigate(["topic"]);
        } else {
          this.errorMessage = "Enter Valid Credentials!";
        }
      },
      error => {
        this.errorMessage = "Enter Valid Credentials!"
      });
  }

  ngOnInit() {

    localStorage.removeItem("consoleAccess");
    localStorage.removeItem("currentUser");

    this.route.queryParams.subscribe(params => {
	console.log("param=",params)
     if (params.name && params.val){
         this.redirectedPass = atob(params.val);
         this.redirectedUser = atob(params.name) +"@aiportal.hitachi.com"; 
         console.log("auto-login for:"+atob(params.name));
         console.log("p="+this.password); 
     }
    });

  }

  ngAfterViewInit(): void {
    this.user = { userName: this.redirectedUser, password: this.redirectedPass };
    console.log("go to chekin:");
    console.log(this.user);
    if (this.redirectedUser != '' && this.redirectedPass !=''){
         console.log("Redirecting as:"+this.user.userName);	
	console.log("Redirecting pass:"+this.user.password);
        this.checkValidUser();
    }
  }



  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }
}
