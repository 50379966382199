import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppComponent } from "./app.component";

import { TopicComponent } from "./topic/topic.component";
import { ThemesComponent, SafePipe } from "./topic/themes/themes.component";
import { HttpClientModule } from "@angular/common/http";

import { LoginComponent } from "./login/login.component";
import { ConsoleComponent } from "./console/console.component";
import { HeaderComponent } from "./header/header.component";
import { SideContentComponent } from "./side-content/side-content.component";
import { CustomModalComponent } from "./console/custom-modal/custom-modal.component";
import { RegisterComponent } from "./register/register.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { EditThemeComponent } from "./console/edit-theme/edit-theme.component";
import { ApprovalComponent } from "./approval/approval.component";
import { DeleteModalComponent } from "./delete-modal/delete-modal.component";
import { ContainerComponent } from "./container/container.component";

import { NgxLoadingModule } from "ngx-loading";
import { Globals } from "./config";
import { ConsoleMenuComponent } from './console-menu/console-menu.component';
import { TopicListComponent } from './console/topic-list/topic-list.component';
import { ContainerListComponent } from './console/container-list/container-list.component';
import { CustomMultiSelectDropDownModule } from './custom-multiselect-dropdown/custom-multiselect-dropdown.module';
import { UserIdleModule } from 'angular-user-idle';
import { ErrorPageComponent } from './error-page/error-page.component';
import { BwLoaderComponent } from './bw-loader/bw-loader.component';


@NgModule({
  declarations: [
    AppComponent,
    TopicComponent,
    ThemesComponent,
    LoginComponent,
    ConsoleComponent,
    HeaderComponent,
    SideContentComponent,
    CustomModalComponent,
    RegisterComponent,
    EditThemeComponent,
    ApprovalComponent,
    DeleteModalComponent,
    ContainerComponent,
    ConsoleMenuComponent,
    TopicListComponent,
    ContainerListComponent,
    SafePipe,
    ErrorPageComponent,
    BwLoaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    NgxLoadingModule.forRoot({}),
    CustomMultiSelectDropDownModule.forRoot(),
    UserIdleModule.forRoot({ idle: 10, timeout: 1800, ping: 15 })
  ],
  providers: [Globals, ConsoleComponent],

  bootstrap: [AppComponent]
})
export class AppModule { }
