import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  userData = {}

  constructor() { }

  addUserInfo(key: string, value: any) {
    this.userData[key] = value;
  }

  getUserInfo(key: string) {
    return this.userData[key];
  }

  deleteUserInfo(key: string) {
    delete this.userData[key];
  }

}
