import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import * as _ from 'lodash';

@Injectable({
  providedIn: "root"
})
export class ApiFormatterService {
  constructor(private httpClient: HttpClient) { }

  getPUTJsonFormat(contextObjArray: any[]): any {
    // debugger;
    var jsonObject: any = {};

    for (let field of contextObjArray) {
      // let jsonSubObjectArray: any[] = [];
      // if (field.type === "many") {
      //   for (let subField of field.fields) {
      //     if (
      //       !subField.value ||
      //       subField.value.length < 1 ||
      //       subField.value == ""
      //     )
      //       continue;
      //     subField.jsonObject.resourcename = subField.value;
      //     jsonSubObjectArray.push(subField.jsonObject);
      //   }
      //   jsonObject[field.jsonKey] = jsonSubObjectArray;
      // } else {
      if (field.jsonKey == "horizantals") {
        const values = _.map(field.selectedValues, 'name');
        jsonObject[field.jsonKey] = values.join(",");

      } else if (field.jsonKey == "contact") {
        const values = _.map(field.selectedValues, 'value');
        jsonObject["contactName"] = values.join(",");
      } else {
        jsonObject[field.jsonKey] = field.value;
      }
      // }
      // console.log(jsonObject);
    }

    return JSON.stringify(jsonObject);
  }

  putApiRequest(url, contextObjArrayData: any[]) {
    //  debugger;
    let contextObjArray: any[] = contextObjArrayData;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    let jsonToSend: any = this.getPUTJsonFormat(contextObjArray);
    //console.log("endpoint:", jsonToSend);
    if (!jsonToSend) return;
    // console.log(jsonToSend);
    //this.getPUTJsonFormat(contextObjArray);
    // debugger;
    return this.httpClient.put(url, jsonToSend, httpOptions);
  }


  putApiRequestWithRawData(url, jsonToSend: any) {
    // debugger;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    //console.log("endpoint:", jsonToSend);
    if (!jsonToSend) return;
    return this.httpClient.put(url, jsonToSend, httpOptions);
  }

  getApiRequest(url: string): Observable<any> {
    return this.httpClient.get<any>(url);
  }

  postApiRequest(url: string, objectToSend: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    //console.log("endpoint:", jsonToSend);
    let jsonToSend = JSON.stringify(objectToSend);
    if (!jsonToSend) return;
    return this.httpClient.post(url, jsonToSend, httpOptions);
  }

  copyMultiselectValuesToSelectedValues(field) {
    // console.log(field.selectedValues, field.value, );
    if (field.type == "multiSelect" && field.value instanceof Array && field.selectedValues) {
      field.selectedValues = [];
      field.value.forEach(val => {
        field.selectedValues.push({ ...val });
      });
      // console.log("Values Set!", field.selectedValues);
    }
  }


  removeQuotesFromString(str: string): string {
    let currentString: string = "";
    for (let i = 0; i < str.length; i++) {
      if (i == 0 || i == str.length - 1) {
        if (str.charAt(i) == "\"" || str.charAt(i) == "'")
          continue;
      }
      currentString += str.charAt(i).toString();
    }
    return currentString;
  }
}
