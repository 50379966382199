import { Component, OnInit, Output, OnDestroy } from "@angular/core";
import { Globals } from "../config";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Container } from "./custom-modal/container";
import { Topic } from "./custom-modal/topic";
import { Instance } from "./custom-modal/instance";
import { ApiFormatterService } from "../common/services/Api Formatter/api-formatter.service";
import { ActivatedRoute, Router } from "../../../node_modules/@angular/router";
import { DataService } from "../register/services/data.service";
import { FileUploadService } from "./services/fileUploadService/file-upload.service";
import { WebSocketAPI } from "./WebSocketAPI";
import { UserInfoService } from '../common/services/user-info/user-info.service';

@Component({
  selector: "app-console",
  templateUrl: "./console.component.html",
  styleUrls: ["./console.component.css"],
  providers: [Globals]
})
export class ConsoleComponent implements OnInit, OnDestroy {
  availableContainers: any;
  topicsAndContainers: any;
  messageFromStomp: string = "";
  constructor(
    // private httpClient: HttpClient,
    private globals: Globals,
    private modalService: NgbModal,
    private apiFormatterService: ApiFormatterService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private uploadFilesService: FileUploadService,
    private userInfoService: UserInfoService
  ) {
    //this.getContainers();

    this.showAddTopicFlag = false;
  }
  selectedMenu: string = "topics-list";
  username: string;
  index: number;
  topics: any;
  loading: boolean = false;
  showTopicList = true;
  showContainerList = true;
  showMenu: boolean = false;
  webSocketApi: WebSocketAPI;
  topicStompConnections = {};
  webSocketEndPointForStomp: string = this.globals.webSocketEndPointForStomp;
  topicURIForStomp: string = this.globals.consoleTopicURIForStomp;
  sendURIForStomp: string = this.globals.consoleSendURIForStomp;
  userRole: string = undefined;
  enableAction: boolean = true;

  currentTab = "topic";
  // instanceStatus: Array<String> = [];
  // topicStatusCode: Array<number> = [];

  tabs: Array<any> = [
    {
      name: "Topic Status",
      key: "topic"
    },
    {
      name: "Containers Only",
      key: "container"
    }
  ];

  tabChange(event) {
    this.currentTab = event.nextId;
  }

  // FOR STORING INSTANCE STATUS FOR TOPICS
  // instanceStatus = {};
  // topicStatusCode = {};

  // FOR STORING INSTANCE STATUS FOR CONTAINERS
  // containerInstanceStatus = {};
  // containerStatusCode = {};

  instanceStatus = {}
  instanceStatusCode = {}

  // topicApi: Object = {
  //   "Manufacturing": "manufacturing",
  //   "operations": "operations",
  //   "Predictive Maintenance": "pdm",
  //   "mining": "mining"
  // };

  ngOnInit() {
    this.selectedMenu = "topics-list";
    this.username = localStorage.getItem("currentUser");
    if (this.username && localStorage.getItem("consoleAccess") === "true") {
      this.getTopicsAndContainers();
      // this.getTopics();
    } else {
      this.router.navigate([""]);
    }
    // this.getTopics();
    // this.route.queryParams.subscribe(params => {
    //   this.username = params["username"];
    // });
    this.webSocketApi = new WebSocketAPI(this.webSocketEndPointForStomp, this.topicURIForStomp, this.sendURIForStomp, this);
    this.webSocketApi._connect();
    this.userRole = localStorage.getItem("userRole");
  }


  ngOnDestroy() {
    if (this.webSocketApi) this.webSocketApi._disconnect();
  }

  toggleMenu(): void {
    // console.log(this.showMenu);
    this.showMenu = !this.showMenu;
  }

  setShowContainerList(ref, flag: boolean) {
    ref.showContainerList = flag;
  }

  getShowContainerList(ref): boolean {
    return ref.showContainerList;
  }

  getRef() {
    return this;
  }

  changeMenu(selectedMenu) {
    if (selectedMenu === "containers-list") {
      this.setShowContainerList(this, false);
    }
    this.selectedMenu = selectedMenu;
    if (selectedMenu === "topics-list") {
      this.showAddTopicFlag = false;
      this.showTopicList = true;
    }
    if (this.selectedMenu === "home") {
      this.getTopicsAndContainers();
    }
    this.showMenu = false;
  }
  // getTopics() {
  //   this.loading = true;
  //   this.httpClient.get(this.globals.devDbUrl + "/getTopics").subscribe(res => {
  //     this.loading = false;
  //     this.topics = res;
  //     this.index = -1;
  //     for (const topic of this.topics) {
  //       this.index++;
  //       this.getThemesNames(topic.topicid, this.index);
  //     }
  //   });
  // }
  // getThemesNames(topicID, index) {
  //   this.httpClient
  //     .get(this.globals.devDbUrl + "/getThemesByTopic/" + topicID + "/text")
  //     .subscribe(res => {
  //       this.topics[index].themeNames = res;
  //     });
  // }
  items: Object;
  title = "";
  createTopic: any;
  instanceModalContent: any;
  modalDataForInstance: Object;
  showAddTopicFlag: boolean;
  deleteObj: object;

  // formatDate(date): string {
  //   var d = new Date(date),
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-");
  // }

  // dataFromInstanceModal(event: any, modal): void {
  //   event.fields.push({
  //     jsonKey: "createdOn",
  //     value: this.formatDate(new Date())
  //   });
  //   console.log(event);

  //   event.postData(this.globals.devDbUrl, modal);
  //   modal.close('Saved');
  // }

  getFieldIndexByJsonKey(fields: any[], jsonKey: string): number {
    return fields.findIndex(field => field.jsonKey == jsonKey);
  }

  findFieldAndAssign(fields: any[], arrayToPopulateWith: any[]) {
    fields.map(field => {
      let index: number = arrayToPopulateWith.findIndex(object => {
        if (field.jsonKey == object.jsonKey) return true;
      });
      if (index >= 0) field.value = arrayToPopulateWith[index].value;
    });
  }

  setDynamicDataForModal(event: any): any {
    if (!(event instanceof Topic)) return;
    event = <Topic>event;
    let arrayToPopulateWith: any[] = [];
    let index = this.getFieldIndexByJsonKey(event.fields, "topicArtifacts");
    if (index >= 0) {
      let arrayToSetAsValue: any[] = [];
      event.fields[index].selectedValues.map(value => {
        // let dataFromApiIndex = event.fields[index]["dataFromApi"].findIndex(
        //   dataObject => dataObject.artifactID == value.value
        // );
        arrayToSetAsValue.push({
          taid: "",
          artifactid: value.value,
          topicid: ""
        });
      });
      arrayToPopulateWith.push({
        jsonKey: "topicArtifacts",
        value: arrayToSetAsValue
      });
    }
    let arrayForLogos: any[] = [];
    index = this.getFieldIndexByJsonKey(event.fields, "logos");
    if (index >= 0) {
      let field = event.fields[index];
      field.fields.map(subField => {
        if (subField.value && subField.value.length >= 0) {
          subField.jsonObject.logoname = subField.value;
          arrayForLogos.push(subField.jsonObject);
        }
      });
    }
    //console.log(arrayForLogos);
    arrayToPopulateWith.push({
      jsonKey: "logos",
      value: arrayForLogos
    });
    //console.log(arrayToPopulateWith);
    this.findFieldAndAssign(event.fields, arrayToPopulateWith);
  }

  dataFromModal(event, modal): void {
    this.setDynamicDataForModal(event);
    // console.log(event);
    event.postData(this.globals.devDbUrl, modal);
    modal.close("Done");
    this.getTopicsAndContainers();
  }

  selectData(selector) {
    if (selector === "container") {
      this.items = new Container(this.apiFormatterService).getData(
        this.globals.devDbUrl

      );
    } else if (selector === "topic") {
      // this.items = new Topic(
      //   this.apiFormatterService,
      //   this,
      //   this.uploadFilesService
      // ).getData(this.globals.devDbUrl);
    }
  }

  setContentForInstance(content): void {
    this.instanceModalContent = content;
  }

  editContainer(content, objectToPopulateWith) {
    this.items = new Container(this.apiFormatterService).getPopulatdData(
      objectToPopulateWith,
      this.globals.devDbUrl
    );

    this.openWindowCustomClass(content);
  }

  // getContainers() {
  //   this.httpClient
  //     .get(this.globals.devDbUrl + "/console/getDemoContainer")
  //     .subscribe(res => {
  //       this.allContainers = res;
  //       console.log("Containers:", this.allContainers);
  //     });
  // }

  formatStompMessage(startOrStopMessage, instanceObj): any {
    let messageObj = {};
    messageObj["startOrStop"] = startOrStopMessage;
    messageObj["topicName"] = 'name';
    // let listOfInstances: Array<any> = [];
    // for(let aritfact of topic.artifacts) {
    //   if(aritfact["instances"]){
    //     aritfact.instances.map( instance => {
    //       if(instance.ipaddress == instanceIP)
    //       listOfInstances.push(instance);
    //     });
    //   }
    // }
    // console.log(listOfInstances);
    messageObj["listOfInstances"] = [instanceObj];
    return messageObj;
  }

  startStomp(type, object, instanceObj) {
    if (this.instanceStatusCode[instanceObj.ipaddress] == 0) {
      // console.log(this.formatStompMessage("start", topic, instanceObj.ipaddress));
      this.webSocketApi._send(this.formatStompMessage("start", instanceObj));
      // this.webSocketApi._send(this.formatStompMessage("start", topic));
    }
    // else if (this.instanceStatusCode[instanceObj.ipaddress] == 0) {
    //   this.webSocketApi._send(this.formatStompMessage("start", instanceObj));
    // }
    // console.log(this.topicStatusCode);
  }

  stopStomp(type, object, instanceObj) {
    if (this.instanceStatusCode[instanceObj.ipaddress] == 1) {
      // console.log(this.formatStompMessage("start", topic, instanceObj.ipaddress));
      this.webSocketApi._send(this.formatStompMessage("stop", instanceObj));
      // this.webSocketApi._send(this.formatStompMessage("stop", topic));
    }
    // else if (this.instanceStatusCode[instanceObj.ipaddress] == 1) {
    //   this.webSocketApi._send(this.formatStompMessage("stop", instanceObj));
    // }
    // console.log(this.topicStatusCode);
  }

  async checkStomp(type, object, instanceObj) {
    if(this.instanceStatusCode[instanceObj.ipaddress] != -1) {
      this.enableAction = false;
      this.webSocketApi._send(this.formatStompMessage("check", instanceObj));
      let instanceObject = await this.getLatestInstanceDataByName(instanceObj.instancename);
      this.updateCurrentInstance(instanceObj.ipaddress, instanceObject);
    }
  }

  
  getAvailableContainers() {
    this.loading = true;
    let currentUser = this.apiFormatterService.removeQuotesFromString(localStorage.getItem('currentUser').trim());
    this.apiFormatterService.getApiRequest(this.globals.devDbUrl + "/console/getContainerList/" + currentUser)
    .subscribe(res => {
        let filteredres = res.filter(function (e) {
          if (e.artifact.instances && e.artifact.instances.length > 0) {
            e.artifact.instances = e.artifact.instances.filter(function (instance) {
              if (instance.topicids.length <= 0) {
                return instance;
              }
            })
          }
          if (e.artifact.instances && e.artifact.instances.length > 0) {
            return e;
          }
        })
        this.availableContainers = filteredres;
        this.loading = false;
        
        let count = 0
        // this.containerInstanceStatus = {};
        for (const container of this.availableContainers) {
          let artifact = container.artifact;
          count++;
          // artifact.rowspan = 0;
          artifact.rowspan = artifact.instances && artifact.instances.length ? artifact.instances.length : 0;
          artifact.show = true;
          // this.containerInstanceStatus[artifact.artifactID] = {};
          if (artifact.instances instanceof Array) {
            artifact.instances.forEach(instance => {
              this.instanceStatus[instance.ipaddress] = instance.status;
            });
          }
          // this.setStatusCode(artifact.artifactID, this.containerInstanceStatus, this.containerStatusCode);
          this.setStatusCode(this.instanceStatus, this.instanceStatusCode);
        }
      },
      err => {
          console.error(err);
          this.loading = false;
        });
      }

      getTopicsAndContainers() {
        this.loading = true;
        let currentUser = this.apiFormatterService.removeQuotesFromString(localStorage.getItem('currentUser').trim());
    // console.log(this.globals.devDbUrl + "/console/getTopicContainerList/" + currentUser);
    // this.httpClient
    //   .get(this.globals.devDbUrl + "/console/getConsoleContainer/" + currentUser)
    // .get(this.globals.devDbUrl + "/console/getTopicContainerList/" + currentUser)
    this.apiFormatterService.getApiRequest(this.globals.devDbUrl + "/console/getConsoleContainer/" + currentUser)
    .subscribe(res => {
      // console.log(res);
        this.loading = false;
        let filteredres = res.filter(function (topic) {
          if (topic.artifacts && topic.artifacts.length > 0) {
            topic.artifacts = topic.artifacts.filter(function (artifact) {
              if (artifact.instances && artifact.instances.length > 0) {
                artifact.instances = artifact.instances.filter(function (instance) {
                  if (instance.topicids.indexOf(topic.topicid) >= 0) {
                    return instance;
                  }
                })
              }
              if (artifact.instances && artifact.instances.length > 0) {
                return artifact;
              }
            })
            
          }
          if (topic.artifacts && topic.artifacts.length > 0)
          return topic;
        })
        this.topicsAndContainers = filteredres;
        var index = -1;
        // console.log(this.topicsAndContainers);
        let i = 0;
        this.instanceStatus = {};
        for (const topic of this.topicsAndContainers) {
          let rowspan = 0,
          count = -1;
          index++;
          // if (!this.instanceStatus[topic.topicname])
          //   this.instanceStatus[topic.topicname] = {};
          for (const artifact of topic.artifacts) {
            // debugger;
            count++;
            this.topicsAndContainers[index].artifacts[count].rowspan =
              artifact.instances ? artifact.instances.length : 0;
              rowspan = rowspan + (artifact.instances && artifact.instances.length > 1 ? artifact.instances.length : 1);
              if (artifact.instances) {
                artifact.instances.map(instance => {
                  this.instanceStatus[instance.ipaddress] = instance.status;
                });
              }
            }
            this.topicsAndContainers[index].rowspan = rowspan;
            i++;
            // this.setTopicStatusCode(topic.topicname);
            // this.setStatusCode(topic.topicname, this.instanceStatus, this.topicStatusCode);
            this.setStatusCode(this.instanceStatus, this.instanceStatusCode);
          }
          // console.log(this.instanceStatus, this.topicStatusCode);
        // this.setStatusOfUnavailableInstances();
        // console.log("topicsAndContainers:", this.topicsAndContainers);
        this.getAvailableContainers();
      },
      err => {
        // console.log(err);
        // this.router.navigate(["error"]);
        this.selectedMenu = 'error-page'
      });
      // console.log(this.instanceStatus);
    }

    // setTopicStatusCode(topicname){
      //   // console.log(name);
      //   let status: string = "";
      //   if(!this.topicStatusCode[topicname]) this.topicStatusCode[topicname] = {};

      //   for(let key of Object.keys(this.instanceStatus[topicname])){
        //     status = this.instanceStatus[topicname][key];
  //     // console.log(this.topicStatusCode[topicname], this.topicStatusCode[topicname][key]);
  //     this.topicStatusCode[topicname][key] = "";
  //     if(status == "NEW" || status.includes("STOPPED") || status.includes("DEPLOYED")){
    //       this.topicStatusCode[topicname][key] = 0;
    //     } else if (status.includes("RUNNING")){
      //       this.topicStatusCode[topicname][key] = 1;
  //     } else {
    //       this.topicStatusCode[topicname][key] = -1;
    //     }
  //   }
  
  //   // console.log(status);
  //   // if(!status || status == "?"){
    //   //   this.topicStatusCode[topicname] = -1;
    //   // } else if(status.includes("RUNNING")){
  //   //   this.topicStatusCode[topicname] = 1;
  //   // } else if(status.includes("STOPPED") || status.includes("NEW")){
    //   //   this.topicStatusCode[topicname] = 0;
  //   // }
  // }
  



  setStatusCode(statusObject, statusCodeObject) {
    
    let status: string = "";
    for (let key of Object.keys(statusObject)) {
      status = statusObject[key];
      statusCodeObject[key] = "";
      if (status == "NEW" || status.includes("STOPPED") || status.includes("DEPLOYED") || status.includes("DOWN")) {
        statusCodeObject[key] = 0;
      } else if (status.includes("RUNNING")) {
        statusCodeObject[key] = 1;
      } else {
        statusCodeObject[key] = -1;
      }
    }
    
  }
  
  async getLatestInstanceDataByName(instanceName) {
    let instanceObj = undefined;
    try {
      let data = await this.apiFormatterService.getApiRequest(this.globals.devDbUrl + "/console/getInstanceByName/" + instanceName).toPromise();
      instanceObj = data;
    } catch (error) {
      console.error(error);
    }
    return instanceObj;
  }

  
  async updateCurrentInstance(instanceIP, instanceObj) {
    if(!this.topicsAndContainers || !instanceObj) return;
    for (let topic of this.topicsAndContainers) {
      for (let artifact of topic.artifacts) {
        for (let instance of artifact.instances) {
          if (instance.ipaddress == instanceIP) {
            instance = {...instanceObj};
          }
        }
      }
    }
  }
  // setStatusOfUnavailableInstances(){
    //   for(let key of Object.keys(this.topicStatusCode)){
      //     if(!this.topicApi[key]){
        //       this.topicStatusCode[key] = -1;
        //     }
        //   }
  //   // console.log(this.topicStatusCode);
  // }
  
  
  newInstanceModal(event): void {
    event.target.blur();
    this.modalDataForInstance = new Instance(this.apiFormatterService).getData(
      this.globals.devDbUrl
    );
    this.openWindowCustomClass(this.instanceModalContent);
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static"
    });
  }

  showAddTopic(data) {
    //  debugger;
    this.createTopic = data;
    // this.getTopics();
    //  this.getTopicsAndContainers();
    this.showAddTopicFlag = true;
  }
  setDeleteModalTopic(topic, deleteContent) {
    this.deleteObj = {
      title: "Delete Topic",
      name: topic.topicname,
      id: topic.topicid,
      callbackFunc: this.dataService.putData("console/deleteTopic", {
        topicid: topic.topicid
      })
    };
    this.openDeleteModal(deleteContent);
  }
  openDeleteModal(deleteContent) {
    this.modalService.open(deleteContent, {
      centered: true,
      backdrop: "static"
    });
  }
  routeToAddContainer() {
    this.router.navigate(["container", ""]);
  }

  callOnStompMessageSent(message) {
    if (message.topicName && message.listOfIntances && message.listOfInstances[0]['ipaddress'])
      this.instanceStatusCode[message.listOfInstances[0].ipaddress] = -1;
    this.enableAction = true;
  }

  handleMessageFromStomp(message) {
    // this.messageFromStomp = message;
    // this.messageFromStomp =  this.messageFromStomp.replace("\"", "");
    // this.messageFromStomp =  this.messageFromStomp.replace("\"", "");
    // let formattedMessage = this.messageFromStomp.split(",");
    // if(formattedMessage.length < 2)
    //   return;
    // console.log(formattedMessage);
    message = JSON.parse(message);
    // console.log(message);
    // console.log(this.instanceStatus);
    // console.log(this.topicStatusCode);
    // debugger;
    for (let key of Object.keys(message)) {

      
      
      if(message[key]) {
        this.instanceStatus[key] = message[key];
        this.setStatusCode(this.instanceStatus, this.instanceStatusCode);
      }
        
      if (!message[key] || message[key] == "RUNNING" || message[key] == "STOPPED"){
        if(this.currentTab == 'topic')
          this.getTopicsAndContainers();
        else if(this.currentTab == 'container')
          this.getAvailableContainers();
      } 
      // let topicName = undefined;
      // let artifactID = undefined;
      // // console.log(key);
      // for (let instanceStatusKey of Object.keys(this.instanceStatus)) {
      //   if (key in this.instanceStatus[instanceStatusKey]) {
      //     topicName = instanceStatusKey;
      //     break;
      //   }
      // }

      // for (let instanceStatusKey of Object.keys(this.containerInstanceStatus)) {
      //   if (key in this.containerInstanceStatus[instanceStatusKey]) {
      //     artifactID = instanceStatusKey;
      //     break;
      //   }
      // }
      // // console.log(topicName, key, message[key]);
      // if (topicName) {
      //   this.instanceStatus[topicName][key] = message[key];
      //   this.setStatusCode(topicName, this.instanceStatus, this.topicStatusCode);
      // }

      // if (artifactID) {
      //   this.containerInstanceStatus[artifactID][key] = message[key];
      //   this.setStatusCode(artifactID, this.containerInstanceStatus, this.containerStatusCode);
      // }



      // this.setTopicStatusCode(topicName);
    }
    // console.log(this.topicStatusCode)
    // console.log(this.messageFromStomp+ ": "+ topicIndex);
  }
}
