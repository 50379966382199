import { Injectable } from '@angular/core';
import Speech from "speak-tts";

@Injectable({
  providedIn: 'root'
})
export class SpeechService {

  constructor() { }

  speech = undefined;
  speechData = undefined;

  getSpeech() {
    return this.speech
  }

  initializeSpeech() {
    this.speech = new Speech()
    this.speech.init({
      volume: 1,
      lang: "en",
      rate: 1,
      pitch: 1,
      // voice: "Google UK English Male",
      splitSentences: true,
      listeners: {
        onvoiceschanges: voices => {
          // console.log("Voices Changed",voices)
        }
      }
    })
      .then(data => {
        this.speechData = data;
        // console.log("Speech Ready", data)
      })
      .catch(err => {
        console.error("An error Occured", err)
      })
    let msg = this.speech.hasBrowserSupport()
      ? "You're Ready!!!"
      : "Your Browser doesn't support it!!!"
    // console.log(msg) 
  }

  speak(text: string, modal, componentRef) {
    let language = this.speechData.voices[0].lang;
    let voice = this.speechData.voices[0].name;
    if (language) this.speech.setLanguage(language);
    if (voice) this.speech.setVoice(voice);
    this.speech.speak({
      text: text,
      queue: false,
      listeners: {
        onstart: () => {/*console.log("Speech Start")*/ },
        onend: () => { /*console.log("Speech end")*/ },
        onresume: () => {/*console.log("Speech Resume")*/ }
      }
    })
      .then(data => {/*console.log("Speech Success", data)*/
      })
      .catch(err => {/*console.log("Speech Error", err)*/ })
      .finally(() => {
        componentRef.audioStatus = 0; modal.close();
      })
  }

}
