import { Component, OnInit, Input } from '@angular/core';

import { Globals } from "../../config";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Container } from "../custom-modal/container";
import { Topic } from "../custom-modal/topic";
import { Instance } from "../custom-modal/instance";
import { ApiFormatterService } from "../../common/services/Api Formatter/api-formatter.service";
import { ActivatedRoute, Router } from "../../../../node_modules/@angular/router";
import { DataService } from "../../register/services/data.service";
import { FileUploadService } from "../services/fileUploadService/file-upload.service";

@Component({
  selector: 'app-topic-list',
  templateUrl: './topic-list.component.html',
  styleUrls: ['./../console.component.css']
})
export class TopicListComponent implements OnInit {
  allContainers: any;
  topicsAndContainers: any;
  error: boolean = false;
  constructor(
    private globals: Globals,
    private modalService: NgbModal,
    private apiFormatterService: ApiFormatterService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private uploadFilesService: FileUploadService
  ) {
    // this.getContainers();
    this.getTopicsAndContainers();
    this.showAddTopicFlag = false;

    this.username = localStorage.getItem("currentUser");
  }
  username: string;
  index: number;
  topics: any;
  loading: boolean = false;
  @Input() showTopicList: boolean;
  ngOnInit() {
    this.showAddTopicFlag = !this.showTopicList;
    if (this.username && localStorage.getItem("consoleAccess") === 'true') {
     // this.getTopics();
    } else {
      this.router.navigate([""]);
    }

  }
  gobackToTopicList() {
    this.getTopicsAndContainers();
    this.showAddTopicFlag = false;

  }
  getTopics() {
    this.loading = true;
    this.error = false;
    let currentUser = this.apiFormatterService.removeQuotesFromString(localStorage.getItem('currentUser').trim());
    this.apiFormatterService.getApiRequest(this.globals.devDbUrl  + "/console/getConsoleContainer/" + currentUser).subscribe(res => {
      // console.log(res);
      this.loading = false;
      this.topics = res;
      this.index = -1;
      for (const topic of this.topics) {
        this.index++;
        this.getThemesNames(topic.topicid, this.index);
      }
    },
    err => {
      // console.log(err);
      this.loading = false;
      this.error = true;
    });
  }
  getThemesNames(topicID, index) {
    this.error = false;
    // this.httpClient
    //   .get(this.globals.devDbUrl + "/getThemesByTopic/" + topicID + "/text")
    this.apiFormatterService.getApiRequest(this.globals.devDbUrl + "/getThemesByTopic/" + topicID + "/text")
      .subscribe(res => {
        this.topics[index].themeNames = res;
      },
      err => {
        // console.log(err);
        this.loading = false;
        this.error = true;
      });
  }
  items: Object;
  title = "";
  createTopic: any;
  instanceModalContent: any;
  modalDataForInstance: Object;
  showAddTopicFlag: boolean;
  deleteObj: object;

  // formatDate(date): string {
  //   var d = new Date(date),
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-");
  // }

  // dataFromInstanceModal(event: any, modal): void {
  //   event.fields.push({
  //     jsonKey: "createdOn",
  //     value: this.formatDate(new Date())
  //   });
  //   console.log(event);

  //   event.postData(this.globals.devDbUrl, modal);
  //   modal.close('Saved');
  // }

  getFieldIndexByJsonKey(fields: any[], jsonKey: string): number {
    return fields.findIndex(field => field.jsonKey == jsonKey);
  }

  findFieldAndAssign(fields: any[], arrayToPopulateWith: any[]) {
    fields.map(field => {
      let index: number = arrayToPopulateWith.findIndex(object => {
        if (field.jsonKey == object.jsonKey) return true;
      });
      if (index >= 0) field.value = arrayToPopulateWith[index].value;
    });
  }

  setDynamicDataForModal(event: any): any {
    if (!(event instanceof Topic)) return;
    event = <Topic>event;
    let arrayToPopulateWith: any[] = [];
    let index = this.getFieldIndexByJsonKey(event.fields, "topicArtifacts");
    if (index >= 0) {
      let arrayToSetAsValue: any[] = [];
      event.fields[index].selectedValues.map(value => {
        // let dataFromApiIndex = event.fields[index]["dataFromApi"].findIndex(
        //   dataObject => dataObject.artifactID == value.value
        // );
        arrayToSetAsValue.push({
          taid: "",
          artifactid: value.value,
          topicid: ""
        });
      });
      arrayToPopulateWith.push({
        jsonKey: "topicArtifacts",
        value: arrayToSetAsValue
      });
    }

    index = this.getFieldIndexByJsonKey(event.fields, "topicInstances");
    if (index >= 0) {
      let arrayToSetAsValue: any[] = [];
      event.fields[index].selectedValues.map(value => {
        arrayToSetAsValue.push(value.value);
      });
      arrayToPopulateWith.push({
        jsonKey: "topicInstances",
        value: arrayToSetAsValue
      });
    }

    index = this.getFieldIndexByJsonKey(event.fields, "owner");
    if(index >= 0){
      let arrayToSetAsValue: any[] = [];
      event.fields[index].selectedValues.map(value => {
        arrayToSetAsValue.push(value.value);
      });
      arrayToPopulateWith.push({
        jsonKey: "owner",
        value: arrayToSetAsValue
      });
    }

    index = this.getFieldIndexByJsonKey(event.fields, 'country');
			if (index >= 0) {
				let arrayToSetAsValue: any[] = [];
				event.fields[index].selectedValues.map((value) => {
					arrayToSetAsValue.push(value.value);
				});
				arrayToPopulateWith.push({
					jsonKey: 'country',
					value: arrayToSetAsValue
				});
			}
    let arrayForLogos: any[] = [];
    index = this.getFieldIndexByJsonKey(event.fields, "logos");
    if (index >= 0) {
      let field = event.fields[index];
      field.fields.map(subField => {
        if (subField.value && subField.value.length >= 0) {
          subField.jsonObject.logoname = subField.value;
          arrayForLogos.push(subField.jsonObject);
        }
      });
    }
    // console.log(arrayForLogos);
    arrayToPopulateWith.push({
      jsonKey: "logos",
      value: arrayForLogos
    });
    // console.log(arrayToPopulateWith);
    this.findFieldAndAssign(event.fields, arrayToPopulateWith);
  }

  dataFromModal(event, modal): void {
    this.setDynamicDataForModal(event);
    // console.log(event);
    event.postData(this.globals.devDbUrl, modal);
    modal.close("Done");
    this.getTopicsAndContainers();
  }

  selectData(selector) {
    // if (selector === "container") {
    //   this.items = new Container(this.apiFormatterService).getData(
    //     this.globals.devDbUrl
    //   );
    // } else
    if (selector === "topic") {
      this.items = new Topic(
        this.globals,
        this.apiFormatterService,
        this,
        this.uploadFilesService
      ).getData(this.globals.devDbUrl);
    }
  }

  setContentForInstance(content): void {
    this.instanceModalContent = content;
  }

  // editContainer(content, objectToPopulateWith) {
  //   this.items = new Container(this.apiFormatterService).getPopulatdData(
  //     objectToPopulateWith,
  //     this.globals.devDbUrl
  //   );

  //   this.openWindowCustomClass(content);
  // }

  // getContainers() {
  //   this.httpClient
  //     .get(this.globals.devDbUrl + "/console/getDemoContainer")
  //     .subscribe(res => {
  //       this.allContainers = res;
  //       console.log("Containers:", this.allContainers);
  //     });
  // }
  getTopicsAndContainers() {
    this.loading = true;
    this.error = false;
    let currentUser = this.apiFormatterService.removeQuotesFromString(localStorage.getItem('currentUser').trim());
    // this.httpClient
    //   .get(this.globals.devDbUrl + "/console/getTopicContainerList/"+currentUser)
    this.apiFormatterService.getApiRequest(this.globals.devDbUrl + "/console/getTopicContainerList/"+currentUser)
      .subscribe(res => {
        // debugger;
        this.topicsAndContainers = res;
        this.loading = false;
        // var index = -1;
        // for (const topic of this.topicsAndContainers) {
        //   var rowspan = 0, count = -1;
        //   index++;
        //   for (const aritfact of topic.artifacts) {
        //     count++;
        //     this.topicsAndContainers[index].artifacts[count].rowspan = aritfact.instances.length;
        //     rowspan = rowspan + aritfact.instances.length;
        //   }
        //   this.topicsAndContainers[index].rowspan = rowspan;

        // }
        // console.log("topicsAndContainers:", this.topicsAndContainers);
      },
      err => {
        console.error(err);
        this.loading = false;
        this.error = true;
      });
  }
  newInstanceModal(event): void {
    event.target.blur();
    this.modalDataForInstance = new Instance(this.apiFormatterService).getData(
      this.globals.devDbUrl
    );
    this.openWindowCustomClass(this.instanceModalContent);
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static"
    });
  }

  showAddTopic(data) {
    //  debugger;
    this.createTopic = data;
   // this.getTopics();
    //  this.getTopicsAndContainers();
    this.showAddTopicFlag = true;
  }
  setDeleteModalTopic(topic, deleteContent) {
    this.deleteObj = {
      title: "Delete Topic",
      name: topic.topicname,
      id: topic.topicid,
      callbackFunc: this.dataService.putData("/console/deleteTopic", {
        topicid: topic.topicid
      })
    };
    this.openDeleteModal(deleteContent);
  }
  openDeleteModal(deleteContent) {
    this.modalService.open(deleteContent, {
      centered: true,
      backdrop: "static"
    });
  }
  routeToAddContainer() {
    this.router.navigate(["container", ""]);
  }
  dataFromDeleteModal(deleteObj) {
    this.getTopicsAndContainers();
  }
}
